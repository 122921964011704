import { Email } from "@mui/icons-material";
import { Button, CssBaseline, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import GmailLogo from "../../../images/gmail_logo.svg"
import OutlookLogo from "../../../images/outlook_logo.svg"

export default function ProviderSelection(props) {

    const { selected } = props
    return (
        <div>
            <CssBaseline />
            <Grid container spacing={4} marginTop={2} alignItems="center" justifyContent="center">
                <Grid item >
                    <Button variant='outlined' color='primary' sx={{ textTransform: 'none' }} onClick={_ => props.setCustomProvider(1)} >
                        <Grid container spacing={2} item width={150} height={150} direction="column" alignItems="center" justifyContent="center" >
                            <Grid item>
                                <img width='35px' src={GmailLogo} title="Logomarca Gmail" />
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5">Gmail</Typography>
                            </Grid>
                        </Grid>
                    </Button>
                </Grid>
                <Grid item >
                    <Button variant='outlined' color='primary' sx={{ textTransform: 'none' }} onClick={_ => props.setCustomProvider(2)} >
                        <Grid container spacing={2} item width={150} height={150} direction="column" alignItems="center" justifyContent="center" >
                            <Grid item>
                                <img width='35px' src={OutlookLogo} title="Logomarca Outlook" />
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5">Microsoft</Typography>
                            </Grid>
                        </Grid>
                    </Button>
                </Grid>
                <Grid item >
                    <Button variant='outlined' color='primary' sx={{ textTransform: 'none' }} onClick={_ => props.setCustomProvider(3)} disabled={selected == 3} >
                        <Grid container spacing={2} item width={150} height={150} direction="column" alignItems="center" justifyContent="center" >
                            <Grid item>
                                <Email sx={{ width:'35px', height:'35px' }} />
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5"><FormattedMessage id="custom" /></Typography>
                            </Grid>
                        </Grid>
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}