import { PapiroConsole } from '../utils/papiroConsole';
import Api from './api';

export const getGroups = async () => { 
  let data = [];
  await Api.get('/groups')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getSegments = async () => { 
  let data = [];
  await Api.get('/segments')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrg = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllOrganizationsToEditCalledOrganization = async () => { 
  let data = [];
  await Api.get('/performance/organizations/edit-called-organization')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations/tech')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsNewSimple = async () => { 
  let data = [];
  await Api.get('/organizations/list/new/simple')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAddressesSimpleByOrgId = async (orgId) => {
  let data = [];
  await Api.get(`/addresses/organization/${orgId}/selectionmodel`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}


export const getOrgCallCenter = async () => {
  let data = [];
  await Api.get('/organizations/organizationsCallCenter')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllSelectionModelByTechnicalTeam = async (technicalTeamId) => {
  PapiroConsole.log("=== getAllSelectionModelByTechnicalTeam ===")
  PapiroConsole.log(technicalTeamId)
  let data = [];
  await Api.get(`/organizations/selectionmodel/technicalteam?technicalTeamId=${technicalTeamId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllSelectionModel = async () => {
  let data = [];
  await Api.get(`/organizations/selectionmodel`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getAllSelectionModelToTableCustomFilterByRoleType = async () => {
  let data = [];
  await Api.get(`/organizations/selectionmodel/tablecustomfilter/byroletype`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}