import React, { Component } from 'react';
import { Container, Typography, Box, Grid, Button, IconButton, Paper, Stack } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

class DataSendingConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }



  render() {
    return (

      <Paper sx={{ pl: 10, pr: 10, py: 7, mb: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Typography variant="h4" color="primary" fontWeight="bold" gutterBottom>
            Confirmação de envio de dados (opt in)
          </Typography>

          <Typography justifyContent="center" variant="body2" color="textSecondary" sx={{ mb: 3, mt: 2 }}>
            Para facilitar o acesso e gerenciamento das suas informações, oferecemos a opção de login através do GOV.BR. Ao fazer login, você poderá acessar dados e serviços relacionados ao seu cadastro de forma mais rápida e segura. Deseja continuar e acessar suas informações do GOV.BR agora? Caso queira prosseguir, clique em SIM. Se preferir não utilizar essa opção neste momento, clique em NÃO.
          </Typography>

          <Box sx={{ border: '1px solid #ECEBEB', p: 3, pr: 15, textAlign: 'start' }}>
            <Typography variant="h6" color="inherit" sx={{ fontSize: '0.875rem', mb: 1, fontWeight: 700 }}>
              Dados enviados
            </Typography>
            <Typography variant="h6" color="inherit" sx={{ fontSize: '0.875rem', mb: 1 }}>
              <span sx={{ fontWeight: 700 }}>Email:</span> {this.props.email}
            </Typography>
            <Typography variant="h6" color="inherit" sx={{ fontSize: '0.875rem' }}>
              <span sx={{ color: 'red', fontWeight: 700 }}>CPF:</span>  {this.props.document}
            </Typography>
          </Box>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Stack direction="row" spacing={4} justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                sx={{ paddingX: 6 }}

              onClick={this.props.handleOptInDenied}
              >
                Não
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ paddingX: 6 }}

               onClick={this.props.handleOptInConfirmation}
              >
                Sim
              </Button>


            </Stack>
          </Grid>

        </Grid>
      </Paper>
    
    );
  }
}

export default DataSendingConfirmation;
