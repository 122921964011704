import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomizedSnackbars from 'src/components/material-snackbars';
import Api from 'src/services/api';
import Api2Talk from 'src/services/api2talk';
import { Overlay } from 'src/styles/global';
import * as componentActions from '../../../../redux/actions/chats'; // trocar pelo redux que criarei no chat
import * as QuickResponseService from "../../../../services/2talk/quick-response.service";
import { Styles } from '../../../../styles/material-styles';
import './styles.css';


const ChatQuickResponse = (props) => {
  const { chats, sendMessage, phonenumberid, customerid, userSession, setMessage, setExecScrollToBottom, onSetCurrentQuickResponseSeledted, onSetOpenModal } = props;

  const intl = useIntl();
  
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quickResponses, setQuickResponses] = useState([]);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationVariant, setNotificationVariant] = useState('error');
  const [notificationMessage, setNotificationMessage] = useState('');

  const setAlert = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const handleAddQuickResponseMessage = (quickResponse) => {
    
    Api2Talk.get(`/quickresponses/${quickResponse.id}`)
      .then(result => {
        if (result.data.success) {
          setLoading(false);
          onSetCurrentQuickResponseSeledted(result.data.data);
          onSetOpenModal(true);
        }
        else {   
          setLoading(false);
          let alertMsg = result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" });
          setAlert(true, 'error', alertMsg);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false);
          let alertMsg = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" });
          setAlert(true, 'error', alertMsg);
        }
        else {
          setLoading(false);
          let alertMsg = err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" });
          setAlert(true, 'error', alertMsg);
        }
      });
  }

  const onHandleScrollToBottom = () => {
    setExecScrollToBottom(prev => !prev);
  }

  const getData = async () => {
    try {
      const response = await QuickResponseService.getQuickResponseHighlight();
      if (response.success) {
        setQuickResponses(response.data);
      }
    } catch (err) {
      console.error(err);
    } 
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    setShow(false);

    if (chats?.chat?.id) {
      let messages = chats.chatsv2[chats.chat.id] ? chats.chatsv2[chats.chat.id].itens : null;

      if(messages){
        let lastKey = Object.keys(messages).pop();

        if (lastKey) {
          let originLastMessage = messages[lastKey]?.messageorigin;
  
          const messageOriginAutomatic = 4;
          const messageOriginMensseger = 1;
  
          if (originLastMessage != messageOriginAutomatic && originLastMessage != messageOriginMensseger) { 
            setShow(true);
          }
        }
      }

      
    }
  }, [chats])

  return (
    <>  
      {show && (
        <div className='container-quick-response'>
          {quickResponses?.length > 0 && quickResponses?.map(quickResponse => (
            <button 
              key={quickResponse?.id} 
              className='btn-quick-response' 
              onClick={() => handleAddQuickResponseMessage(quickResponse)}
            >
              {quickResponse?.title}
            </button>
          ))}
          {onHandleScrollToBottom()}
        </div>   
      )}

      {loading && (
        <Overlay>
            <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} 
      />
    </>
  )
}

ChatQuickResponse.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatQuickResponse)));