import { Button, Container, CssBaseline, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import TitleName from "src/components/title-name";
import { Styles } from "src/styles/material-styles";
import PropTypes from "prop-types"
import { PapiroConsole } from "src/utils/papiroConsole";
import Api from "src/services/api";
import EditableTableBlocks from "./editable-table-blocks";
import DialogGeneral from '../../../components/dialog-general';
import { Delete } from "@mui/icons-material";
import DialogConfirmationDeleteImage from '../../../components/dialog-confirmation-not-button'

function BlocksStep(props) {

    const { classes, intl, backButton, nextButton, clientPortal, handleNextBarProgress, handleComplete, setNotification, setLoading } = props

    const [blocks, setBlocks] = useState([{ id: 0, title: '', blockimagefiles: [], type: { id: 3, name: 'link' } }])
    const [deleteBlockDialogOpen, setDeleteBlockDialogOpen] = useState(false)
    const [idToDelete, setIdToDelete] = useState(0)
    const [idToDeleteImage, setIdToDeleteImage] = useState(0)
    const [options, setOptions] = useState([])
    const [deleteImageDialog, setDeleteImageDialog] = useState(false)
    const typeOptions = [{ id: 1, name: 'knowledgebase' }, { id: 2, name: 'artcile' }, { id: 3, name: 'link' }]

    useEffect(() => {
        getBlocks()
    }, [clientPortal])

    const getBlocks = async () => {
        setLoading(true)
        let rows = []
        const nextRow = { id: 0, title: '', blockimagefiles: [], type: { id: 3, name: 'link' }, relation: '' }
        await Api.get(`clientportalblocks/clientportal/${clientPortal.id}`)
            .then(result => {
                if (result.data.success) {
                    rows = (result.data.data)
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
        rows = rows.map(r => {
            let relation = ''
            let type = { id: 0, name: '' }
            if (r.clientportalblocktype == 1) {
                relation = r.knowledgebaseid
                type = { id: 1, name: 'knowledgebase' }
            }
            else if (r.clientportalblocktype == 2) {
                relation = r.articleid
                type = { id: 2, name: 'article' }
            }
            else if (r.clientportalblocktype == 3) {
                relation = r.link
                type = { id: 3, name: 'link' }
            }
            return ({ ...r, relation: relation, type: type, blockimagefiles: [] })
        })

        rows.push(nextRow)

        PapiroConsole.log("getBlocks rows")
        PapiroConsole.log(rows)

        setBlocks(rows)
        setLoading(false)
    }

    const handleSaveRow = async (row) => {

        const nextRow = { id: 0, title: '', blockimagefiles: [], type: { id: 3, name: 'link' }, relation: '' }

        if (row.title) {

            var formData = {
                title: row.title,
                fileUpload: row.fileUpload
            }

            let data = new FormData()
            data.append('clientportalid', clientPortal.id)
            data.append('hirerid', clientPortal.hirerid)
            data.append('title', row.title)
            row.blockimagefiles.forEach(file => { data.append('filesupload', file) })
            data.append('clientportalblocktype', row.type.id)

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }

            var result;

            switch (row.type.id) {
                case 1:
                    data.append('knowledgebaseid', row.relation)
                    break;
                case 2:
                    data.append('articleid', row.relation)
                    break;
                case 3:
                    data.append('link', row.relation)
                default:
                    break;
            }

            setLoading(true)

            if (row.id == 0) {
                await Api.post("clientportalblocks", data, config)
                    .then(result => {
                        if (result.data && result.data.success) {
                            const data = result.data.data
                            setBlocks([
                                { ...data, blockimagefiles: [], type: typeOptions.find(t => t.id == data.clientportalblocktype) },
                                ...blocks.filter(b => b.id != 0),
                                nextRow
                            ])
                            setNotification(true, 'success', intl.formatMessage({ id: "add.success" }))
                        } else {
                            setLoading(false)
                            setNotification(true, 'error', result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                            Api.kickoff(result)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    })
            } else {
                data.append('id', row.id)
                await Api.put("clientportalblocks", data, config)
                    .then(result => {
                        if (result.data && result.data.success) {
                            const data = result.data.data
                            setBlocks([
                                { ...data, blockimagefiles: [], type: typeOptions.find(t => t.id == data.clientportalblocktype) },
                                ...blocks.filter(b => b.id != row.id)
                            ])
                            setNotification(true, 'success', intl.formatMessage({ id: "add.success" }))
                        } else {
                            setLoading(false)
                            setNotification(true, 'error', result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                            Api.kickoff(result)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    })
            }
        }

        setLoading(false)
    }

    const handleInputChangeTable = (field, value, id) => {
        if (field === "type") {
            setBlocks(blocks.map(row => (row.id === id ? { ...row, [field]: value, relation: '' } : row)))
            if (value.id === 1 || value.id === 2) {
                getOptions(value.id)
            }
        } else {
            setBlocks(blocks.map(row => (row.id === id ? { ...row, [field]: value } : row)))
        }
    }

    const getOptions = async (typeid) => {
        let list = []
        setLoading(true)
        if (typeid === 1) {
            await Api.get("knowledgebases/simple")
                .then(result => {
                    if (result.data.success) {
                        list = result.data.data.map(i => { return ({ id: i.id, name: i.title }) })
                    } else {
                        setLoading(false)
                        setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    }
                })
                .catch(err => {
                    setLoading(false)
                    setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                })
        } else if (2) {
            await Api.get("articles/get-all/simple")
                .then(result => {
                    if (result.data.success) {
                        list = result.data.data.map(i => { return ({ id: i.id, name: i.title }) })
                    } else {
                        setLoading(false)
                        setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    }
                })
                .catch(err => {
                    setLoading(false)
                    setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                })
        }
        setLoading(false)
        setOptions(list)
    }

    const handleDeleteBlock = (rowItem) => {
        if (rowItem.id > 0) {
            setDeleteBlockDialogOpen(true)
            setIdToDelete(rowItem.id)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        PapiroConsole.log(e)
        PapiroConsole.log('e')
        handleNextBarProgress()
        handleComplete()
    }

    const deleteBlock = async () => {
        setLoading(true)
        await Api.delete(`clientportalblocks/${idToDelete}`)
            .then(result => {
                if (result && result.data.success) {
                    setBlocks(blocks.filter(b => b.id != idToDelete))
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
        setLoading(false)
        setDeleteBlockDialogOpen(false)
    }

    const deleteImage = async() => {
        setLoading(true)
        await Api.put(`/clientportalblocks/deleteimage/${idToDeleteImage}`)
            .then(result => {
                if (result.data && result.data.success) {
                    setBlocks(blocks.map(b => {
                        if (b.id == idToDeleteImage) {
                            b.image = null
                        }
                        return b
                    }))
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
        setDeleteImageDialog(false)
        setLoading(false)
    }

    return (
        <div className={classes.root}>
            <Container>
                <CssBaseline />
                <div className={classes.paper} style={{ marginTop: 0 }}>
                    <Typography component='h1' variant='h5'>
                        {<FormattedMessage id="blocks" />}
                        <TitleName />
                    </Typography>
                    <form name='myForm' className={classes.form} onSubmit={handleSubmit} encType='multipart/form-data'>

                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <EditableTableBlocks
                                    rows={blocks}
                                    handleDeleteRow={handleDeleteBlock}
                                    handleSaveRow={handleSaveRow}
                                    handleInputChangeTable={handleInputChangeTable}
                                    options={options}
                                    intl={intl}
                                    classes={classes}
                                    typeOptions={typeOptions}
                                    setLoading={setLoading}
                                    setNotification={setNotification}
                                    setIdToDeleteImage={setIdToDeleteImage}
                                    setDeleteImageDialog={setDeleteImageDialog}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginBottom: '60px', display: 'flex', marginTop: '20px' }}>
                            <Grid item xs={12} sm={2} >
                                {backButton}
                            </Grid>
                            <Grid item xs={12} sm={2} >
                                {nextButton}
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginLeft: 'auto' }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                >
                                    {<FormattedMessage id="save.next" />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                {deleteImageDialog && (
                    <DialogConfirmationDeleteImage
                        msgButton={intl.formatMessage({ id: "confirm" })}
                        msg={intl.formatMessage({ id: "delete.image.confirmation" })}
                        msgDisagree={intl.formatMessage({ id: "cancel" })}
                        msgAgree={intl.formatMessage({ id: "yes" })}
                        handleConfirmation={_ => deleteImage()}
                        handleClose={_ => setDeleteImageDialog(false)}
                        classes={classes}
                        disabled={false}
                        icon={<Delete />}
                    />
                )}
                <DialogGeneral
                    dialogTitle="Remoção de bloco"
                    dialogSubTitle="Você realmente deseja remover este bloco?"
                    open={deleteBlockDialogOpen}
                    openOrCloseModalFunction={_ => setDeleteBlockDialogOpen(!deleteBlockDialogOpen)}
                    handleConfirmation={deleteBlock}
                />
            </Container>
        </div>
    )
}

BlocksStep.propTypes = {
    classes: PropTypes.object.isRequired,
    back: PropTypes.func,
    next: PropTypes.func
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,

});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(BlocksStep)));