import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  Badge,
  Divider
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterAlt as FilterIcon,
  CalendarMonth as CalendarIcon,
  ViewKanban as KanbanIcon,
  Notifications as NotificationsIcon,
  MoreVert as MoreVertIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';

const ModernAppBar = ({ onSearchChange, searchTerm }) => {
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
  
  const history = useHistory();
  const { lang } = useParams();

  const handleUserMenuOpen = (event) => {
    setUserMenuAnchor(event.currentTarget);
  };
  
  const handleUserMenuClose = () => {
    setUserMenuAnchor(null);
  };
  
  const handleNotificationsOpen = (event) => {
    setNotificationsAnchor(event.currentTarget);
  };
  
  const handleNotificationsClose = () => {
    setNotificationsAnchor(null);
  };
  
  const handleMoreMenuOpen = (event) => {
    setMoreMenuAnchor(event.currentTarget);
  };
  
  const handleMoreMenuClose = () => {
    setMoreMenuAnchor(null);
  };

  return (
    <AppBar 
      position="static" 
      elevation={0}
      sx={{ 
        bgcolor: 'white', 
        color: '#1e293b',
        borderBottom: '1px solid',
        borderColor: 'rgba(0, 0, 0, 0.06)'
      }}
    >
      <Toolbar sx={{ px: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <KanbanIcon 
            sx={{ 
              color: 'primary.main', 
              fontSize: 28, 
              mr: 1.5,
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'rotate(-10deg)'
              }
            }} 
          />
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: '700',
              background: 'linear-gradient(90deg, #3b82f6 0%, #10b981 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mr: { xs: 1, md: 3 }
            }}
          >
            Kanban de Tickets
          </Typography>
        </Box>
        
        <TextField
          placeholder="Buscar tickets..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          sx={{
            maxWidth: { xs: '140px', sm: '200px', md: '300px' },
            ml: { xs: 1, md: 2 },
            mr: { xs: 'auto', md: 2 },
            '& .MuiOutlinedInput-root': {
              borderRadius: 3,
              bgcolor: '#f9fafb',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main',
              }
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" sx={{ color: 'text.secondary' }} />
              </InputAdornment>
            ),
          }}
        />
        
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', ml: 'auto' }}>
          {/*<Button 
            variant="outlined"
            startIcon={<FilterIcon />}
            sx={{ 
              borderRadius: 3, 
              mr: 2, 
              borderColor: '#e2e8f0',
              color: 'text.primary',
              '&:hover': {
                bgcolor: '#f8fafc',
                borderColor: '#cbd5e1'
              }
            }}
          >
            Filtros
          </Button>
          */}
          <Button 
            variant="contained"
            startIcon={<CalendarIcon />}
            sx={{ 
              borderRadius: 3, 
              mr: 2,
              boxShadow: '0 2px 5px 0 rgba(59, 130, 246, 0.2)'
            }}
            onClick={(event, rowData) => {
                history.push(`/${lang}/calendar`);
              }}
          >
            Ver Calendário
          </Button>
          

        </Box>
        
        {/* Menu móvel */}
        <Box sx={{ display: { xs: 'flex', md: 'none' }, ml: 'auto' }}>
          <IconButton onClick={handleMoreMenuOpen}>
            <MoreVertIcon />
          </IconButton>
        </Box>
        
        {/* Menus */}

        
        <Menu
          anchorEl={moreMenuAnchor}
          open={Boolean(moreMenuAnchor)}
          onClose={handleMoreMenuClose}
          PaperProps={{
            elevation: 2,
            sx: { mt: 1.5, borderRadius: 2 }
          }}
        >
          <MenuItem onClick={handleMoreMenuClose}>Ver Calendário</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default ModernAppBar;