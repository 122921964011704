import { PapiroConsole } from '../utils/papiroConsole';
import Api from './api';

export const createClientExternalUser = async (formData) => {
  let data = []
  await Api.post(`/users/register-external-client`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllUsersSelecionModel = async () => {
  let data = []
  await Api.get(`/users/list/selectionmodel`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllRequestingUsersSelectionModelByOrganizationId = async (organizationId, requestingUserId) => {
  PapiroConsole.log("requestingUserId")
  PapiroConsole.log(requestingUserId)
  
  let data = []
  await Api.get(`/users/list/requestinguser/selectionmodel/${organizationId}/${requestingUserId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllRequestingUsersSelectionModelByOrganizationIdAndLocationId = async (organizationId, locationId, requestingUserId) => {
  let data = []
  await Api.get(`/users/list/requestinguser/selectionmodel/${organizationId}/${locationId}/${requestingUserId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getOrganizations = async (id = 0) => { 
    let data = [];
    if(id != 0){
      await Api.get('/users/techinical/organization/'+id)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
      });
    }else{
      await Api.get('/organizations')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
      });
    }
    
    return data;
  };

  export const getCalledsBygroup = async () => {
    let data = [];
    await Api.get(`/calleds/getCalledsBygroup`)
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getAllTec = async () => { 
    let data = [];
    await Api.get('/users/techinical')
    .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err;
    });
    
    return data;
  };

  export const getGroups = async () => {
    let data = [];
    await Api.get('/groups')
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getNameById = async (id) => {
    let data = [];
    await Api.get(`/name/${id}`)
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };


  export const getUsersGroups = async () => {
    let data = [];
    await Api.get('/groups/GetGroupsByRole')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const FilterSlaCalled = async () => {
    let data = [];
    await Api.get('/calleds/getSlaCalleds')
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };


  export const getAllCalledMaterials = async (organizations) => {
    let data = [];
    await Api.get(`/calledorganizationmaterials/getbyorganizations?orgs=${organizations}`)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };


  export const getOrganizationsCategories = async (organizations) => {
    let data = [];
    await Api.get(`/organizationcategories/GetAllOrganizationCategories`)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };


  export const getOrganizationsCategoriesByOrganizationId = async (id) => {
    let data = [];
    await Api.get(`/organizationcategories/organizations/list/${id}`)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };


  export const getDepartaments = async (ids) => {
    let data = [];
    await Api.get(`/users/departaments/all?ids=${ids}`)
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getDepartamentByUserId = async (id) => {
    let data = [];
    await Api.get(`/users/departaments/user/${id}`)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getOrganizationsByGroup = async (ids) => {
    let data = [];
    await Api.get(`/users/organizations/all?ids=${ids}`)
        .then(result => {
            data = result.data.data

            
         })
         .catch(err => {
            data = err;
    });
    return data;
  };


  export const GetUsersTechs = async (categoryId, organizationId) => {
    let data = [];
    await Api.get(`/users/getusers/category/${categoryId}/organization/${organizationId}`)
      .then((result) => {
        data = result.data;
      })
      .catch((err) => {
        data = err;
      });
    return data;
  };

  export const GetUsersTechsByName = async (categoryId, organizationId,name) => {
    let data = [];
    await Api.get(`/users/getusers/category/${categoryId}/organization/${organizationId}/name/${name}`)
      .then((result) => {
        data = result.data;
      })
      .catch((err) => {
        data = err;
      });
    return data;
  };

  export const GetAllActivities = async () => {
    let data = [];
    await Api.get("activity")
      .then((result) => {
        data = result.data;
      })
      .catch((err) => {
        data = err;
      });
    return data;
  };




  export const getAllOrganizationsByGroup = async (ids) => {
    let data = [];
    await Api.get(`/users/organizations/all?ids=${ids}`)
        .then(result => {
            data = result.data

         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getAllUserChatStatus = async () => {
    try {
      const response = await Api.get('/userchatstatus');
      return response.data 
    } catch(err) {
      return err
    }
  };

  export const associateOrganizationUser = async (formData) => {
    try {
      const response = await Api.post('/users/associate-user-organization', formData);
      return response.data
    } catch(err) {
      return err
    }
  }

  export const createUserChatStatus = async (data) => {
    try {
      const response = await Api.post('/userchatstatus', data);
      return response.data
    } catch(err) {
      return err
    }
  };
  export const updateUserChatStatus = async (data) => {
    try {
      const response = await Api.put('/userchatstatus', data);
      return response.data 
    } catch(err) {
      return err
    }
  };
  
  export const getLocationsByOrganizations = async (ids) => {
    let data = [];
    await Api.get(`/addresses/organizations/all?ids=${ids}`)
        .then(result => {
            data = result.data.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };


  export const getDepartamentList = async (orgId) => { 
    let data = [];
    await Api.get(`/organizationdepartaments/organization/${orgId}`)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  

export const getRoles = async () => { 
    let data = [];
    await Api.get('/roles')
        .then(result => {
            data = result.data
            PapiroConsole.log(data)
            PapiroConsole.log("data roles")
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getAddresses = async () => { 
    let data = [];
    await Api.get('/addresses')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const getRolesByUser = async (id) => { 
    let data = [];
    await Api.get('/roles/user/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getWorkHours = async (id) => { 
    let data = [];
    await Api.get('/workhours')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getWorkHoursList = async (id = '') => { 
    let data = [];
    await Api.get('/workhours/list')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const getWorkHoursListByOrgs = async (dataForm) => {
    let data = [];
    await Api.post('/workhours/list/orgs', dataForm)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  }

  export const getById = async (id) => {
    let data = [];
    await Api.get(`/users/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }

  export const getForUpdateById = async (id) => {
    let data = [];
    await Api.get(`/users/forupdate/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }

  export const getAllUsersSelecionModelByRoleTypeAndOrganizationIdAndTechnicalTeamIdAndUserId = async (roleType, technicalTeamId, orgId, userId) => {
    let data = [];
    await Api.get(`/users/selectionmodel?roleType=${roleType}&orgId=${orgId}&technicalTeamId=${technicalTeamId}&userId=${userId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }

  export const getAllUsers = async () => {
    let data = [];
    await Api.get(`/users`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }

  export const getAllUsersTech = async () => {
    let data = [];
    await Api.get(`/users/tech`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }

  export const getAdmCoordByOrg = async (orgId) => {
    let data = [];
    await Api.get(`/users/admcoord/${orgId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }

  export const CreateAuthEmailSession = async (createSession) => {
    let data = [];
    await Api.post(`/users/login/email/create-session`, createSession)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
    });
    return data;
  }


  export const getAllContactPreference = async () => { 
    let data = []
    await Api.get(`/users/get-contact-preference-list`)
      .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err
      })
    return data
  }