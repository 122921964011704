import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Grid, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { injectIntl } from 'react-intl';
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CheckIcon from '@mui/icons-material/Check';



function DialogUserAlreadyExistsOptInMe(props) {

  const intl = props.intl;



  return (
    <div>


      <Dialog open={props.open} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontWeight: "bold" }}>Substituição de dados

          <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={props.openOrCloseModalFunction}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent >
          <Typography variant="body1" gutterBottom>
            <strong>Dados do gov.br:</strong>
          </Typography>
          <Typography variant="body2">Nome: {props.name}</Typography>
          <Typography variant="body2">E-mail: {props.email}</Typography>
          <Typography variant="body2">CPF: {props.document}</Typography>

          <Typography variant="body1" gutterBottom sx={{ marginTop: 2 }}>
            <strong>Dados encontrados no Portal da Central de Atendimento  </strong>aos sistemas estruturantes do Ministério da Gestão e da Inovação em Serviços Públicos
          </Typography>
          {/* <Typography variant="body2">1 - Luiz Moraes, E-mail: luiz195@gmail.com</Typography>
          <Typography variant="body2">2 - Luiz Silva, E-mail: otavio@hotmail.com</Typography> */}
          <Box>
            {props.userAlreadyInSystem && props.userAlreadyInSystem.length > 0 ? props.userAlreadyInSystem.map((item) => (
              <Paper
                key={item.id}
                sx={{ padding: 1, marginBottom: 1, backgroundColor: "#f5f5f5" }}
                elevation={0}
              >
                <Typography variant="body2"  >
                  ID: {item.id}, Nome: {item.name}, E-mail: {item.email}, Cpf: {item.document}
                  {item.id == props.userId &&
                    <>
                      <span style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        marginLeft: '10px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px'
                      }}>  Conta oficial GOV.BR <CheckIcon style={{
                        color: 'green',
                        verticalAlign: 'middle',
                        fontSize: '1.3em', // Ajusta o tamanho do ícone para combinar com o texto
                        marginLeft: '3px' // Espaçamento entre o texto e o ícone
                      }} /></span>
                    </>

                  }

                  {item.id != props.userId &&
                    <>
                      <span style={{
                        fontWeight: 'bolder',
                        color: 'black',
                        marginLeft: '10px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px'
                      }}>  Conta será unificada</span>
                    </>

                  }





                </Typography>

              </Paper>
            )) : null}
          </Box>


          <Typography variant="body1" sx={{ marginTop: 2 }}>
            O CPF informado já está associado a uma conta no <strong>Portal da Central de Atendimento</strong> aos sistemas estruturantes do Ministério da Gestão e da Inovação em Serviços Públicos. Deseja sobrescrever os dados existentes com as informações do seu cadastro no GOV.BR?
            Essa ação substituirá as informações atuais. Caso queira prosseguir, clique em 'Sim'. Se preferir manter os dados atuais, clique em 'Não'.

          </Typography>




        </DialogContent>
        <DialogActions mb={2} >

          <Grid container spacing={2} >
            <Grid item xs={12} >
              <Stack direction="row" spacing={4} justifyContent="center">
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ paddingX: 6 }}

                  onClick={props.openOrCloseModalFunction}
                >
                  Não
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ paddingX: 6 }}

                  onClick={props.handleConfirmation}


                >
                  Sim
                </Button>
              </Stack>
            </Grid>
          </Grid>




        </DialogActions>
      </Dialog>

    </div>
  );
}

export default injectIntl(DialogUserAlreadyExistsOptInMe);