import { Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import TitleName from "src/components/title-name";
import { Styles } from "src/styles/material-styles";
import PropTypes from "prop-types"
import { PapiroConsole } from "src/utils/papiroConsole";
import SimpleCheckBox from "src/components/checkbox/check";
import Api from "src/services/api";

function ArticlesStep(props) {

    const { classes, intl, backButton, nextButton, clientPortal, handleNextBarProgress, handleComplete, setLoading, setNotification } = props

    const [item, setItem] = useState({
        showpromotedarticles: clientPortal.showpromotedarticles,
        promotedarticlesamount: clientPortal.promotedarticlesamount,
        showrecentarticles: clientPortal.showrecentarticles,
        recentarticlesamount: clientPortal.recentarticlesamount,
        showmostviewedarticles: clientPortal.showmostviewedarticles,
        mostviewedarticlesamount: clientPortal.mostviewedarticlesamount,
        showbestratedarticles: clientPortal.showbestratedarticles,
        bestratedarticlesamount: clientPortal.bestratedarticlesamount,
    })

    useEffect(() => {
        setItem({
            showpromotedarticles: clientPortal.showpromotedarticles,
            promotedarticlesamount: clientPortal.promotedarticlesamount,
            showrecentarticles: clientPortal.showrecentarticles,
            recentarticlesamount: clientPortal.recentarticlesamount,
            showmostviewedarticles: clientPortal.showmostviewedarticles,
            mostviewedarticlesamount: clientPortal.mostviewedarticlesamount,
            showbestratedarticles: clientPortal.showbestratedarticles,
            bestratedarticlesamount: clientPortal.bestratedarticlesamount,
        })
    }, [clientPortal])

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setItem({
            ...item,
            [name]: value
        })
    }

    const handleChangeSelect = (stateName, value) => {
        setItem({
            ...item,
            [stateName]: value
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()

        let data = new FormData()
        data.append('id', clientPortal.id)
        data.append('showpromotedarticles', item.showpromotedarticles)
        data.append('promotedarticlesamount', item.promotedarticlesamount)
        data.append('showrecentarticles', item.showrecentarticles)
        data.append('recentarticlesamount', item.recentarticlesamount)
        data.append('showmostviewedarticles', item.showmostviewedarticles)
        data.append('mostviewedarticlesamount', item.mostviewedarticlesamount)
        data.append('showbestratedarticles', item.showbestratedarticles)
        data.append('bestratedarticlesamount', item.bestratedarticlesamount)

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        await Api.put("/clientportal/articles", data, config)
            .then(result => {
                if (result.data.success) {
                    setLoading(false)
                    handleNextBarProgress()
                    handleComplete()
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
    }

    return (
        <div className={classes.root}>
            <Container>
                <CssBaseline />
                <div className={classes.paper} style={{ marginTop: 0 }}>
                    <Typography component='h1' variant='h5'>
                        {<FormattedMessage id="articles" />}
                        <TitleName />
                    </Typography>
                    <form name='myForm' className={classes.form} onSubmit={handleSubmit} encType='multipart/form-data'>
                        <Grid container spacing={2}>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.promoted.articles" })} name='showpromotedarticles' stateName='showpromotedarticles' changeSelect={handleChangeSelect} selected={item.showpromotedarticles} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField type="number" fullWidth label={intl.formatMessage({ id: "promoted.articles.amount" })} name="promotedarticlesamount" value={item.promotedarticlesamount} onChange={handleChange} disabled={!item.showpromotedarticles} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.recent.articles" })} name='showrecentarticles' stateName='showrecentarticles' changeSelect={handleChangeSelect} selected={item.showrecentarticles} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField type="number" fullWidth label={intl.formatMessage({ id: "recent.articles.amount" })} name="recentarticlesamount" value={item.recentarticlesamount} onChange={handleChange} disabled={!item.showrecentarticles}/>
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.most.viewed.articles" })} name='showmostviewedarticles' stateName='showmostviewedarticles' changeSelect={handleChangeSelect} selected={item.showmostviewedarticles} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField type="number" fullWidth label={intl.formatMessage({ id: "most.viewed.articles.amount" })} name="mostviewedarticlesamount" value={item.mostviewedarticlesamount} onChange={handleChange} disabled={!item.showmostviewedarticles}/>
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.best.rated.articles" })} name='showbestratedarticles' stateName='showbestratedarticles' changeSelect={handleChangeSelect} selected={item.showbestratedarticles} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField type="number" fullWidth label={intl.formatMessage({ id: "best.rated.articles.amount" })} name="bestratedarticlesamount" value={item.bestratedarticlesamount} onChange={handleChange} disabled={!item.showbestratedarticles}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginBottom: '60px', display: 'flex', marginTop: '20px' }}>
                            <Grid item xs={12} sm={2} >
                                {backButton}
                            </Grid>
                            <Grid item xs={12} sm={2} >
                                {nextButton}
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginLeft: 'auto' }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                >
                                    {<FormattedMessage id="save.next" />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </div>
    )
}

ArticlesStep.propTypes = {
    classes: PropTypes.object.isRequired,
    back: PropTypes.func,
    next: PropTypes.func
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,

});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(ArticlesStep)));