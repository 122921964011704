import React, { forwardRef } from 'react';
import { 
  Snackbar, 
  Alert as MuiAlert,
  IconButton 
} from '@mui/material';
import { X as CloseIcon } from 'lucide-react';

// Componente de alerta personalizado
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastNotification = ({ open, message, severity, onClose }) => {
  // Converte a severidade para uma expressão amigável
  const getSeverityText = (severity) => {
    switch(severity) {
      case 'success':
        return 'Sucesso!';
      case 'error':
        return 'Erro:';
      case 'warning':
        return 'Atenção:';
      case 'info':
        return 'Informação:';
      default:
        return '';
    }
  };
  
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        width: { xs: '90%', sm: 'auto' },
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '400px',
        }
      }}
    >
      <Alert 
        severity={severity} 
        sx={{ 
          width: '100%',
          borderRadius: 2,
          px: 2,
          alignItems: 'center'
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon size={16} />
          </IconButton>
        }
      >
        <strong>{getSeverityText(severity)}</strong> {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastNotification;