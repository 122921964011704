import React, { Component } from 'react';
import { Container, Typography, Box, Grid, Button, IconButton, Paper, Stack } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

class DataReplacementSucess extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }



  render() {
    return (

      <Paper sx={{ pl: 10, pr: 10, py: 7, mb: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Typography variant="h4" color="primary" fontWeight="bold" gutterBottom>
            Solicitação enviada com sucesso 
          </Typography>

          <Typography justifyContent="center" variant="body2" color="textSecondary" sx={{ mb: 3, mt: 2 }}>
          Sua solicitação foi enviada com sucesso! Em breve, você receberá um e-mail com mais detalhes sobre o andamento do processo. Fique atento à sua caixa de entrada e, caso não encontre a mensagem, verifique também a pasta de spam ou lixo eletrônico.
          </Typography>

          
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Stack direction="row" spacing={4} justifyContent="center">
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                color="primary"
                sx={{ paddingX: 6 }}

              onClick={this.props.handleBackToLogin}
              >
                Voltar à Página de Login
              </Button>
            


            </Stack>
          </Grid>

        </Grid>
      </Paper>
    
    );
  }
}

export default DataReplacementSucess;
