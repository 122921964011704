import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Badge,
  Chip,
  Button,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Add, Edit, MoreHoriz } from '@mui/icons-material';

const DayView = ({ 
  currentDate,
  monthNames,
  weekdayNames,
  getTicketsForDate,
  openTicketForm,
  showTicketDetails,
  getStatusColor,
  getStatusIcon,
  getPriorityColor
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  const dayNumber = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const dayTickets = getTicketsForDate(dayNumber);
  const TICKETS_PER_PAGE = isMobile ? 3 : isTablet ? 5 : 10;
  
  // Estado para a paginação por status
  const [paginationState, setPaginationState] = useState({
    'Novo': 1,
    'Em andamento': 1,
    'Finalizado': 1
  });
  
  // Obter tickets paginados para um status
  const getPaginatedTickets = (tickets, status) => {
    const currentPage = paginationState[status] || 1;
    const endIndex = currentPage * TICKETS_PER_PAGE;
    
    return {
      visibleTickets: tickets.slice(0, endIndex),
      hasMore: tickets.length > endIndex,
      totalCount: tickets.length
    };
  };
  
  // Manipulador para carregar mais tickets
  const handleLoadMore = (status) => {
    setPaginationState(prev => ({
      ...prev,
      [status]: (prev[status] || 1) + 1
    }));
  };
  
  // Calcular altura do cartão de ticket baseado no dispositivo
  const getTicketCardHeight = () => {
    return isMobile ? 100 : isTablet ? 120 : 140;
  };
  
  const ticketCardHeight = getTicketCardHeight();
  
  return (
    <Box sx={{ p: isMobile ? 1 : 2 }}>
      <Paper 
        sx={{ 
          p: isMobile ? 2 : 3, 
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          mb: 3, 
          flexWrap: 'wrap', 
          gap: 2 
        }}>
          <Typography variant={isMobile ? "h6" : "h5"} sx={{ fontWeight: 'bold' }}>
            {weekdayNames[currentDate.getDay()]}, {dayNumber} de {monthNames[currentMonth]}
          </Typography>
          
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => openTicketForm(null, dayNumber)}
            size={isMobile ? "small" : "medium"}
          >
            Novo Ticket
          </Button>
        </Box>
        
        <Grid container spacing={isMobile ? 1 : 2}>
          {['Novo', 'Em andamento', 'Finalizado'].map(status => {
            const statusTickets = dayTickets.filter(t => t.status === status);
            const { visibleTickets, hasMore, totalCount } = getPaginatedTickets(statusTickets, status);
            
            return (
              <Grid item xs={12} md={4} key={status}>
                <Paper 
                  elevation={0}
                  sx={{ 
                    p: 2, 
                    bgcolor: '#f9fafb',
                    height: '100%',
                    borderRadius: 2,
                    border: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    gap: 1
                  }}>
                    {getStatusIcon(status)}
                    <Typography variant="h6">{status}</Typography>
                    <Badge 
                      badgeContent={totalCount} 
                      color="primary" 
                      sx={{ 
                        ml: 1.5,
                        '& .MuiBadge-badge': { 
                          fontSize: '0.65rem',
                          top: -2,
                          right: -2
                        }
                      }}
                    />
                  </Box>
                  
                  <Box sx={{ 
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    gap: 1
                  }}>
                    {visibleTickets.length > 0 ? (
                      <>
                        {visibleTickets.map((ticket) => (
                          <Paper
                            key={ticket.id}
                            sx={{
                              p: 2,
                              cursor: 'pointer',
                              transition: 'all 0.2s',
                              position: 'relative',
                              height: ticketCardHeight,
                              display: 'flex',
                              flexDirection: 'column',
                              overflow: 'hidden',
                              '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: 2,
                                '& .ticket-actions': {
                                  opacity: 1
                                }
                              }
                            }}
                            onClick={() => showTicketDetails(ticket)}
                          >
                            <Box sx={{ display: 'flex', flexDirection: 'column', pr: 6 }}>
                              <Typography 
                                variant="body1" 
                                fontWeight="medium" 
                                sx={{ 
                                  mb: 1, 
                                  pr: 6,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical'
                                }}
                              >
                                {ticket.title}
                              </Typography>
                              
                              {!isMobile && (
                                <Typography 
                                  variant="body2" 
                                  color="text.secondary" 
                                  sx={{ 
                                    mb: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical'
                                  }}
                                >
                                  {ticket.description}
                                </Typography>
                              )}
                              
                              <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                mt: 'auto'
                              }}>
                                <Typography variant="caption" color="text.secondary">
                                  {ticket.requester}
                                </Typography>
                                
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Typography variant="caption" color="text.secondary">
                                    #{ticket.id}
                                  </Typography>
                                  <Chip 
                                    label={ticket.priority} 
                                    size="small" 
                                    color={getPriorityColor(ticket.priority)}
                                    sx={{ 
                                      height: 20, 
                                      '& .MuiChip-label': { 
                                        px: 1, 
                                        py: 0, 
                                        fontSize: '0.65rem' 
                                      } 
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                            
                            {/* Botões de ação */}
                            <Box 
                              className="ticket-actions"
                              sx={{ 
                                position: 'absolute', 
                                top: 8, 
                                right: 8,
                                opacity: 0,
                                transition: 'opacity 0.2s',
                                display: 'flex',
                                gap: 0.5,
                                zIndex: 10
                              }}
                            >
                              <Tooltip title="Editar">
                                <IconButton 
                                  size="small" 
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openTicketForm(ticket);
                                  }}
                                  sx={{ 
                                    bgcolor: 'background.paper',
                                    boxShadow: 1,
                                    '&:hover': { bgcolor: 'background.paper' }
                                  }}
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Ver detalhes">
                                <IconButton 
                                  size="small" 
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showTicketDetails(ticket);
                                  }}
                                  sx={{ 
                                    bgcolor: 'background.paper',
                                    boxShadow: 1,
                                    '&:hover': { bgcolor: 'background.paper' }
                                  }}
                                >
                                  <MoreHoriz fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Paper>
                        ))}
                        
                        {hasMore && (
                          <Box sx={{ textAlign: 'center', mt: 1 }}>
                            <Button 
                              onClick={() => handleLoadMore(status)} 
                              variant="text" 
                              size="small"
                            >
                              Mostrar mais {Math.min(TICKETS_PER_PAGE, statusTickets.length - visibleTickets.length)} de {statusTickets.length}
                            </Button>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        height: '100%',
                        minHeight: 100
                      }}>
                        <Typography variant="body2" color="text.secondary">
                          Nenhum ticket
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Box>
  );
};

export default DayView;