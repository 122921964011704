import { DoorSliding } from "@mui/icons-material";
import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles"
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import HeaderMenu from "src/components/header-menu";
import Api from "src/services/api";
import { Styles } from "src/styles/material-styles";
import { browserHistory } from "src/helpers/history";
import * as componentActions from '../../redux/actions/calleds';
import { AbilityContext } from "src/config/ability-context";
import clsx from 'clsx'
import { PapiroConsole } from "src/utils/papiroConsole";
import Wizard from "./components/wizard";
import { Overlay } from "src/styles/global";
import CustomizedSnackbars from "src/components/material-snackbars";
import Footer from "src/components/footer";

class ClientPortal extends Component {
    constructor(props) {
        super(props)
        const { userSession } = this.props;

        if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        this.intialState = {

        }

        this.state = {
            item: this.intialState,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
        }
        this.setNotification = this.setNotification.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.setClientPortal = this.setClientPortal.bind(this)
    }

    async componentDidMount() {
        PapiroConsole.log("Mount ClientPortal")
        await this.getPortal();
    }

    setClientPortal = (item) => {
        PapiroConsole.log("setClientPortal item")
        PapiroConsole.log(item)
        this.setState({
            item: item
        })
    }

    async getPortal() {
        const intl = this.props.intl
        this.setState({ loading: true })

        await Api.get(`/clientportal`)
            .then(result => {
                if (result.data && result.data.success) {
                    this.setState({
                        loading: false,
                        item: result.data.data
                    })
                } else {
                    this.setState({ loading: false })
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    })
                    Api.kickoff(result.data)
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(err)
            })

        this.setState({ loading: false })
    }

    setNotification = (notification) => {
        this.setState({
            openNotification: notification.openNotification, 
            notificationVariant: notification.notificationVariant, 
            notificationMessage: notification.notificationMessage
        })
    }

    setLoading = (loading) => {
        this.setState({ loading: loading })
    }

    closeNotification = () => {
        this.setState({ openNotification: false })
    }

    render() {

        const { classes, headerMenu, intl, actionsHeaderMenu } = this.props
        
        PapiroConsole.log(this.state.item)
        PapiroConsole.log("ClientPortal this.state.item")

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Container >
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                                <DoorSliding />
                            </Avatar>
                            <Typography component='h1' variant='h5' mt={2}>
                                {intl.formatMessage({ id: "client.portal" })}
                            </Typography>
                            <Wizard clientPortal={this.state.item} setLoading={this.setLoading} setNotification={this.setNotification} setClientPortal={this.setClientPortal}/>
                        </div>
                    </Container>
                    <Footer />

                    {this.state.loading && (
                        <Overlay>
                            <CircularProgress color='secondary' />
                        </Overlay>
                    )}

                    <CustomizedSnackbars
                        variant={this.state.notificationVariant}
                        message={this.state.notificationMessage}
                        isOpen={this.state.openNotification}
                        toClose={this.closeNotification}
                    />
                </main>
            </div>
        )
    }
}

ClientPortal.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ClientPortal)));

ClientPortal.contextType = AbilityContext;