import { withStyles } from "@mui/styles";
import React, { Component, useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Styles } from "src/styles/material-styles";
import PropTypes from "prop-types"
import { Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import TitleName from "src/components/title-name";
import { PapiroConsole } from "src/utils/papiroConsole";
import { SketchPicker } from 'react-color'
import SimpleCheckBox from "src/components/checkbox/check";
import { AttachFile, Delete, DeleteForever } from "@mui/icons-material";
import Dropzone from "react-dropzone";
import Api from "src/services/api";
import DialogConfirmationDeleteImage from '../../../components/dialog-confirmation-not-button'
import * as Service from '../../../services/clientportal.service'
import * as FileService from '../../../services/attachment.service';

function AppearanceStep(props) {

    const { classes, intl, backButton, nextButton, clientPortal, handleNextBarProgress, handleComplete, setLoading, setNotification, setClientPortal } = props

    const [deleteLogoImageDialog, setDeleteLogoImageDialog] = useState(false)
    const [deleteMainImageDialog, setDeleteMainImageDialog] = useState(false)

    const [item, setItem] = useState({
        id: clientPortal.id,
        title: clientPortal.title,
        showtitle: clientPortal.showtitle,
        defaultcolor: clientPortal.defaultcolor,
        enablesearch: clientPortal.enablesearch,
        searchtext: clientPortal.searchtext,
        showknowledgebases: clientPortal.showknowledgebases,
        enablelogin: clientPortal.enablelogin,
        mainimage: clientPortal.mainimage,
        mainimageid: clientPortal.mainimageid,
        logoimage: clientPortal.logoimage,
        logoimageid: clientPortal.logoimageid,
        mainimagefiles: [],
        logoimagefiles: [],
    })

    useEffect(() => {
        PapiroConsole.log("appearance useEffect")
        setItem({
            id: clientPortal.id,
            title: clientPortal.title,
            showtitle: clientPortal.showtitle,
            defaultcolor: clientPortal.defaultcolor,
            enablesearch: clientPortal.enablesearch,
            searchtext: clientPortal.searchtext,
            showknowledgebases: clientPortal.showknowledgebases,
            enablelogin: clientPortal.enablelogin,
            mainimage: clientPortal.mainimage,
            mainimageid: clientPortal.mainimageid,
            logoimage: clientPortal.logoimage,
            logoimageid: clientPortal.logoimageid,
            mainimagefiles: [],
            logoimagefiles: [],
        })
    }, [clientPortal])

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setItem({
            ...item,
            [name]: value
        })
    }

    const handleChangeSelect = (stateName, value) => {
        setItem({
            ...item,
            [stateName]: value
        })
    }

    const handleChangeColor = (color) => {
        setItem({
            ...item,
            defaultcolor: color.hex
        })
    }

    const validateImagesAtachments = (files, inputName) => {

        if (inputName === 'mainimagefiles') {
            if (files.length > 1) {
                setItem({
                    ...item,
                    mainimagefiles: []
                })
                setNotification(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
                return
            }
            else {
                setItem({
                    ...item,
                    mainimagefiles: files
                })
            }
        } else if (inputName === 'logoimagefiles') {
            if (files.length > 1) {
                setItem({
                    ...item,
                    logoimagefiles: []
                })
                setNotification(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
                return
            }
            else {
                setItem({
                    ...item,
                    logoimagefiles: files
                })
            }
        }
    }

    const removeItem = (file, inputName) => {
        if (inputName === 'mainimagefiles') {
            let array = [...item.mainimagefiles]
            let index = array.indexOf(file)
            if (index !== -1) {
                array.splice(index, 1)
                setItem({
                    ...item,
                    mainimagefiles: array
                })
            }
        } else if (inputName === 'logoimagefiles') {
            let array = [...item.logoimagefiles]
            let index = array.indexOf(file)
            if (index !== -1) {
                array.splice(index, 1);
                setItem({
                    ...item,
                    logoimagefiles: array
                })
            }
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)

        let data = new FormData()
        data.append('id', clientPortal.id)
        data.append('title', item.title)
        data.append('showtitle', item.showtitle)
        data.append('defaultcolor', item.defaultcolor)
        data.append('enablesearch', item.enablesearch)
        data.append('searchtext', item.searchtext)
        data.append('showknowledgebases', item.showknowledgebases)
        data.append('enablelogin', item.enablelogin)
        data.append('hirerid', clientPortal.hirerid)
        item.mainimagefiles.forEach(file => { data.append('mainimagefiles', file) })
        item.logoimagefiles.forEach(file => data.append('logoimagefiles', file))

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        await Api.put("/clientportal/appearance", data, config)
            .then(result => {
                if (result.data.success) {
                    PapiroConsole.log("setClientPortal")
                    PapiroConsole.log(result.data.data)
                    setClientPortal(result.data.data)
                    setLoading(false)
                    handleNextBarProgress()
                    handleComplete()
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
    }

    const deleteImage = async (name) => {

        setLoading(true)
        const result = await Service.deleteImage(item.id, name)
        if (result.success) {
            setClientPortal({ ...item, [name]: null })
            setNotification(true, 'success', intl.formatMessage({ id: "delete.sucess" }))
        } else {
            setNotification(true, 'error', result != null ? result : intl.formatMessage({ id: "process.error" }))
        }
        setDeleteLogoImageDialog(false)
        setLoading(false)
    }

    const getFile = async (hirerid, fileName, filerepositorymethod) => {
        setLoading(true)

        let url = await FileService.getFileByPath(hirerid, fileName, filerepositorymethod);
        if (url && url.data && url.data.data && url.data.success) {
            setLoading(false)
            window.open(`${url.data.data}`, '_blank')
        } else {
            setLoading(false)
            setNotification(true, 'error', intl.formatMessage({ id: "file.not.found" }))
        }
    }

    const getFileName = (fileName) => {

        let file = fileName != null ? fileName.substring(fileName.lastIndexOf('/') + 1) : "";
        return file;
    }

    return (
        <div className={classes.root}>
            <Container>
                <CssBaseline />
                <div className={classes.paper} style={{ marginTop: 0 }}>
                    <Typography component='h1' variant='h5'>
                        {<FormattedMessage id="appearance" />}
                        <TitleName />
                    </Typography>
                    <form name='myForm' className={classes.form} onSubmit={handleSubmit} encType='multipart/form-data'>
                        <Grid container spacing={2}>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.title" })} name='showtitle' stateName='showtitle' changeSelect={handleChangeSelect} selected={item.showtitle} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "title" })} name="title" value={item.title} onChange={handleChange} disabled={!item.showtitle} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "enable.search" })} name='enablesearch' stateName='enablesearch' changeSelect={handleChangeSelect} selected={item.enablesearch} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "search.text" })} name="searchtext" value={item.searchtext} onChange={handleChange} disabled={!item.enablesearch} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.knowledgebases" })} name='showknowledgebases' stateName='showknowledgebases' changeSelect={handleChangeSelect} selected={item.showknowledgebases} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "enable.login" })} name='enablelogin' stateName='enablelogin' changeSelect={handleChangeSelect} selected={item.enablelogin} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <SketchPicker
                                    color={item.defaultcolor}
                                    onChange={(value) => handleChangeColor(value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
                                <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagem principal</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <section style={{ width: '100%' }}>
                                    <div className={classes.dropzoneArea}>
                                        <Dropzone onDrop={acceptedFiles => validateImagesAtachments(acceptedFiles, "mainimagefiles")}>
                                            {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>{<FormattedMessage id="drag.and.drop" />}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {item.mainimagefiles.map(file => {
                                            return (
                                                <div className={classes.dropzoneItem} key={file.name}>
                                                    {file.name}
                                                    <DeleteForever className={classes.deleteItem} onClick={() => { removeItem(file, "mainimagefiles") }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </section>
                            </Grid>
                            {item.mainimage != null && item.mainimage != "null" && item.mainimage != "undefined" && (
                                <Grid item xs={12}>
                                    <label>Anexo</label>
                                    <ul>
                                        <li key={item.mainimage.path}>
                                            <Grid container alignItems='center'>
                                                <Grid item sm={.5}>
                                                    <AttachFile />
                                                </Grid>
                                                <Grid item sm={10.5}>
                                                    <Button onClick={() => getFile(item.mainimage.hirerid, item.mainimage.path, item.mainimage.filerepositorymethod)}>
                                                        {getFileName(item.mainimage.path)}
                                                    </Button>
                                                </Grid>
                                                <Grid item sm={1}>
                                                    <Button color='inherit' onClick={_ => setDeleteMainImageDialog(true)}>
                                                        <Delete />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </li>
                                    </ul>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
                                <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Logomarca</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <section style={{ width: '100%' }}>
                                    <div className={classes.dropzoneArea}>
                                        <Dropzone onDrop={acceptedFiles => validateImagesAtachments(acceptedFiles, "logoimagefiles")}>
                                            {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>{<FormattedMessage id="drag.and.drop" />}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {item.logoimagefiles.map(file => {
                                            return (
                                                <div className={classes.dropzoneItem} key={file.name}>
                                                    {file.name}
                                                    <DeleteForever className={classes.deleteItem} onClick={() => { removeItem(file, "logoimagefiles") }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </section>
                            </Grid>
                            {item.logoimage != null && item.logoimage != "null" && item.logoimage != "undefined" && (
                                <Grid item xs={12}>
                                    <label>Anexo</label>
                                    <ul>
                                        <li key={item.logoimage.path}>
                                            <Grid container alignItems='center'>
                                                <Grid item sm={.5}>
                                                    <AttachFile />
                                                </Grid>
                                                <Grid item sm={10.5}>
                                                    <Button onClick={() => getFile(item.logoimage.hirerid, item.logoimage.path, item.logoimage.filerepositorymethod)}>
                                                        {getFileName(item.logoimage.path)}
                                                    </Button>
                                                </Grid>
                                                <Grid item sm={1}>
                                                    <Button color='inherit' onClick={_ => setDeleteLogoImageDialog(true)}>
                                                        <Delete />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </li>
                                    </ul>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={0} style={{ marginBottom: '60px', display: 'flex', marginTop: '20px' }}>
                            <Grid item xs={12} sm={2} >
                                {backButton}
                            </Grid>
                            <Grid item xs={12} sm={2} >
                                {nextButton}
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginLeft: 'auto' }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                >
                                    {<FormattedMessage id="save.next" />}
                                </Button>
                            </Grid>
                        </Grid>
                        {deleteLogoImageDialog && (
                            <DialogConfirmationDeleteImage
                                msgButton={intl.formatMessage({ id: "confirm" })}
                                msg={intl.formatMessage({ id: "delete.image.confirmation" })}
                                msgDisagree={intl.formatMessage({ id: "cancel" })}
                                msgAgree={intl.formatMessage({ id: "yes" })}
                                handleConfirmation={_ => deleteImage('logoimage')}
                                handleClose={_ => setDeleteLogoImageDialog(false)}
                                classes={classes}
                                disabled={false}
                                icon={<Delete />}
                            />
                        )}
                        {deleteMainImageDialog && (
                            <DialogConfirmationDeleteImage
                                msgButton={intl.formatMessage({ id: "confirm" })}
                                msg={intl.formatMessage({ id: "delete.image.confirmation" })}
                                msgDisagree={intl.formatMessage({ id: "cancel" })}
                                msgAgree={intl.formatMessage({ id: "yes" })}
                                handleConfirmation={_ => deleteImage('mainimage')}
                                handleClose={_ => setDeleteMainImageDialog(false)}
                                classes={classes}
                                disabled={false}
                                icon={<Delete />}
                            />
                        )}
                    </form>
                </div>
            </Container>
        </div>
    )
}

AppearanceStep.propTypes = {
    classes: PropTypes.object.isRequired,
    back: PropTypes.func,
    next: PropTypes.func
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,

});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AppearanceStep)));