import { CircularProgress, CssBaseline, Grid } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import CustomizedSnackbars from '../../components/material-snackbars'
import * as componentActions from '../../redux/actions/chats'
import { GetChatListByTech as GetChatListByTechService, GetHistoryChatListByCustomer as GetHistoryChatListByCustomerService, getEnabledUsers, getUserStatusList, getTechinicalTeamFromUser as getTechinicalTeamListService, getProviderList as getProviderListService } from '../../services/2talk/omnichannel'
import Api from '../../services/api'
import { Overlay } from '../../styles/global'
import { Styles } from '../../styles/material-styles-omnichannel'
import { PapiroConsole } from '../../utils/papiroConsole'
import Chat from './components/chat'
import ChatMenu from './components/chat-menu'
import './styles.css'

import { getOrganizationsNewSimple, getAddressesSimpleByOrgId } from '../../services/organization.service'
import { getCategoriesByOrganizationSimpleList } from '../../services/organizationcategory.service'

import { getOrganizationsDepartamentsSimpleByOrganizationId } from '../../services/organizationdepartament.service'
import { getAllSimple as getPriorityListSimple } from '../../services/priority.service'




import DialogGeneralSm from '../../components/dialog-general-sm'
import DialogGeneralWithAutoComplete from '../../components/dialog-general-with-auto-complete'
import DialogGeneralWithCheckbox from '../../components/dialog-general-with-checkbox'
import Api2Talk from "../../services/api2talk"
import ChatChannelsTabs from './components/chat/chat-channels-tabs'
import ChatHistorySidebar from './components/chat/chat-history-sidebar'
import ChatInboxHeader from './components/chat/chat-inbox-header'
import ChatUserDetailsSidebar from './components/chat/chat-user-details-sidebar'
import DialogWithRichTextAndAttachment from './components/dialogs/dialog-with-rich-text-and-attachment'
import addressList from '../address-list'

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  content: {
    flexGrow: 1,
    marginRight: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginRight: drawerWidth, // Ajuste conforme necessário
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  open: {
    width: '80%',
    '@media (max-width:1199.9px)': {
      width: '70%',
    },
    '@media (min-width:1200px)': {
      width: '80%',
    }
  },
  toolbar: theme.mixins.toolbar,
}));

function ToTalkOmniChannel(props) {
  const { userSession, phoneNumberList, load,
    channelList, statusList, chatClosingStatus, techList, changeValues, handleInputChange, getChatsByTechWithFilters, handleChangeEditor, sendMessage, sendMessageAttachment, reSendMessage, phonenumberid, customerid, loadingcustomerid, noteDescription, customerList, intl, openOrCloseConfirmationModal, openOrCloseEndChatModal, openOrCloseDialogSyncDataModal, openOrCloseDialogAddNoteModal, openOrCloseTransferChatModal, DialogAddNoteState, DialogRemoveState, DialogEndChatState, DialogTransferChatState, DialogSyncDataState, handleStatusChangeConfirmation, handleAddnoteConfirmation, handleDataSyncConfirmation, handleEndChatConfirmation, handleTransferChatConfirmation, newStatusValue, messageNotification, notificationType, openNotificationMessage, automaticallyCreateCalledAfterEndingChat, createCalledToChat, files, validateAtachments, removeItem, onFinalizationChatByWebchat } = props
  //console.error('phoneNumberList -> ');
  //console.error(phoneNumberList);
  //console.error('load -> '+load);
  if (Api == null) {
    var l = props.match.params.lang;
    document.location.href = `/${l}/unavailable`
  }

  const classes = useStyles();

  const [loading, setLoading] = useState()
  const [loadingHistory, setLoadingHistory] = useState()
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationVariant, setNotificationVariant] = useState('success')
  const [openNotification, setOpenNotification] = useState(false)
  const [count, setCount] = useState(0)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHistorySidebarOpen, setIsHistorySidebarOpen] = useState(false);
  const [userDetailsSidebarOpen, setUserDetailsSidebarOpen] = useState(false)
  const [statusOptions, setStatusOptions] = useState([])
  const [userStatusId, setUserStatusId] = useState(6)
  const [organizationList, setOrganizationList] = useState([])
  const [AddressList, setAddressList] = useState([])
  const [organizationCategoryList, setOrganizationCategoryList] = useState([])
  const [organizationDepartamentList, setOrganizationDepartamentList] = useState([])
  const [priorityList, setPriorityList] = useState([])
  const [techinicalTeamList, setTechinicalTeamList] = useState([])
  const [providerList, setProviderList] = useState([])


  const [historyChatList, sethistoryChatList] = useState([])

  const [activeMessage, setActiveMessage] = useState(false);









  const closeNotification = () => setOpenNotification(false)


  useEffect(() => {
    props.addCurrentOmnichannelChatList([])
    props.addCurrentChatList([])
    props.addTotalChatCount(0)
    props.addCurrentChat(null)
    props.handleChatUserDetailsSidebarToggle(false)
    props.handleChatHistorySidebarToggle(false)
  }, [])


  useEffect(() => {
    
    const fetchAllData = async () => {
      try {
        setLoading(true);
        
        const promises = [
          getChatsByTech(),
          getStatusOptions(),
          getEnableUsers(),
          getOrganizationList(),
          getPriorityList(),
          getTechinicalTeamListList(props.userSession && props.userSession.user ? props.userSession.user.id : null)
        ];

        if (props.chats && props.chats.chat && props.chats.chat.organizationid) {
          let orgId = props.chats && props.chats.chat && props.chats.chat.organizationid
          promises.push(getOrganizationsDepartamentsByOrganizationId(orgId));
          promises.push(getCategoriesByOrganizationId(orgId));
          promises.push(getAddressesList(orgId));
        }

        await Promise.all(promises);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }


    };

    fetchAllData();

  }, [])

  useEffect(() => {
    if (isSidebarOpen != props.chats.issidebaropen)
      setIsSidebarOpen(props.chats.issidebaropen)
  }, [props.chats.issidebaropen])


  useEffect(() => {
    PapiroConsole.log("isHistorySidebarOpen")
    PapiroConsole.log(isHistorySidebarOpen)
    PapiroConsole.log(props.chats.ishistorysidebaryopen)
    if (isHistorySidebarOpen != props.chats.ishistorysidebaryopen)
      setIsHistorySidebarOpen(props.chats.ishistorysidebaryopen)
  }, [props.chats.ishistorysidebaryopen])



  
  useEffect(() => {
    console.log("entrei aqui activemessage")
    console.log(activeMessage)
    console.log(props.chats.activemessage)
   
    if(props.chats.activemessage == true){

      console.log("entrei aqui activemessage 2")
        getChatsByTech();
        props.templateMessageActiveUpdateChatList(false);
    }
    
      
  }, [props.chats.activemessage])



  




  const [isMobile, setIsMobile] = useState(window.innerWidth <= 599.9);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 599.9);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onEvent = (event) => {
    props.onFinalizationChatByWebchat();
  }

  useEffect(() => {
    if (props.chats.event) {
      onEvent(props.chats.event)
    }
  }, [props.chats.event]);


  /*useEffect(() => {
      setLoading(load)
  }, [load])

  useEffect(() => {

    PapiroConsole.log("entrei aqui openNotificationMessage")
    PapiroConsole.log(openNotificationMessage)
    if(openNotificationMessage == true){
      setOpenNotification(openNotificationMessage)
      setNotificationVariant(notificationType)
      setNotificationMessage(messageNotification)
    }
      
}, [openNotificationMessage])*/




  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen)
    props.handleChatUserDetailsSidebarToggle(!isSidebarOpen)
  }



  const handleHistorySidebarToggle = () => {
    setIsHistorySidebarOpen(!isHistorySidebarOpen)
    props.handleChatHistorySidebarToggle(!isHistorySidebarOpen)
  }



  const getChatsByTech = async _ => {

    PapiroConsole.log("entrei active 1")
    let result = await GetChatListByTechService()
    if (result && result.success) {
      let chatList = result.data;
      props.addCurrentOmnichannelChatList(chatList);
      props.addTotalChatCount(chatList.length);
      setCount(chatList.length);
      getCurrentChat(chatList)

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

    }
  }


  const getChatsByHistory = async (showMore = false) => {



    setLoadingHistory(true)

    let result = await GetHistoryChatListByCustomerService(props.chats.chat.customerid, props.chats.chat.id,)
    PapiroConsole.log(result)
    PapiroConsole.log("result list123")
    if (result && result.success) {
      let list = result.data;
      PapiroConsole.log("list123")
      PapiroConsole.log(list)
      PapiroConsole.log(historyChatList)



      sethistoryChatList(list)




      setLoadingHistory(false)

    }
    else {
      setLoadingHistory(false)
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

    }
  }



  const getStatusOptions = async _ => {

    let result = await getUserStatusList()
    if (result && result.success) {
      let statusOptions = result.data;
      setStatusOptions(statusOptions)

    }

    else {

      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

    }
  }

  const getEnableUsers = async _ => {

    var userStatusId = 6;
    let userId = props.userSession && props.userSession.user ? props.userSession.user.id : null
    PapiroConsole.log(props)
    PapiroConsole.log("props iniciada")
    let result = await getEnabledUsers()
    if (result && result.success) {

      PapiroConsole.log(result)
      PapiroConsole.log("result data")

      let enableUsers = result.data;
      if (userId) {
        var currentUser = enableUsers.find(item => item.userid === userId);
        userStatusId = currentUser ? currentUser.userstatusid : 6
      }

      setUserStatusId(userStatusId)

      //this.setState({ loading: false, enableUsers: enableUsers ? enableUsers : [], userStatusId: userStatusId })

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  const getOrganizationList = async _ => {


    let result = await getOrganizationsNewSimple()
    if (result && result.success) {
      if (result.data) {

        setOrganizationList(result.data)
      }
      PapiroConsole.log(result)

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }

  const getAddressesList = async (id) => {

    PapiroConsole.log('getAddressList' + props)
    let result = await getAddressesSimpleByOrgId(id)

    if(result && result.success){

      if(result.data){
        setAddressList(result.data);
      }

    }else{
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }

  const getCategoriesByOrganizationId = async (id) => {


    let result = await getCategoriesByOrganizationSimpleList(id)
    if (result && result.success) {
      if (result.data) {

        setOrganizationCategoryList(result.data)
      }
      PapiroConsole.log(result)

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }


  const getOrganizationsDepartamentsByOrganizationId = async (id) => {


    let result = await getOrganizationsDepartamentsSimpleByOrganizationId(id)
    if (result && result.success) {
      if (result.data) {

        setOrganizationDepartamentList(result.data)
      }
      PapiroConsole.log(result)

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }

  const getProviderList = async _ => {

    let result = await getProviderListService()
    if (result && result.success) {
      setProviderList(result.data)

    } else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }
  const getTechinicalTeamListList = async (id) => {

    let result = await getTechinicalTeamListService(id)
    if (result && result.success) {

      setTechinicalTeamList(result.data)

    } else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }

  const getPriorityList = async _ => {


    let result = await getPriorityListSimple()
    if (result && result.success) {

      if (result.data) {

        setPriorityList(result.data)
      }

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result);
    }
  }


  const updateNotificationsMessages = async (id) => {

    if (id) {

      let sentData = {

        chatid: id
      }

      await Api2Talk.put('chat/updatenotificationmessage', sentData)
        .then((result) => {
          PapiroConsole.log(result)
          PapiroConsole.log("result notifications")
          if (result.data.success) {

            if (result.data && result.data.data) {
              PapiroConsole.log("enntrei aqui")
              props.resetChatNumberOfNotification(id)
            }

          } else {
            /*props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage("Erro ao processar a solicitação.");
            props.setNotificationVariant("error");*/
          }
        })
        .catch((err) => {

          if (err.response && err.response.data && err.response.data.errors) {
            /*props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
            props.setNotificationVariant("error");*/


          } else {
            /*props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
            props.setNotificationVariant("error");*/
          }
        });

    }





  }




  const getCurrentChat = (chatList) => {
    if (chatList && chatList.length > 0 && props.history && props.history.location && props.history.location.state && props.history.location.state.chatId) {
      let chatId = props.history.location.state.chatId
      let currentChat = chatList.find(item => item.id == chatId)
      PapiroConsole.log(currentChat)
      PapiroConsole.log("currentChat")
      props.addCurrentChat(currentChat)
      if (currentChat?.unreadmessages) {
        updateNotificationsMessages(currentChat.id)
      }

      // if(props.history.location.state && props.history.location.state.redirectActive){

      //   setTimeout(function () { getChatsByTech() }, 8000);
        
      // }
      //updateNotificationsMessages(chatId)
    }

  }

  const backToPreviousPage = () => {

    if (props && props.match && props.history) {
      const { lang } = props.match.params
      props.history.push(`/${lang}/chat-attendance-list`)
    }

  }

  const changeStatus = async (id) => {

    setLoading(true)

    Api2Talk.put(`/enableusers/changestatus/${id}`)
      .then(result => {
        PapiroConsole.log("resultado enable users")
        PapiroConsole.log(result)
        if (result.data.success) {

          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('success')
          setNotificationMessage('Status alterado com sucesso')

        }
        else {
          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('error')
          setNotificationMessage(result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('error')
          setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
        else {
          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('error')
          setNotificationMessage(err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
      });

  }



  return (
    <main className='omnichannel-main'>
      <CssBaseline />
      <div className={clsx('omnichannel', classes.content, { [classes.open]: isSidebarOpen }, { [classes.open]: isHistorySidebarOpen })}>
        <Grid container style={{ marginBottom: '20px' }}>
          {/*<Grid item xs={12} sm={4}>
            <Button variant='outlined' color='primary' onClick={backToPreviousPage}>{<FormattedMessage id="back" />}</Button>
  </Grid>*/}
          <Grid item xs={12} sm={8} />
        </Grid>
        <ChatInboxHeader statusOptions={statusOptions} userStatusId={userStatusId} changeStatus={changeStatus} redirectprops={props} />
        <ChatChannelsTabs />
        <div style={{ display: 'flex', flexGrow: 1, maxHeight: '84%' }}>
          {(!isMobile || ((isMobile && !props.chats.issidebaropen) || (isMobile && !props.chats.ishistorysidebaryopen))) && <ChatMenu techinicalTeamList={techinicalTeamList} phoneNumberList={phoneNumberList}
            channelList={channelList}
            providerList={providerList}
            statusList={statusList}
            changeValues={changeValues}
            handleInputChange={handleInputChange}
            getChatsByTechWithFilters={getChatsByTechWithFilters}
          />}
          {(count >= 0) && (<Chat getChatsByHistory={getChatsByHistory} phonenumberid={phonenumberid} sendMessage={sendMessage} reSendMessage={reSendMessage}
            sendMessageAttachment={sendMessageAttachment}
            setNotificationMessage={setNotificationMessage}
            setNotificationVariant={setNotificationVariant}
            setOpenNotification={setOpenNotification}
            changeSelect={changeValues}
            customerid={customerid}
            loadingcustomerid={loadingcustomerid}
            customerList={customerList}
            statusList={statusList}
            onFinalizationChatByWebchat={onFinalizationChatByWebchat}
            setLoading={setLoading}
            newStatusValue={newStatusValue}
            openOrCloseEndChatModal={openOrCloseEndChatModal}
            openOrCloseTransferChatModal={openOrCloseTransferChatModal}
          />)}
        </div>
      </div>
      <ChatUserDetailsSidebar
        isOpen={isSidebarOpen}
        onClose={handleSidebarToggle}
        intl={intl}
        setLoading={setLoading}
        setNotificationMessage={setNotificationMessage}
        setNotificationVariant={setNotificationVariant}
        setOpenNotification={setOpenNotification}
        openOrCloseDialogSyncDataModal={openOrCloseDialogSyncDataModal}
        openOrCloseDialogAddNoteModal={openOrCloseDialogAddNoteModal}
        priorityList={priorityList}
        organizationList={organizationList}
        organizationCategoryList={organizationCategoryList}
        organizationDepartamentList={organizationDepartamentList}
        addressList={AddressList}
        getCategoriesByOrganizationId={getCategoriesByOrganizationId}
        getOrganizationsDepartamentsByOrganizationId={getOrganizationsDepartamentsByOrganizationId}
        getAddressesByOrganizationId={getAddressesList}
      />

      <ChatHistorySidebar
        isOpen={isHistorySidebarOpen}
        onClose={handleHistorySidebarToggle}
        intl={intl}
        setLoading={setLoading}
        setNotificationMessage={setNotificationMessage}
        setNotificationVariant={setNotificationVariant}
        setOpenNotification={setOpenNotification}
        historyChatList={historyChatList}
        loading={loadingHistory}
        getChatsByHistory={getChatsByHistory}


      />
      {/* Dialogs criados para o 2talk  */}

      <DialogGeneralSm
        dialogTitle={intl.formatMessage({ id: "status.change.confirmation" })}
        dialogSubTitle={intl.formatMessage({ id: "status.change.confirmation.info" })}
        open={DialogRemoveState}
        openOrCloseModalFunction={openOrCloseConfirmationModal}
        handleConfirmation={handleStatusChangeConfirmation}
      />
      <DialogGeneralSm
        dialogTitle={intl.formatMessage({ id: "data.sync" })}
        dialogSubTitle={intl.formatMessage({ id: "data.sync.info" })}
        open={DialogSyncDataState}
        openOrCloseModalFunction={openOrCloseDialogSyncDataModal}
        handleConfirmation={handleDataSyncConfirmation}
      />
      <DialogGeneralWithAutoComplete
        dialogTitle={intl.formatMessage({ id: "transfer.chat" })}
        dialogSubTitle={intl.formatMessage({ id: "transfer.chat.info" })}
        open={DialogTransferChatState}
        openOrCloseModalFunction={openOrCloseTransferChatModal}
        handleConfirmation={handleTransferChatConfirmation}
        options={techList}
        changeValues={changeValues}
        autocompleteStateName="techId"
        autocompleteLabel="Técnicos"

      />
      <DialogGeneralWithCheckbox
        dialogTitle={intl.formatMessage({ id: "end.chat.confirmation" })}
        dialogSubTitle={intl.formatMessage({ id: "end.chat.confirmation.info" })}
        open={DialogEndChatState}
        openOrCloseModalFunction={openOrCloseEndChatModal}
        handleConfirmation={handleEndChatConfirmation}
        chechBoxLabel={intl.formatMessage({ id: "create.called.for.chat" })}
        checkBoxStateName="createCalledToChat"
        checkBoxSelected={createCalledToChat}
        checkBoxDisabled={automaticallyCreateCalledAfterEndingChat}
        chatClosingStatus={chatClosingStatus}
        changeValues={changeValues}
        autocompleteStateName="chatClosingStatusId"
        autocompleteLabel="Status de finalização"
      />

      <DialogWithRichTextAndAttachment
        dialogTitle="Adicionar Nota"
        dialogSubTitle="Adicione abaixo sua nota e se necessário, anexos"
        open={DialogAddNoteState}
        openOrCloseModalFunction={openOrCloseDialogAddNoteModal}
        handleConfirmation={handleAddnoteConfirmation}
        nameDescription="noteDescription"
        handleChangeEditor={handleChangeEditor}
        value={noteDescription}
        classes={props.classes}
        files={files}
        validateAtachments={validateAtachments}
        removeItem={removeItem}
      />

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </main>
  )
}

ToTalkOmniChannel.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ToTalkOmniChannel))));
//ToTalkOmniChannel.contextType = AbilityContext;
