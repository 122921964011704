import React from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
  Button,
  Tooltip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  CalendarMonth,
  Today,
  ViewWeek,
  ViewDay,
  ViewModule
} from '@mui/icons-material';

const CalendarHeader = ({ 
  currentDate, 
  monthNames, 
  view, 
  setView,
  prevMonth, 
  nextMonth, 
  goToToday 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center', 
        justifyContent: 'space-between', 
        mb: 3,
        gap: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CalendarMonth sx={{ mr: 1, color: theme.palette.primary.main }} />
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Calendário de Tickets
        </Typography>
      </Box>
      
      <Box 
        sx={{ 
          display: 'flex', 
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Button 
          variant="contained" 
          size="small" 
          onClick={goToToday}
          startIcon={<Today />}
        >
          Hoje
        </Button>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
          <IconButton onClick={prevMonth} size="small">
            <ChevronLeft />
          </IconButton>
          
          <Typography variant="h6" sx={{ px: 1, minWidth: isMobile ? 'auto' : 140, textAlign: 'center' }}>
            {monthNames[currentMonth]} {currentYear}
          </Typography>
          
          <IconButton onClick={nextMonth} size="small">
            <ChevronRight />
          </IconButton>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, gap: 1 }}>
          <Tooltip title="Visualização mensal">
            <IconButton 
              onClick={() => setView('month')} 
              color={view === 'month' ? 'primary' : 'default'}
              size="small"
            >
              <ViewModule />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Visualização semanal">
            <IconButton 
              onClick={() => setView('week')} 
              color={view === 'week' ? 'primary' : 'default'}
              size="small"
            >
              <ViewWeek />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Visualização diária">
            <IconButton 
              onClick={() => setView('day')} 
              color={view === 'day' ? 'primary' : 'default'}
              size="small"
            >
              <ViewDay />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarHeader;