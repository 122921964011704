import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import {
  AlertTriangle as WarningIcon
} from 'lucide-react';

const ConfirmationDialog = ({ open, onClose, onConfirm, fromStatus, toStatus }) => {
  // Função para obter a cor baseada no status
  const getStatusColor = (status) => {
    switch(status?.toLowerCase()) {
      case 'novo':
        return '#3b82f6'; // Azul
      case 'em andamento':
        return '#f59e0b'; // Âmbar
      case 'finalizado':
        return '#10b981'; // Verde
      default:
        return '#6b7280'; // Cinza
    }
  };
  
  return (
    <Dialog 
      open={open} 
      onClose={() => onClose(false)}
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          width: '100%',
          maxWidth: '400px'
        }
      }}
    >
      <DialogTitle sx={{ pt: 3, pb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <WarningIcon size={24} color="#f59e0b" />
          <Typography variant="h6" fontWeight="bold">
            Confirmar alteração de status
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Você está prestes a alterar o status do ticket:
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Box
            sx={{
              px: 2,
              py: 1,
              borderRadius: 2,
              color: getStatusColor(fromStatus),
              bgcolor: `${getStatusColor(fromStatus)}15`,
              fontWeight: 'medium',
              fontSize: '0.9rem'
            }}
          >
            {fromStatus}
          </Box>
          
          <Box sx={{ 
            mx: 2, 
            fontSize: '1.2rem', 
            color: 'text.secondary',
            fontWeight: 'bold'
          }}>
            →
          </Box>
          
          <Box
            sx={{
              px: 2,
              py: 1,
              borderRadius: 2,
              color: getStatusColor(toStatus),
              bgcolor: `${getStatusColor(toStatus)}15`,
              fontWeight: 'medium',
              fontSize: '0.9rem'
            }}
          >
            {toStatus}
          </Box>
        </Box>
        
        <Typography variant="body2" color="text.secondary">
          Esta ação atualizará o fluxo de trabalho do ticket. Deseja continuar?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button 
          variant="outlined" 
          onClick={() => onClose(false)}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            px: 3
          }}
        >
          Cancelar
        </Button>
        <Button 
          variant="contained" 
          onClick={() => onConfirm(true)}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            px: 3,
            boxShadow: 'none'
          }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;