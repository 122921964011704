import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { AbilityContext } from '../../config/ability-context';
import { browserHistory } from '../../helpers/history';
import Api from "../../services/api";
import axios from 'axios';
import { Overlay } from "../../styles/global.js";
import { Styles } from '../../styles/material-styles';
import { useSearchParams } from "react-router-dom";
import queryString from 'query-string';
//import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { jwtDecode } from 'jwt-decode' // import dependency
import qs from 'qs';
import { PapiroConsole } from '../../utils/papiroConsole';
import { resetLocalStorageVariables } from '../../utils/storageUtils';
import * as lastCalledsQueueActions from '../../redux/actions/last-calleds-queue';
import * as userSessionActions from '../../redux/actions/user-session';

import { Box, IconButton, Paper, Stack } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataSendingConfirmation from "./components/data-sending-confirmation.js";
import DialogUserAlreadyExistsOptInMe from "src/components/dialog-user-already-exists-opt-in-me/index.js";
import { LoginActionResponse } from "src/utils/enums/login-action-response.enum.js";
import { data } from "../dashboard-report/components/latestsales/chart.js";
import DataReplacementSucess from "./components/data-replacement-sucess.js";
class Authorize extends Component {

  constructor(props) {
    super(props);
    PapiroConsole.log(this.props)
    PapiroConsole.log('Authorize this.props')
    const { match } = this.props;
    let search = this.props.location.search;

    let params = new URLSearchParams(search);
    let param_code = params.get('code');
    let param_state = params.get('state');
    let url_redirect = params.get('redirect');
    let scope = params.getAll('scope').toString();
    let uuid = params.get('uuid');
    let email = params.get('email');
    localStorage.setItem('govbr_code', `${param_code}`)


    this.itemInitialState = {
      param_code: param_code,
      param_state: param_state,
      url_redirect: url_redirect,
      scope: scope,
      uuid: uuid,
    };

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      showOptIn: false,
      showDataReplacementSucess: false,
      userAlreadyInSystem: null,
      showDataReplacement: false,
      DialogOptInUserAlreadyExistsState: false,
      document: null,
      name: null,
      email: null,
      idToken: null,
      accessTokenGov: null,
      startPage: "calleds",
      resultAuthentication: null,
      guid : null
    };

    this.auth = this.auth.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  closeDialogOptInUserAlreadyExistsModal = () => {
    this.setState({ DialogOptInUserAlreadyExistsState: false })
  }

  authNew = async () => {
    this.setState({ loading: true });

    this.props.addUserSession(null);


    let email = "sfia9907@uorak.com"
    let document = "626.026.933-16";
    let name = "sfia9907";

    let twoFactorAuthCode = localStorage.getItem('twoFactorAuthCode');
    let userAcceptedLogged = localStorage.getItem('userAcceptedLogged');
    let twoFactorAuthType = localStorage.getItem('twoFactorAuthType');
    let headers = {
      'Content-Type': 'application/json',
    };
    //alert(email);
    let code_challenge = "eyJraWQiOiJjb2RlQ3J5cHRvZ3JhcGh5IiwiYWxnIjoiZGlyIiwiZW5jIjoiQTI1NkdDTSJ9..ze948jkHFXR-r-1O.Ygyxva_H1wLTY6ic9Hmp892vIpOgdFiAqi2rxtMCrTATlQ.RVcNwgox1K5sT7l91I1RIg"

    let teste = "eyJraWQiOiJyc2ExIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIxMTQ4MDM2ODQ5MCIsImVtYWlsX3ZlcmlmaWVkIjoidHJ1ZSIsImFtciI6WyJwYXNzd2QiLCJjYXB0Y2hhIl0sInByb2ZpbGUiOiJodHRwczpcL1wvc2Vydmljb3Muc3RhZ2luZy5hY2Vzc28uZ292LmJyXC8iLCJraWQiOiJyc2ExIiwiaXNzIjoiaHR0cHM6XC9cL3Nzby5zdGFnaW5nLmFjZXNzby5nb3YuYnJcLyIsInByZWZlcnJlZF91c2VybmFtZSI6IjExNDgwMzY4NDkwIiwibm9uY2UiOiJkNDk3MWYxNS1kNTI4LTRhMGMtODQ1OS02MWY0NDZkZjI0ZTAiLCJwaWN0dXJlIjoiaHR0cHM6XC9cL3Nzby5zdGFnaW5nLmFjZXNzby5nb3YuYnJcL3VzZXJpbmZvXC9waWN0dXJlIiwiYXVkIjoic3BlZWRtYWlzLmhvbW9sb2dhLjJkby5tZXh4LmFpIiwiYXV0aF90aW1lIjoxNzQzNTMxNzYxLCJzY29wZSI6WyJvcGVuaWQiLCJnb3Zicl9jb25maWFiaWxpZGFkZXMiLCJwcm9maWxlIiwiZW1haWwiXSwibmFtZSI6Ikx1aXogT3RhdmlvIEJpb25lIE1vcmFlcyIsImV4cCI6MTc0MzUzMTkxNSwiaWF0IjoxNzQzNTMxODU1LCJqdGkiOiJjYzdhNWY3My0yZWI0LTQxNTMtYTk0Zi02MDc1YTcwNjg0NjgiLCJlbWFpbCI6Imx1aXgxOTVAZ21haWwuY29tIn0.mCdcXYH9UN1E5KCbIfqfd5kKhCuU_L9id4cV94xd0v4NpLlcBSIpFX7h2Rvi2X-g0p6AZ4GvLBFw9pT7Cho2NuUremrTlZrghqOmjRAd5IW5YmXNW-siM7-KNzJ_ifvV3VudqGJtT4Tl1VBmG8J8K67u1dOmmyiB0E0kxt7qhsbo9VsGklhInhEc1lQgpkuTIkgVz96lRlGZTD5LRp-RVwSUt7HVSCuXyrVOR4q655QHx9mStHq7GLPztnhJCH1vUhIP2euj8a7Brtitc1Qldki6gQhDnU_3Zn0pA9h1lAKPoLeuGz3iCKorTEkpQHmlfLUioNKDqYSOEJ4JidJrCg"
    const user = jwtDecode(teste); // decode your token here
    
    let guid  = user.jti.toString().toLowerCase();
    Api.post("/users/login/email",
      {
        name, email, document, useracceptedlogged: userAcceptedLogged, ismobile: false, twoFactorAuthCode, twoFactorAuthType
        , id_token_gov: "", access_token_gov: "", codeChallenge: code_challenge
      },
      { headers: headers }
    )
      .then(async result => {
        if (result.data.success) {
          if (result.data.data) {
            PapiroConsole.log("result.data.data authentication")
            PapiroConsole.log(result.data.data)
            resetLocalStorageVariables()
            this.props.clearlastCalledsQueue()
            // localStorage.setItem('accesstoken', result.data.data.accesstoken);

            this.setState({ resultAuthentication: result.data.data, document: document, name: name, email: email, guid : guid });

            if (result.data.data.startpage && result.data.data.startpage.length > 0 && result.data.data.startpage != 'undefined' && result.data.data.startpage != 'null') {
              this.setState({ startPage: result.data.data.startpage })
            }

            await this.verifyOptInByDocument(email, document, name);
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: "Ocorreu um erro na autenticação, por favor tente novamente mais tarde"
            });
          }

        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: "Ocorreu um erro na autenticação, por favor tente novamente mais tarde"
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        console.log("entrei aqui result login 1.2")
        console.log(err)
        if (err.response && err.response.data && err.response.data.errors) {
          if (err.response.data.errors && err.response.data.errors[0] == "Error: ChangePassword") {

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: this.props.intl.formatMessage({ id: "passwordpolicy.passwordexpirationtimewarning" })
            });
          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TermAccepted") {
            this.setState({
              loading: false,
              openDialogTerm: true
            });
          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: UserLogged") {
            this.setState({
              loading: false,
              openDialogUserLogged: true
            });
          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnableEmail") {
            this.setState(prevState => ({
              signInForm: {
                ...prevState.signInForm,
                twoFactorAuthCode: ""
              },
              loading: false,
              openDialogTwoFactorAuth: true,
              twoFactorAuthType: "email"
            }));

          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnable") {
            this.setState({
              loading: false,
              openDialogTwoFactorAuth: true,
              twoFactorAuthType: "app"
            });
          } else if (err.response.data.errors && err.response.data.errors[0] == "Error: UserNotFound") {
            this.props.history.push({ pathname: 'invalid-authenticated-user', state: { email: email } })
          }


          else if (err.response.data.errors && err.response.data.errors[0] == "Error: ConfigTwoFactorAuthenticationAppEnable") {
            PapiroConsole.log("entrei aqui result login 2")
            PapiroConsole.log(err.response.data)
            PapiroConsole.log(err.response.data)
            let { email } = this.state.signInForm;
            localStorage.setItem('accesstoken', err.response.data.accesstoken);
            localStorage.setItem('emailtoactivate', email);
            this.goToEnableTwoFactorAuthenticationApp()
          }

          else {

            this.setState(prevState => ({
              signInForm: {
                ...prevState.signInForm,
                twoFactorAuthCode: ""
              },
              loading: false,
              openNotification: true,
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            }));
          }

        }
        else {

          this.setState(prevState => ({
            signInForm: {
              ...prevState.signInForm,
              twoFactorAuthCode: ""
            },
            loading: false,
            notificationVariant: "error",
            openNotification: true,
            notificationMessage: this.props.intl.formatMessage({ id: "process.error" })
          }));


        }
      });



  }

  auth = async () => {
    this.setState({ loading: true });
    let govbr_state = localStorage.getItem('govbr_state');

    try {

      var ApiAxios = axios.create({
        baseURL: Api.apiurl(),
      });
      ApiAxios.defaults.headers.post['Accept'] = 'application/json';
      ApiAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

      if (this.state.item.param_code != null && this.state.item.scope != null && this.state.item.scope.indexOf("googleapis") > -1) {

        if (this.state.item.param_state.indexOf("config-edit") > -1) {
          let data = new FormData()
          for (let key in this.state.item) {
            data.append('code', this.state.item.param_code)
          }
          const paramstate = this.state.item.param_state.split(' ')
          if (paramstate.length > 1) {
            data.append('configid', paramstate[1])
          }
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              "Accept": "application/json",
              "Access-Control-Allow-Origin": "*",
            }
          }
          await Api.post('/configmailbox/oauth/gmail', data, config)
            .then(result => {
              if (result && result.data && result.data.success == true) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "success",
                  notificationMessage: "Autenticação realizada com sucesso!",
                });
                this.props.history.push(paramstate[0], { propTab: parseInt(paramstate[2]) })
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: JSON.stringify(result.data.errors)
                });

              }
            })
            .catch(err => {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: JSON.stringify(err.response)
              });
            });
        } else if (this.state.item.param_state.indexOf("organization-edit") > -1) {
          let data = new FormData()
          for (let key in this.state.item) {
            data.append('code', this.state.item.param_code)
          }
          const paramstate = this.state.item.param_state.split(' ')
          if (paramstate.length > 1) {
            data.append('organizationid', paramstate[1])
          }
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              "Accept": "application/json",
              "Access-Control-Allow-Origin": "*",
            }
          }
          await Api.post('/organizationaccountemails/oauth/gmail', data, config)
            .then(result => {
              if (result && result.data && result.data.success == true) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "success",
                  notificationMessage: "Autenticação realizada com sucesso!",
                });
                this.props.history.push(paramstate[0], { propTab: parseInt(paramstate[2]) })
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: JSON.stringify(result.data.errors)
                });

              }
            })
            .catch(err => {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: JSON.stringify(err.response ?? err)
              });
            });
        } else {
          const data = {};
          data.code = this.state.item.param_code;
          data.scope = this.state.item.scope;
          data.uuid = this.state.item.uuid;

          let config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Accept": "application/json",
              "Access-Control-Allow-Origin": "*",
            }
          };
          var u = Api.apiurl();
          //alert(u); 
          ApiAxios.post(u + "/gmail/oauth2", qs.stringify(data), config)
            .then(result => {
              //console.log(JSON.stringify(result));
              if (result && result.data && result.data.success == true) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "success",
                  notificationMessage: "Autenticação realizada com sucesso!",
                });
                let redirect_uri = "https://localhost:3001/pt/organizationaccountemail-edit?gmail_auth=true";
                if (this.state.item.param_state != null) {
                  let origin = this.state.item.param_state.split(' ')[1]
                  redirect_uri = `https://localhost:3001/pt/${origin}?gmail_auth=true`;
                }
                document.location.href = redirect_uri;
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: JSON.stringify(result.data.errors)
                });

              }

            })
            .catch(err => {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: JSON.stringify(err.response)
              });
            });
        }
      }
      else {
        if (govbr_state == this.state.item.param_state) {

          let dataSet = new FormData();
          let secret = process.env.REACT_APP_TSE_CLIENT_SECRET;
          let client_id = process.env.REACT_APP_TSE_CLIENT_ID;

          let redirecturi = 'https%3A%2F%2Fspeedmais.homologa.2do.mexx.ai%2Fpt%2Fauthorize';
          redirecturi = 'https://speedmais.homologa.2do.mexx.ai/pt/authorize';
          //redirecturi='https://speedmais.homologa.2do.mexx.ai';
          let codeverifier = localStorage.getItem('govbr_codeverifier');
          let code_challenge = localStorage.getItem('govbr_code_challenge');

          if (this.state.item.url_redirect != null) {
            redirecturi = this.state.item.url_redirect;
          }

          dataSet.append('grant_type', "authorization_code");
          dataSet.append('code', this.state.item.param_code);
          dataSet.append('redirect_uri', redirecturi);
          dataSet.append('code_verifier', codeverifier);
          //Informação codificada em Base64, no seguinte formato: CLIENT_ID:CLIENT_SECRET (senha de acesso do serviço consumidor)(utilizar codificador para Base64 site externo para gerar codificação). A palavra Basic deve está antes da informação.

          let token = Buffer.from(client_id + ':' + secret).toString('base64');
          let config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Authorization": "Basic " + token,
              //"Access-Control-Allow-Origin":"*",
            }
          };
          const data = {};
          data.grant_type = "authorization_code";
          data.code = this.state.item.param_code;
          data.redirect_uri = redirecturi;
          data.code_verifier = codeverifier;


          let url = process.env.REACT_APP_TSE_CLIENT_REDIRECT_URL;
          if (url == null) {
            console.log("process.env.REACT_APP_TSE_CLIENT_REDIRECT_URL null")
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
              url = "https://sso.staging.acesso.gov.br"
              client_id = "speedmais.homologa.2do.mexx.ai"
              secret = "guvhEYTAySOTrjINWWK44cdiNJKUSoGijtaeyKONjzcM3HT70xWaRWeV4Mrb3l1-T24Rlmp91TNDYyIsmXzjVg"
            }
            else {
              url = "https://sso.acesso.gov.br/authorize"
              client_id = "speedmais.homologa.2do.mexx.ai"
              secret = "guvhEYTAySOTrjINWWK44cdiNJKUSoGijtaeyKONjzcM3HT70xWaRWeV4Mrb3l1-T24Rlmp91TNDYyIsmXzjVg"
            }
          } else {
            console.log(url)
          }
          //console.log(dataSet);
          //console.log(config);
          await ApiAxios.post(url + "/token", qs.stringify(data), config)
            .then(async result => {
              //console.log('result');
              //console.log(JSON.stringify(result.data));
              const user = jwtDecode(result.data.id_token); // decode your token here
              localStorage.setItem('govbr_result', JSON.stringify(user));
              //console.log(JSON.stringify(user));

              this.setState({
                loading: true,
                openNotification: true,
                notificationVariant: "info",
                notificationMessage: "Autenticação realizada, recuperando autorização...",
              });

              /*
                  {
                          "access_token": "(Token de acesso a recursos protegidos do autenticador, bem como serviços do Login Único.)",
                          "id_token": "(Token de autenticação com informações básicas do usuário.)",
                          "token_type": "(O tipo do token gerado. Padrão: Bearer)",
                          "expires_in": "(Tempo de vida do token em segundos.)"
                  }                
              */
              let config = {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "Accept": "application/json",
                  //"Access-Control-Allow-Origin":"*",
                }
              };

              /* 
              ApiAxios.get(url+"/jwk", config)
                  .then(result => {
                      console.log(JSON.stringify(result));
                      localStorage.setItem('govbr_claims', result);
      
                      this.setState({
                          loading: false,
                          openNotification: true,
                          notificationVariant: "success",
                          notificationMessage: "Autenticação realizada com sucesso!",
                      });
      
                  })
                  .catch(err => {
                      this.setState({
                          loading: false,
                          openNotification: true,
                          notificationVariant: "error",
                          notificationMessage: JSON.stringify(err.response)
                      });
              });
              */

              this.props.addUserSession(null);


              let email = user.email.toString().toLowerCase();
              let document = user.preferred_username.toString().toLowerCase();
              if(document != null) {
                document = this.formatarCPF(document)
              }
              
              let name = user.name.toString().toLowerCase();
              let guid  = user.jti.toString().toLowerCase();

              let twoFactorAuthCode = localStorage.getItem('twoFactorAuthCode');
              let userAcceptedLogged = localStorage.getItem('userAcceptedLogged');
              let twoFactorAuthType = localStorage.getItem('twoFactorAuthType');
              let nonce = localStorage.getItem('govbr_nonce');
              let headers = {
                'Content-Type': 'application/json',
              };
              //alert(email);
              let code_challenge = localStorage.getItem('govbr_code_challenge')
              

              Api.post("/users/login/email",
                {
                  name, email, useracceptedlogged: userAcceptedLogged, ismobile: false, twoFactorAuthCode, twoFactorAuthType, document
                  , id_token_gov: result.data.id_token, access_token_gov: result.data.access_token, codeChallenge: code_challenge, nonce : nonce
                },
                { headers: headers }
              )
                .then(async result => {
                  if (result.data.success) {

                    resetLocalStorageVariables()
                    this.props.clearlastCalledsQueue()
                    this.setState({ resultAuthentication: result.data.data, document: document, name: name, email: email, guid : guid });
                    if (result.data.data.startpage && result.data.data.startpage.length > 0 && result.data.data.startpage != 'undefined' && result.data.data.startpage != 'null') {
                      this.setState({ startPage: result.data.data.startpage })
                    }
                    await this.verifyOptInByDocument(email, document, name);


                  } else {
                    this.props.history.push({ pathname: 'invalid-authenticated-user', state: { email: email } })
                  }
                })
                .catch(err => {
                  const intl = this.props.intl;
                  console.log("entrei aqui result login 1.2")
                  console.log(err)
                  if (err.response && err.response.data && err.response.data.errors) {
                    if (err.response.data.errors && err.response.data.errors[0] == "Error: ChangePassword") {

                      this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: this.props.intl.formatMessage({ id: "passwordpolicy.passwordexpirationtimewarning" })
                      });
                    } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TermAccepted") {
                      this.setState({
                        loading: false,
                        openDialogTerm: true
                      });
                    } else if (err.response.data.errors && err.response.data.errors[0] == "Error: UserLogged") {
                      this.setState({
                        loading: false,
                        openDialogUserLogged: true
                      });
                    } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnableEmail") {
                      this.setState(prevState => ({
                        signInForm: {
                          ...prevState.signInForm,
                          twoFactorAuthCode: ""
                        },
                        loading: false,
                        openDialogTwoFactorAuth: true,
                        twoFactorAuthType: "email"
                      }));

                    } else if (err.response.data.errors && err.response.data.errors[0] == "Error: TwoFactorAuthenticationEnable") {
                      this.setState({
                        loading: false,
                        openDialogTwoFactorAuth: true,
                        twoFactorAuthType: "app"
                      });
                    } else if (err.response.data.errors && err.response.data.errors[0] == "Error: UserNotFound") {
                      this.props.history.push({ pathname: 'invalid-authenticated-user', state: { email: email } })
                    }


                    else if (err.response.data.errors && err.response.data.errors[0] == "Error: ConfigTwoFactorAuthenticationAppEnable") {
                      PapiroConsole.log("entrei aqui result login 2")
                      PapiroConsole.log(err.response.data)
                      PapiroConsole.log(err.response.data)
                      let { email } = this.state.signInForm;
                      localStorage.setItem('accesstoken', err.response.data.accesstoken);
                      localStorage.setItem('emailtoactivate', email);
                      this.goToEnableTwoFactorAuthenticationApp()
                    }

                    else {

                      this.setState(prevState => ({
                        signInForm: {
                          ...prevState.signInForm,
                          twoFactorAuthCode: ""
                        },
                        loading: false,
                        openNotification: true,
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                      }));
                    }

                  }
                  else {

                    this.setState(prevState => ({
                      signInForm: {
                        ...prevState.signInForm,
                        twoFactorAuthCode: ""
                      },
                      loading: false,
                      notificationVariant: "error",
                      openNotification: true,
                      notificationMessage: this.props.intl.formatMessage({ id: "process.error" })
                    }));


                  }
                });




            })
            .catch(err => {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response.data.error + " -> " + err.response.data.error_description
              });
              window.history.back(-1);
            });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: "Acesso não permitido."
          });

        }

      }

    } catch (err) {
      console.error(err);

    } finally {
      this.setState({ loading: false });
    }
  }
  closeNotification() {
    this.setState({ openNotification: false });
  }



  async componentDidMount() {
    // await this.authNew();
    await this.auth();
    // await this.verifyOptInByDocument("417.257.470-06", "osvaldo5846@uorak.com", "Luiz Otavio Bione Moraes");
  }

  formatarCPF(cpf) {
    return cpf.replace(/\D/g, '') // Remove tudo que não for número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})$/, '$1-$2');
  }



  verifyOptInByDocument = async (email, document, name) => {
    var resultAuthentication = this.state.resultAuthentication;

    if (resultAuthentication != null) {
      this.setState({ loading: true });

      let data = {

        document: document,
        email: email,
        userId: resultAuthentication.id,
        guid : this.state.guid
      }


      let token = resultAuthentication.accesstoken
      Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      let url = `/optinloginauthentication/verify-opt-in`;
      await Api.post(url, data)
        .then(result => {
          if (result.data.success) {
            if (result.data.data) {
              PapiroConsole.log("result.data")
              PapiroConsole.log(result.data)

              if (result.data.data.loginactionresponse == LoginActionResponse.LoginAndRedirect) {

                this.setState({
                  loading: false
                });

                this.redirectToLogin()

              }
              else if (result.data.data.loginactionresponse == LoginActionResponse.OptInDenied) {

                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: "Autenticação não permitida, o usuário respondeu a Confirmação de envio de dados com 'NÃO' anteriormente. Você sera redirecionado para tela de login"
                });

                const { history } = this.props;
                setTimeout(function () { history.push("/"); }, 8000);



              }
              else if (result.data.data.loginactionresponse == LoginActionResponse.DataOverwriteProcessNotCompleted) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: "Autenticação não permitida, o processo de substituição de dados está sendo realizado, aguarde a conclusão do processo."
                });

                const { history } = this.props;
                setTimeout(function () { history.push("/"); }, 8000);

              }
              else {

                this.setState({
                  showOptIn: true,
                  userAlreadyInSystem: result.data.data != null && result.data.data.usersresponse != null && result.data.data.usersresponse.length > 0 ? result.data.data.usersresponse : null,
                  loading: false,
                  showDataReplacement: result.data.data.loginactionresponse == LoginActionResponse.ShowOptInAndDataReplacement ? true : false
                });


              }
            }


          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

            });
          }
        });


    }else{
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Ocorreu um erro na verificação da resposta de confirmação de envio de dados, por favor tente novamente mais tarde"
      });
    }



  };

  handleOptIn = async (optInResponse = true) => {



    var resultAuthentication = this.state.resultAuthentication;

    if (resultAuthentication != null) {

      this.setState({ loading: true })
      let headers = {
        'Content-Type': 'application/json',
      };



      let data = {
        document: this.state.document,
        name: this.state.name,
        email: this.state.email,
        optInResponse: optInResponse,
        userId: resultAuthentication.id,
        guid : this.state.guid
      }
      PapiroConsole.log("this.state.email")
      PapiroConsole.log("this.state.document")
      PapiroConsole.log("this.state.name")
      PapiroConsole.log(this.state.email)
      PapiroConsole.log(this.state.document)
      PapiroConsole.log(this.state.name)

      let token = resultAuthentication.accesstoken;
      Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      await Api.post('/optinloginauthentication', data, { headers: headers })
        .then(result => {
          if (result && result.data && result.data.success == true) {


            if (optInResponse == false) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "Você negou a confirmação de envio de dados, a autenticação não foi permitida,você sera redirecionado para o login.",
                showOptIn: false,
              });

              const { history } = this.props;
              setTimeout(function () { history.push("/"); }, 8000);
            }
            else {
               this.redirectToLogin()
            }


          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: JSON.stringify(result.data.errors)
            });

          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: JSON.stringify(err.response)
          });
        });

    }
    else{
  
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: "Ocorreu um erro na resposta de confirmação de envio de dados, por favor tente novamente mais tarde"
        });
      
    }



  }


  handleOptInConfirmation = async () => {

    PapiroConsole.log("entrei aqui 1 ")
    const intl = this.props.intl;

    let data = new FormData();



    //caso já tenha usuário no sistema abrir modal de usuário já existe, substituição de dados
    if (this.state.showDataReplacement) {
      PapiroConsole.log("entrei aqui 2 ")
      this.setState({ DialogOptInUserAlreadyExistsState: true })

    }
    //caso não tenha usuário no sistema, registrar optin e redirecionar
    else {
      await this.handleOptIn(true);
      
    }

  }



  redirectToLogin = async () => {



    var resultAuthentication = this.state.resultAuthentication;

    if (resultAuthentication != null) {
      localStorage.setItem('userid', resultAuthentication.id);
      localStorage.setItem("refreshTokenTime", "0");
      this.props.addUserSession(resultAuthentication);
      localStorage.setItem('accesstoken', resultAuthentication.accesstoken);
      localStorage.setItem('accesstokenreports', resultAuthentication.accesstokenreports);
      localStorage.setItem('accesstoken2talk', resultAuthentication.accesstoken2talk);
      localStorage.setItem('reload', true);
      const rolesUser = resultAuthentication?.userrole ?? [];
      rolesUser.forEach(obj => {
        if (obj.role && obj.role.id === 4) {
          localStorage.setItem('@user-client-session-2do-logged', JSON.stringify({
            name: resultAuthentication?.name,
            email: resultAuthentication?.email
          }));
          return;
        }
      });

      var l = "pt";
      if (this.props.match && this.props.match.params && this.props.match.params.lang) {
        l = this.props.match.params.lang;
      }

      document.location.href = `/${l}/${this.state.startPage}`

    }
    else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Ocorreu um erro ao redirecionar para o login, por favor tente novamente mais tarde"
      });
    }


  }


  dataOverwriteProcess = () => {

    let headers = {
      'Content-Type': 'application/json',
    };

    this.setState({ loading: true })

    var userId = this.state.resultAuthentication && this.state.resultAuthentication.id ? this.state.resultAuthentication.id : null;


    if (userId != null) {

      let token = this.state.resultAuthentication.accesstoken;
      Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      Api.post("/optinloginauthentication/data-overwrite-process",
        {
          email: this.state.email, document: this.state.document, name: this.state.name, userId: this.state.resultAuthentication.id, transferRoles : this.state.resultAuthentication.transferroles != null ? this.state.resultAuthentication.transferroles : null, guid : this.state.guid
        },
        { headers: headers }
      )
        .then(async result => {

          this.setState({ loading: false })
          if (result.data.success) {
            resetLocalStorageVariables()
            this.props.clearlastCalledsQueue()

            this.closeDialogOptInUserAlreadyExistsModal()

            this.setState({
              loading: false,
              showOptIn: false,
              showDataReplacementSucess: true
            });


            // this.setState({
            //   loading: false,
            //   openNotification: true,
            //   notificationVariant: "error",
            //   notificationMessage: "O processo de substituição de dados foi iniciado, aguarde a conclusão do processo."
            // });
            // const { history } = this.props;
            // setTimeout(function () { history.push("/"); }, 8000);
          }
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: JSON.stringify(err.response ?? err)
          });
        });

    }
    else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Ocorreu um erro na substituição de dados, tente novamente mais tarde."
      });
    }



  }

  handleBackToLogin = () => {

    this.props.history.push({
      pathname: `/pt`,

    });
  };

  render() {

    PapiroConsole.log("this.state.DialogOptInUserAlreadyExistsState")
    PapiroConsole.log(this.state.DialogOptInUserAlreadyExistsState)

    PapiroConsole.log(this.state.email)
    PapiroConsole.log(this.state.document)
    PapiroConsole.log(this.state.name)

    return (
      <div>
        <main>
          <Container component='main' maxWidth='md' sx={{ mt: 10, mb: 10 }}>


            {this.state.showOptIn == true &&
              <DataSendingConfirmation handleOptInConfirmation={this.handleOptInConfirmation} handleOptInDenied={() => this.handleOptIn(false)} email={this.state.email} document={this.state.document} />
            }

            {this.state.showDataReplacementSucess == true &&
              <DataReplacementSucess handleBackToLogin={this.handleBackToLogin} />
            }



            {/* Botão de voltar à página de login */}
            {this.state.showOptIn == true &&

              <Box mt={4} display="flex" justifyContent="flex-end" >
                <Button
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  onClick={this.handleBackToLogin}
                >
                  Voltar à Página de Login
                </Button>
              </Box>
            }

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }

            <DialogUserAlreadyExistsOptInMe
              open={this.state.DialogOptInUserAlreadyExistsState}
              openOrCloseModalFunction={this.closeDialogOptInUserAlreadyExistsModal}
              handleConfirmation={this.dataOverwriteProcess}
              userAlreadyInSystem={this.state.userAlreadyInSystem}
              name ={this.state.name}
              email ={this.state.email}
              document ={this.state.document}
              userId = {this.state.resultAuthentication ? this.state.resultAuthentication.id : null}
            />
          </Container>
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userSession: state.userSession,
  match: state.match
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, userSessionActions, lastCalledsQueueActions), dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Authorize)));

Authorize.contextType = AbilityContext;