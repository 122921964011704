import React, { useState, useEffect } from 'react';
import { AppBar, Avatar, Button, Divider, Drawer, Grid, IconButton, Input, List, ListItem, ListItemIcon, ListItemText, TextField, Toolbar, Typography } from '@mui/material';
import { ChevronRight, ChevronLeft, Home, Info, Clear } from '@mui/icons-material';
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import { bindActionCreators } from 'redux'
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { connect } from 'react-redux';
import './styles.css'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import SimpleAutoComplete from '../autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChatLabel from './label/label';
import ChatNote from './note';
import PersonIcon from '@mui/icons-material/Person';
import Api2Talk from "../../../../services/api2talk";
import { Menu, MenuItem } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const drawerWidth = 230;

function stringToColor(string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 0,
    borderLeft: 0,
    height: '101%',
    backgroundColor: '#F3F3F3',
    color: 'white',
    position: 'relative',
    top: 0,
    right: 0,
    overflowX: 'hidden',
    transition: 'border-left,width 0.5s cubic-bezier(0.4, 0, 0.6, 1)',
    padding: 0
  },
  open: {
    borderLeft: '10px solid #DADADA',
    width: '20%',
    '@media (max-width:599.9px)': {
      width: '30%',
      top: '20px',
      height: '116.5%'
    },
    '@media (min-width:600px) and (max-width:1199.9px)': {
      width: '30%',
    },
    '@media (min-width:1200px)': {
      width: '20%',
    }
  },
  gridClose: {
    display: 'none!important'
  },
  gridOpen: {
    display: 'flex!important'
  },
  gridItemCenter: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  gridItemSubtitleColor: {
    color: '#B4B4B4',
    fill: '#B4B4B4'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginLeft: 2 * drawerWidth, // Ajuste conforme necessário
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: theme.mixins.toolbar,
}));

function ChatUserDetailsSidebar(props) {
  const { isOpen, onClose, chats, intl } = props
  PapiroConsole.log(props)
  PapiroConsole.log("props da nota aqui")
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [editChatDetails, setEditChatDetails] = React.useState(false)
  const [chatDetailsPriorityList, setChatDetailsPriorityList] = React.useState(props.priorityList ? props.priorityList : [] ) // Substituir pela lista da API
  const [chatDetailsCategoryList, setChatDetailsCategoryList] = React.useState(props.categoryList ? props.categoryList : []) // Substituir pela lista da API
  const [chatDetailsDepartamentList, setChatDetailsDepartamentList] = React.useState(props.departamentList ? props.departamentList : []) // Substituir pela lista da API
  //const [chatDetailsAssignedUserList, setChatDetailsAssignedUserList] = React.useState([{ id: 1, name: 'Pessoa' }]) // Substituir pela lista da API
  const [chatDetailsOrganizationList, setChatDetailsOrganizationList] = React.useState(props.organizationList ? props.organizationList : []) // Substituir pela lista da API
  const [chatDetailsAddressList, setChatDetailsAddressList] = React.useState(props.addressList ? props.addressList : []) // Substituir pela lista da API

  const { control: chatDetailsControl, handleSubmit: chatDetailsHandleSubmit, setValue : setValueChatDetails } = useForm({
    defaultValues: {
      title: chats && chats.chat && chats.chat.title ? chats.chat.title : '',
      //assigneduser: chats && chats.chat && chats.chat.assignedusername ? chats.chat.assignedusername : '',
      organization : chats && chats.chat && chats.chat.organization ? chats.chat.organization : null,
      priority: chats && chats.chat && chats.chat.priority ? chats.chat.priority : null,
      category: chats && chats.chat && chats.chat.organizationcategory ? chats.chat.organizationcategory : null,
      departament: chats && chats.chat && chats.chat.organizationdepartament ? chats.chat.organizationdepartament : null,
      address: chats && chats.chat && chats.chat.address ? chats.chat.address : null,
    },
  })

  const changeValues = (stateName, value) => {

      PapiroConsole.log(stateName)
      PapiroConsole.log(value)
      PapiroConsole.log("stateName,value")

      if(stateName == "organization"){
            if(value && value.id != 0){
              props.getCategoriesByOrganizationId(value.id);
              props.getOrganizationsDepartamentsByOrganizationId(value.id)
              props.getAddressesByOrganizationId(value.id)
            }
            else{
              setChatDetailsCategoryList([]);
              setChatDetailsDepartamentList([]);
              setChatDetailsAddressList([]);
              setValueChatDetails('category', ({id:0, name:''}));
              setValueChatDetails('departament', ({id:0, name:''}));
              setValueChatDetails('address',({id:0, name:''}));
            }
            
      }

  };


  const handleShowEditChatDetails = _ => {
    setEditChatDetails(!editChatDetails)
  }
  

  const [editChatUserDetails, setEditChatUserDetails] = React.useState(false)
  const { control: chatUserDetailsControl, handleSubmit: chatUserDetailsHandleSubmit, setValue } = useForm({
    defaultValues: {
      name: (chats && chats.chat && chats.chat.customer && chats.chat.customer.name) ? chats.chat.customer.name : "",
      email: (chats && chats.chat && chats.chat.customer && chats.chat.customer.email) ? chats.chat.customer.email : "",
      phone: (chats && chats.chat && chats.chat.customer && chats.chat.customer.phone) ? chats.chat.customer.phone : ""
    },
  })

  //atualizar valores do sobre depois de atualizado
  useEffect(() => {
    

    setValueChatDetails('title', (chats && chats.chat && chats.chat.title ? chats.chat.title :''));
    setValueChatDetails('organization', (chats && chats.chat && chats.chat.organization) ? chats.chat.organization :  {id : 0, name: ''});
    setValueChatDetails('priority', (chats && chats.chat && chats.chat.priority) ? chats.chat.priority :  {id : 0, name: ''});
    setValueChatDetails('category', (chats && chats.chat && chats.chat.organizationcategory) ? chats.chat.organizationcategory :  {id : 0, name: ''});
    setValueChatDetails('departament', (chats && chats.chat && chats.chat.organizationdepartament) ? chats.chat.organizationdepartament :  {id : 0, name: ''});
    setValueChatDetails('address',(chats && chats.chat && chats.chat.address) ? chats.chat.address : {id : 0, name: ''})
  }, [chats && chats.chat && chats.chat.title, chats && chats.chat && chats && chats.chat.organization, chats && chats.chat && chats.chat.priority, chats && chats.chat && chats.chat.category, chats && chats.chat && chats.chat.organizationdepartament, chats && chats.chat && chats.chat.address ]);

  useEffect(() => {

    setValue('name', (chats && chats.chat && chats.chat.customer && chats.chat.customer.name) ? chats.chat.customer.name : "");
    setValue('email', (chats && chats.chat && chats.chat.customer && chats.chat.customer.email) ? chats.chat.customer.email : "");
    setValue('phone', (chats && chats.chat && chats.chat.customer && chats.chat.customer.phone) ? chats.chat.customer.phone : "");
  }, [chats && chats.chat && chats.chat.customer && chats.chat.customer.name, setValue]);

 


  //atualizar valores dos autocompletes
  useEffect(() => {
    
    if (props.priorityList && props.priorityList !== chatDetailsPriorityList ) {
      setChatDetailsPriorityList(props.priorityList);
    }

 
    if (props.organizationList && props.organizationList !== chatDetailsOrganizationList) {
      setChatDetailsOrganizationList(props.organizationList);
    }
    if (props.organizationCategoryList && props.organizationCategoryList !== chatDetailsOrganizationList) {
      setChatDetailsCategoryList(props.organizationCategoryList);
    if (props.organizationDepartamentList && props.organizationDepartamentList !== chatDetailsDepartamentList) {
      setChatDetailsDepartamentList(props.organizationDepartamentList);
    }
    if(props.addressList && props.addressList !== chatDetailsAddressList){
      setChatDetailsAddressList(props.addressList);
    }

      
    }
  }, [props.priorityList, props.organizationList, props.organizationCategoryList, props.organizationDepartamentList, props.addressList]);

  const handleShowEditChatUserDetails = _ => {
    setEditChatUserDetails(!editChatUserDetails)
  }
  const validations = (data) => {
    let result = true;

    for (let element in data) {
      PapiroConsole.log(element)
      PapiroConsole.log(data[element])
      PapiroConsole.log("Dados aqui")
      if (data[element] == null || data[element] == '') {
        if (element == 'name') {

          props.setNotificationMessage("Campo Nome é obrigatório");

        }
        else if (element == 'email') {

          props.setNotificationMessage("Campo E-mail é obrigatório");

        }
        else if (element == 'phone') {

          props.setNotificationMessage("Campo Telefone é obrigatório");
        }

        props.setLoading(false);
        props.setOpenNotification(true);
        props.setNotificationVariant("error");
        return false
      }
    };

    return true;


  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };
  const onSubmitEditChatUserDetails = (data) => {

    props.setLoading(true);
    //var validation = validations(data)

      data.id = chats && chats.chat && chats.chat.customer && chats.chat.customer.id ? chats.chat.customer.id : 0
      data.chatid = chats && chats.chat && chats.chat.id ? chats.chat.id : 0
      data.chatmessageproviderid = chats.chat.messageproviderid ; 
      Api2Talk.put('customer/updateuserinfos', data)
        .then((result) => {
          if (result.data.success) {
            PapiroConsole.log("result aqui depois do submit")
            PapiroConsole.log(result)
            if (result.data && result.data.data) {
              props.updateUserInformations(result.data.data, chats.chat.id)
              setEditChatUserDetails(false)

              props.setLoading(false);
              props.setOpenNotification(true);
              props.setNotificationMessage("Suas informações foram atualizadas com sucesso");
              props.setNotificationVariant("success");



            }

          } else {
            props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage("Erro ao processar a solicitação.");
            props.setNotificationVariant("error");
          }
        })
        .catch((err) => {
          PapiroConsole.log(err)
          PapiroConsole.log("err")
          if (err.response && err.response.data && err.response.data.errors) {
            props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
            props.setNotificationVariant("error");


          } else {
            props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
            props.setNotificationVariant("error");
          }
        });
      PapiroConsole.log(data)
      PapiroConsole.log("data aqui")
    

  }

  const onSubmitEditChatDetails = (data) => {

    PapiroConsole.log(data)
    PapiroConsole.log("data informado")
    props.setLoading(true);

    let sentData = {
      categoryId : data.category  && data.category.id != 0  ? data.category.id : null,
      departamentId : data.departament && data.departament.id != 0 ? data.departament.id : null,
      organizationId : data.organization  && data.organization.id != 0  ? data.organization.id : null,
      priorityId : data.priority &&  data.priority.id != 0 ? data.priority.id : null,
      title : data.title ? data.title : null,
      addressId : data.address && data.address.id !== 0 ? data.address.id : null
      
    }
    PapiroConsole.log(sentData.departamentId)
    PapiroConsole.log("sentData.departamentId")
    sentData.chatid = chats && chats.chat && chats.chat.id ? chats.chat.id : 0
    Api2Talk.put('chat/updatechatdetails', sentData)
      .then((result) => {
        if (result.data.success) {
          PapiroConsole.log("result aqui depois do submit")
          PapiroConsole.log(result)
          if (result.data && result.data.data) {
            
            //props.updateUserInformations(result.data.data, chats.chat.id)
            //setEditChatUserDetails(false)
            props.updateUserDetailsInformations(result.data.data, chats.chat.id)
            PapiroConsole.log(result.data.data)
           // props.setEditChatUserDetails(false);

            props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage("Suas informações foram atualizadas com sucesso");
            props.setNotificationVariant("success");



          }

        } else {
          props.setLoading(false);
          props.setOpenNotification(true);
          props.setNotificationMessage("Erro ao processar a solicitação.");
          props.setNotificationVariant("error");
        }
      })
      .catch((err) => {
        PapiroConsole.log(err)
        PapiroConsole.log("err")
        if (err.response && err.response.data && err.response.data.errors) {
          props.setLoading(false);
          props.setOpenNotification(true);
          props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
          props.setNotificationVariant("error");


        } else {
          props.setLoading(false);
          props.setOpenNotification(true);
          props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
          props.setNotificationVariant("error");
        }
      });
    PapiroConsole.log(data)
    PapiroConsole.log("data aqui")


  }
  
  

  PapiroConsole.log("=== ChatUserDetailsSidebar -> intl ===")
  PapiroConsole.log(intl)
  PapiroConsole.log(props)
  PapiroConsole.log(chatUserDetailsControl)

  const syncCustomerData = () => {

    props.openOrCloseDialogSyncDataModal()

    /* let data = {
       //id : chats && chats.chat && chats.chat.customer && chats.chat.customer.id ? chats.chat.customer.id : 0,
       //chatid : chats && chats.chat && chats.chat.id ? chats.chat.id : 0,
       name: (chats && chats.chat && chats.chat.customer && chats.chat.customer.name) ? chats.chat.customer.name : "",
       email: (chats && chats.chat && chats.chat.customer && chats.chat.customer.email) ? chats.chat.customer.email : "",
       phone: (chats && chats.chat && chats.chat.customer && chats.chat.customer.phone) ? chats.chat.customer.phone : ""
 
     }
     var validation = validations(data)
     data.id = chats && chats.chat && chats.chat.customer && chats.chat.customer.id ? chats.chat.customer.id : 0
     data.chatid = chats && chats.chat && chats.chat.id ? chats.chat.id : 0
     
     props.setLoading(true);
 
     if (validation) {
 
       Api2Talk.put('customer/syncdata', data)
         .then((result) => {
           if (result.data.success) {
             PapiroConsole.log("result aqui depois do submit")
             PapiroConsole.log(result)
             if (result.data && result.data.data) {
              
               setEditChatUserDetails(false)
               props.setLoading(false);
               props.setOpenNotification(true);
               props.setNotificationMessage("Suas informações foram atualizadas com Sucesso");
               props.setNotificationVariant("success");
 
             }
 
           } else {
             props.setLoading(false);
             props.setOpenNotification(true);
             props.setNotificationMessage("Erro ao processar a solicitação.");
             props.setNotificationVariant("error");
           }
         })
         .catch((err) => {
           PapiroConsole.log(err)
           PapiroConsole.log("err")
           if (err.response && err.response.data && err.response.data.errors) {
             props.setLoading(false);
             props.setOpenNotification(true);
             props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
             props.setNotificationVariant("error");
 
 
           } else {
             props.setLoading(false);
             props.setOpenNotification(true);
             props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
             props.setNotificationVariant("error");
           }
         });
       PapiroConsole.log(data)
       PapiroConsole.log("data aqui")
     }*/

  }


  if (chats && chats.chat && chats.chat.customer && chats.chat.customer.name) {
    return (
      <div className={`${classes.root} ${isOpen ? classes.open : ''} omnichannel-chat-user-details-sidebar`}>
        <Grid className={`${isOpen ? classes.gridOpen : classes.gridClose}`} sx={{ padding: '3%' }} container spacing={0}>
          <Grid className={`${classes.gridItemCenter}`} item xs={12} sm={2} md={2} lg={2} xl={2} >
            <Avatar
              sx={{ width: 56, height: 56 }}
              {...stringAvatar(chats.chat.customer.name)} />
          </Grid>
          <Grid className={`${classes.gridItemCenter} omnichannel-chat-user-details-sidebar-header`} item xs={12} sm={8} md={8} lg={8} xl={8} >
            {chats.chat.title ? chats.chat.title + '-' : ''}  {chats.chat.customer.name}
          </Grid>
          <Grid className={`${classes.gridItemCenter}`} item xs={9} sm={1} md={1} lg={1} xl={1} >
            <IconButton onClick={handleMenuOpen}>
              {menuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={syncCustomerData}>Sincronizar dados</MenuItem>
            </Menu>
          </Grid>
          <Grid className={`${classes.gridItemCenter}`} item xs={3} sm={1} md={1} lg={1} xl={1} >
            <IconButton onClick={onClose}>
              <Clear />
            </IconButton>
          </Grid>

        </Grid>
        <Divider className={`${isOpen ? classes.gridOpen : classes.gridClose}`} />
        <Grid className={`omnichannel-chat-user-details-sidebar-about ${isOpen ? classes.gridOpen : classes.gridClose}`} sx={{ padding: '3%' }} container spacing={0}>
          <Grid className='omnichannel-chat-user-details-sidebar-header' item xs={12} sm={8} md={9} lg={9} xl={9} >
            Sobre
          </Grid>
          <Grid className={`${classes.gridItemSubtitleColor}`} item xs={12} sm={4} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'flex-end' }} >
            {!editChatUserDetails && <Button variant="outlined"
              size="small"
              style={{ textTransform: 'none' }}
              onClick={handleShowEditChatUserDetails}>
              Editar
            </Button>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!editChatUserDetails && <Grid container spacing={0}>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={1} sm={1} md={1} lg={1} xl={1} >
                <PersonIcon />
              </Grid>
              <Grid className={`${classes.gridItemSubtitleColor}`} sx={{ paddingLeft: '5px' }} item xs={10} sm={10} md={10} lg={10} xl={10} >
                {chats.chat.customer.name ? chats.chat.customer.name : ''}
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={1} sm={1} md={1} lg={1} xl={1} >
                <PhoneIcon />
              </Grid>
              <Grid className={`${classes.gridItemSubtitleColor}`} sx={{ paddingLeft: '5px' }} item xs={10} sm={10} md={10} lg={10} xl={10} >
                {chats.chat.customer.phone ? chats.chat.customer.phone : ''}
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={1} sm={1} md={1} lg={1} xl={1} >
                <EmailIcon />
              </Grid>
              <Grid className={`${classes.gridItemSubtitleColor}`} sx={{ paddingLeft: '5px' }} item xs={10} sm={10} md={10} lg={10} xl={10} >
                {chats.chat.customer.email ? chats.chat.customer.email : ''}
              </Grid>
            </Grid>}
            {editChatUserDetails && <form onSubmit={chatUserDetailsHandleSubmit(onSubmitEditChatUserDetails)}><Grid container spacing={2} style={{ padding: '5%' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Controller
                  name="name"
                  control={chatUserDetailsControl}
                  variant='outlined'
                  render={({ field }) => <TextField fullWidth required label={`${intl.formatMessage({ id: "name" })}`} {...field} />} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Controller
                  name="email"
                  control={chatUserDetailsControl}
                  variant='outlined'
                  
                  render={({ field }) => <TextField disabled={props.chats.chat.messageproviderid == 6 ? true : false} fullWidth required={props.chats.chat.messageproviderid == 6 ? true : false} label={`${intl.formatMessage({ id: "email" })}`} {...field} />} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Controller
                  name="phone"
                  control={chatUserDetailsControl}
                  variant='outlined'

                  render={({ field }) => <TextField disabled={props.chats.chat.messageproviderid == 2 ? true : false} fullWidth required={props.chats.chat.messageproviderid == 2 ? true : false} label={`${intl.formatMessage({ id: "phone" })}`} {...field} />} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={4} />
                  <Grid item xs={12} sm={12} md={6} lg={5} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button variant="outlined"
                      size="small"
                      style={{ textTransform: 'none' }}
                      onClick={handleShowEditChatUserDetails}>
                      <FormattedMessage id="cancel" />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={5} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button variant="contained"
                      type="submit"
                      size="small"
                      style={{ textTransform: 'none' }}>
                      <FormattedMessage id="save" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid></form>}
          </Grid>
        </Grid>
        <Divider className={`${isOpen ? classes.gridOpen : classes.gridClose}`} />
        <Grid className={`omnichannel-chat-user-details-sidebar-chat ${isOpen ? classes.gridOpen : classes.gridClose}`} sx={{ padding: '3%' }} container spacing={0}>
          <Grid className='omnichannel-chat-user-details-sidebar-header' item xs={12} sm={8} md={9} lg={9} xl={9} >
            Chat
          </Grid>
          <Grid className={`${classes.gridItemSubtitleColor}`} item xs={12} sm={4} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'flex-end' }} >
            {!editChatDetails && <Button variant="outlined"
              size="small"
              style={{ textTransform: 'none' }}
              onClick={handleShowEditChatDetails}>
              Editar
            </Button>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!editChatDetails && <Grid container spacing={0}>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={11} sm={11} md={11} lg={11} xl={11} >Título da conversa:</Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={10} sm={10} md={10} lg={10} xl={10} >{chats.chat.title ? chats.chat.title : ''}</Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={11} sm={11} md={11} lg={11} xl={11} >Responsável pelo atendimento:</Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={10} sm={10} md={10} lg={10} xl={10} >{chats.chat.assignedusername ? chats.chat.assignedusername : ''}</Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={11} sm={11} md={11} lg={11} xl={11} >Prioridade:</Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={10} sm={10} md={10} lg={10} xl={10} >{(chats.chat.priority && chats.chat.priority.name) ? chats.chat.priority.name : ''}</Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={11} sm={11} md={11} lg={11} xl={11} >Categoria:</Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={10} sm={10} md={10} lg={10} xl={10} >{(chats.chat.organizationcategory ) ? chats.chat.organizationcategory.parentname ? chats.chat.organizationcategory.parentname  : chats.chat.organizationcategory.name   : ''}</Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={11} sm={11} md={11} lg={11} xl={11} >Departamento:</Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={10} sm={10} md={10} lg={10} xl={10} >{(chats.chat.organizationdepartament && chats.chat.organizationdepartament.name) ? chats.chat.organizationdepartament.name : ''}</Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={11} sm={11} md={11} lg={11} xl={11} >Canal:</Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={10} sm={10} md={10} lg={10} xl={10} >{(chats.chat.channelid && chats.chat.channelname) ? chats.chat.channelname : ''}</Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={11} sm={11} md={11} lg={11} xl={11} >{chats.chat.messageproviderid == 6 && chats.chat.webchatid ? 'Webchat' : chats.chat.messageproviderid == 2 && chats.chat.registrationnumberrequestid ? 'Registro de telefone' : null }:</Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid className={`${classes.gridItemSubtitleColor}`} item xs={10} sm={10} md={10} lg={10} xl={10} >{chats.chat.messageproviderid == 6 && chats.chat.webchatid ? chats.chat.webchatid : chats.chat.messageproviderid == 2 && chats.chat.registrationnumberrequestid ?  chats.chat.registrationnumberrequestid : null }</Grid>
            
            </Grid>}
            {editChatDetails && <form onSubmit={chatDetailsHandleSubmit(onSubmitEditChatDetails)}>
              <Grid container spacing={2} style={{ padding: '5%' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Controller
                    name="title"
                    control={chatDetailsControl}
                    variant='outlined'
                    render={({ field }) => <TextField fullWidth  label={`${intl.formatMessage({ id: "title" })}`} {...field} />} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Controller
                    name="organization"
                    control={chatDetailsControl}
                    stateName="organization"
                    render={({ field }) => (
                      <SimpleAutoComplete
                        {...field}
                        label={intl.formatMessage({ id: "organization" })}
                        options={chatDetailsOrganizationList}
                        selected={field.value}
                        
                        
                        changeSelect={changeValues}
                        stateName="organization"
                      />
                    )} />
                    </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Controller
                      name="address"
                      control={chatDetailsControl}
                      stateName="address"
                      render={({ field }) => (
                        <SimpleAutoComplete
                          {...field}
                          label={intl.formatMessage({ id: "location" })}
                          options={chatDetailsAddressList}
                          selected={field.value}        
                          changeSelect={changeValues}
                          stateName="address"
                        />
                      )} />
                  </Grid>
                {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Controller
                    name="assigneduser"
                    control={chatDetailsControl}
                    stateName="assigneduser"
                    render={({ field }) => (
                      <SimpleAutoComplete
                        {...field}
                        label={intl.formatMessage({ id: "attr.to" })}
                        options={chatDetailsAssignedUserList}
                        selected={field.value}
                        isRequired
                        required
                      />
                    )} />
                    </Grid>*/}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Controller
                    name="priority"
                    control={chatDetailsControl}
                    stateName="priority"
                    render={({ field }) => (
                      <SimpleAutoComplete
                        {...field}
                        label={intl.formatMessage({ id: "priority" })}
                        options={chatDetailsPriorityList}
                        selected={field.value}
                        //changeSelect={changeValues}
                        
                        
                      />
                    )} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Controller
                    name="category"
                    control={chatDetailsControl}
                    render={({ field }) => (
                      <SimpleAutoComplete
                        {...field}
                        stateName="category"
                        label={intl.formatMessage({ id: "category" })}
                        options={chatDetailsCategoryList}
                        selected={field.value}
                        
                        
                      />
                    )} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Controller
                    name="departament"
                    control={chatDetailsControl}
                    render={({ field }) => (
                      <SimpleAutoComplete
                        {...field}
                        stateName="departament"
                        label={intl.formatMessage({ id: "departament" })}
                        options={chatDetailsDepartamentList}
                        selected={field.value}
                        
                        
                      />
                    )} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={4} />
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                      <Button variant="outlined"
                        size="small"
                        style={{ textTransform: 'none' }}
                        onClick={handleShowEditChatDetails}>
                        <FormattedMessage id="cancel" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                      <Button variant="contained"
                        type="submit"
                        style={{ textTransform: 'none' }}
                        size="small">
                        <FormattedMessage id="save" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>}
          </Grid>
        </Grid>
        {/*<Divider className={`${isOpen ? classes.gridOpen : classes.gridClose}`} />
        <Grid className={`omnichannel-chat-user-details-sidebar-labels ${isOpen ? classes.gridOpen : classes.gridClose}`} sx={{ padding: '3%' }} container spacing={0}>
          <Grid className='omnichannel-chat-user-details-sidebar-header' item xs={6} sm={7} md={8} lg={8} xl={8} >
            Rótulos
          </Grid>
          <Grid className={`${classes.gridItemSubtitleColor}`} item xs={6} sm={5} md={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >
            <Button variant="outlined"
              style={{ textTransform: 'none' }}
              size="small" >
              Gerenciar
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }} >
            <Button variant="contained"
              size="small"
              style={{ backgroundColor: '#F2F4F7', textTransform: 'none', color: '#000' }}
              startIcon={<AddCircleIcon style={{ color: '#0088FF', fill: '#0088FF' }} />} >
              Adicionar rótulo
            </Button>
          </Grid>
          <Grid className={`${classes.gridItemSubtitleColor}`} item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }} >
            Sugestões de rótulos
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }} >
            <ChatLabel label="Novo cliente"
              checked={false}
              variant="success" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }} >
            <ChatLabel label="Data de hoje (11/24)"
              checked={false}
              variant="info" />
          </Grid>
                    </Grid>*/}
        <Divider className={`${isOpen ? classes.gridOpen : classes.gridClose}`} />
        <Grid className={`omnichannel-chat-user-details-sidebar-notes ${isOpen ? classes.gridOpen : classes.gridClose}`} sx={{ padding: '3%' }} container spacing={0}>
          <Grid className='omnichannel-chat-user-details-sidebar-header' item xs={12} sm={5} md={6} lg={6} xl={6} >
            Notas
          </Grid>
          <Grid className={`${classes.gridItemSubtitleColor}`} item xs={12} sm={7} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }} >
            <Button variant="outlined"
              onClick={() => props.openOrCloseDialogAddNoteModal()}
              style={{ textTransform: 'none' }}
              size="small" >
              Adicionar nota
            </Button>
          </Grid>
          {props.chats && props.chats.chat && props.chats.chat.chatnotes && props.chats.chat.chatnotes.length > 0 ? props.chats.chat.chatnotes.map((entry, index) => (

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5 }} >
                <ChatNote content={entry.description} attachments = {entry.chatnotesattachments} setLoading={props.setLoading} setNotificationMessage={props.setNotificationMessage} setNotificationVariant={props.setNotificationVariant} setOpenNotification={props.setOpenNotification} intl= {intl} />
            </Grid>

          ))
            : null
          }

        </Grid>
      </div>
    )
  } else {
    return (<></>)
  }
}

const mapStateToProps = state => ({
  chats: state.chats
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChatUserDetailsSidebar))
