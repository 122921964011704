import { Accordion, AccordionDetails, AccordionSummary, Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import TitleName from "src/components/title-name";
import { Styles } from "src/styles/material-styles";
import PropTypes from "prop-types"
import { PapiroConsole } from "src/utils/papiroConsole";
import Api from "src/services/api";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Add, Delete, ExpandMore } from "@mui/icons-material";
import DialogConfirmation from '../../../components/dialog-confirmation'
import DialogAddMultiple from '../../../components/dialog-add-multiple'
import SimpleCheckBox from "src/components/checkbox/check";

function CreateCalledStep(props) {

    const { classes, intl, backButton, nextButton, clientPortal, handleNextBarProgress, handleComplete, setLoading, setNotification } = props

    const NoDataRows = () => {
        return (
            <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({ id: "rows.show" })}</div>
        )
    }

    const [item, setItem] = useState({
        id: clientPortal.id,
        enablecreatecalled: clientPortal.enablecreatecalled,
        createcalledpagetitle: clientPortal.createcalledpagetitle,
        createcalledpagetext: clientPortal.createcalledpagetext,
        calledservicesettingslist: clientPortal.calledservicesettingslist ? clientPortal.calledservicesettingslist : [],
    })

    const [options, setOptions] = useState([])
    const [optionsForSelect, setOptionsForSelect] = useState(clientPortal.calledservicesettingslist)
    const [optionsForDelete, setOptionsForDelete] = useState([])
    const [optionsSelected, setOptionsSelected] = useState(false)
    const [selectModalOpen, setSelectModalOpen] = useState(false)

    useEffect(() => {
        getOptions()
        setItem({
            id: clientPortal.id,
            enablecreatecalled: clientPortal.enablecreatecalled,
            createcalledpagetitle: clientPortal.createcalledpagetitle,
            createcalledpagetext: clientPortal.createcalledpagetext,
            calledservicesettingslist: clientPortal.calledservicesettingslist ? clientPortal.calledservicesettingslist : [],
        })
        setOptionsForSelect(clientPortal.calledservicesettingslist)
    }, [clientPortal])

    const getOptions = async () => {
        setLoading(true)

        await Api.get(`calledservicesettings/allbyhirer/${clientPortal.hirerid}`)
            .then(result => {
                if (result.data.success) {
                    setLoading(false)
                    setOptions(result.data.data.map(item => { return ({ id: item.id, name: item.title }) }))
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
    }

    const deleteOptionsForDelete = async () => {

        if (optionsForDelete.length > 0) {

            setLoading(true)

            let data = new FormData()

            var calledservicesettingsid = optionsForSelect.map(i => {
                return i.id + ","
            })

            data.append("calledservicesettingsid", calledservicesettingsid)
            data.append("clientportalid", item.id)

            await Api.post("calledservicesettingsclientportal/delete", data)
                .then(result => {
                    if (result.data.success) {
                        var response = [];
                        result.data.data.map(r => {
                            response.push(options.find(o => o.id == r.id))
                        })
                        setItem({ ...item, calledservicesettingslist: response })
                        setLoading(false)
                        setNotification(true, 'success', intl.formatMessage({ id: "add.success" }))
                    }
                    else {
                        setLoading(false)
                        setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                        Api.kickoff(result)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                })
        } else {
            setNotification(true, 'error', intl.formatMessage({ id: "select.called.service.settings" }))
        }
    }

    const saveOptions = async () => {
        setLoading(true)

        let data = new FormData()

        var calledservicesettingsid = optionsForSelect.map(i => {
            return i.id + ","
        })

        data.append("calledservicesettingsid", calledservicesettingsid)
        data.append("clientportalid", item.id)

        await Api.post("calledservicesettingsclientportal/multiple", data)
            .then(result => {
                if (result.data.success) {
                    var response = [];
                    result.data.data.map(r => {
                        response.push(options.find(o => o.id == r.id))
                    })
                    setItem({ ...item, calledservicesettingslist: response })
                    setLoading(false)
                    setNotification(true, 'success', intl.formatMessage({ id: "add.success" }))
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
    }

    const changeValues = (stateName, value, text = '') => {
        PapiroConsole.log("changeValues")

        if (stateName === 'optionsForSelect') {
            setOptionsForSelect(value)
        }
        else if (stateName === 'optionsSelected') {
            if (value == true) {
                PapiroConsole.log("value == true")
                setOptionsForSelect(options)
            } else {
                PapiroConsole.log("value == false")
                setOptionsForSelect(item.calledservicesettingslist)
            }
        }
    }

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setItem({
            ...item,
            [name]: value
        })
    }

    const handleChangeSelect = (stateName, value) => {
        setItem({
            ...item,
            [stateName]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let data = new FormData()
        data.append('id', clientPortal.id)
        data.append('enablecreatecalled', item.enablecreatecalled)
        data.append('createcalledpagetitle', item.createcalledpagetitle)
        data.append('createcalledpagetext', item.createcalledpagetext)

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        await Api.put("/clientportal/createcalled", data, config)
            .then(result => {
                if (result.data.success) {
                    setLoading(false)
                    handleNextBarProgress()
                    handleComplete()
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
    }

    return (
        <div className={classes.root}>
            <Container>
                <CssBaseline />
                <div className={classes.paper} style={{ marginTop: 0 }}>
                    <Typography component='h1' variant='h5'>
                        {<FormattedMessage id="called.creation" />}
                        <TitleName />
                    </Typography>
                    <form name='myForm' className={classes.form} onSubmit={handleSubmit} encType='multipart/form-data'>
                        <Grid container spacing={2}>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "enable.called.creation" })} name='enablecreatecalled' stateName='enablecreatecalled' changeSelect={handleChangeSelect} selected={item.enablecreatecalled} />
                            </Grid>
                            <Grid item xs={12} sm={12} disabled={!item.enablecreatecalled}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>{intl.formatMessage({ id: "menu.called.service.settings" })}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container item xs={12} sm={12} >
                                            <Grid item xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                                                <Grid item xs={12} sm={4} >
                                                </Grid>
                                                <Grid item xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                    <DialogConfirmation
                                                        msgButton={"Remover formulário"}
                                                        msg={"Você tem certeza que desejar remover este formulário deste portal?"}
                                                        msgDisagree={intl.formatMessage({ id: "cancel" })}
                                                        msgAgree={intl.formatMessage({ id: "yes" })}
                                                        handleConfirmation={e => deleteOptionsForDelete()}
                                                        classes={classes}
                                                        icon={<Delete />}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.button}
                                                        startIcon={<Add />}
                                                        onClick={e => setSelectModalOpen(true)} >
                                                        {intl.formatMessage({ id: "add" })}
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12}
                                                style={{
                                                    border: '3px solid #eee'
                                                }} >
                                                <div style={{ height: 350 }}>
                                                    <DataGrid
                                                        style={{ fontSize: 14, color: '#000' }}
                                                        className="DataGridStyle"
                                                        components={{
                                                            NoRowsOverlay: NoDataRows,
                                                            Toolbar: GridToolbarFilterButton,
                                                        }}
                                                        rows={item.calledservicesettingslist}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                        onSelectionModelChange={itm => {
                                                            if (itm.length > 0) {
                                                                setOptionsForDelete([...optionsForDelete, itm])
                                                            } else {
                                                                setOptionsForDelete([])
                                                            }
                                                        }}
                                                        columns={[
                                                            { headerName: intl.formatMessage({ id: "title" }), field: 'name', width: 500 },
                                                        ]}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "create.called.page.title" })} name="createcalledpagetitle" value={item.createcalledpagetitle} onChange={handleChange} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "create.called.page.text" })} name="createcalledpagetext" value={item.createcalledpagetext} onChange={handleChange} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginBottom: '60px', display: 'flex', marginTop: '20px' }}>
                            <Grid item xs={12} sm={2} >
                                {backButton}
                            </Grid>
                            <Grid item xs={12} sm={2} >
                                {nextButton}
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginLeft: 'auto' }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                >
                                    {<FormattedMessage id="save.next" />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <DialogAddMultiple
                    title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
                    selected={optionsForSelect}
                    options={options}
                    checked={optionsSelected}
                    changeValues={changeValues}
                    open={selectModalOpen}
                    close={_ => setSelectModalOpen(false)}
                    save={saveOptions}
                    intl={intl}
                    checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
                    checkboxStateName='optionsSelected'
                    autoCompleteLabel={intl.formatMessage({ id: "organization" })}
                    autoCompleteStateName="optionsForSelect"
                />
            </Container>
        </div>
    )
}

CreateCalledStep.propTypes = {
    classes: PropTypes.object.isRequired,
    back: PropTypes.func,
    next: PropTypes.func
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,

});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CreateCalledStep)));