import React from 'react';
import { Box } from '@mui/material';

// Obter cor baseada no status do ticket
export const getStatusColor = (status) => {
  switch(status) {
    case 'Novo':
      return '#3b82f6'; // blue-500
    case 'Em andamento':
      return '#f59e0b'; // amber-500
    case 'Finalizado':
      return '#10b981'; // green-500
    default:
      return '#6b7280'; // gray-500
  }
};

// Obter ícone baseado no status
export const getStatusIcon = (status) => {
  const color = getStatusColor(status);
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: color,
        display: 'inline-block',
        mr: 1
      }}
    />
  );
};

// Obter cor baseada na prioridade do ticket
export const getPriorityColor = (priority) => {
  switch(priority) {
    case 'Alta':
      return 'error';
    case 'Média':
      return 'warning';
    case 'Baixa':
      return 'success';
    default:
      return 'default';
  }
};

// Formatar data para exibição
export const formatDateForDisplay = (date) => {
  if (typeof date === 'string') {
    return date;
  }
  
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  
  return `${day}/${month}/${year}`;
};

// Função para formatar a data no formato DD/MM/YYYY
export const formatDate = (day, month, year) => {
  const d = day < 10 ? '0' + day : day;
  const m = (month + 1) < 10 ? '0' + (month + 1) : (month + 1);
  return `${d}/${m}/${year}`;
};

// Formatar data para submissão
export const formatDateForSubmission = (date) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  const hours = d.getHours().toString().padStart(2, '0');
  const minutes = d.getMinutes().toString().padStart(2, '0');
  
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

// Verificar se é o dia atual
export const isToday = (day, month, year) => {
  const today = new Date();
  return day === today.getDate() && 
         month === today.getMonth() && 
         year === today.getFullYear();
};

// Calcular dias no mês
export const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

// Calcular o primeiro dia da semana do mês
export const getFirstDayOfMonth = (year, month) => {
  return new Date(year, month, 1).getDay();
};

// Nomes dos meses em português
export const monthNames = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

// Nomes dos dias da semana em português
export const weekdayNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];