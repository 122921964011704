import { Api } from "@mui/icons-material";
import { PapiroConsole } from "src/utils/papiroConsole";


export function addUserSession(user) {
    return {
        type: 'ADD_USER',
        user,
    }
}

export function removeUserSession() {
    
    const userId = localStorage.getItem("userid");
    const token = localStorage.getItem("accesstoken");
    const baseUrl = localStorage.getItem("urlapi");

    const request = new XMLHttpRequest();
    request.open("GET",`${baseUrl}/login-history/session-expired/${userId}`);
    request.setRequestHeader("Authorization",`Bearer ${token}`);
    request.send();

    localStorage.removeItem('accesstoken');
    localStorage.removeItem('accesstokenreports');
    localStorage.removeItem('accesstoken2talk');
    
  return {
        type: 'REMOVE_USER'
    }
}

export function updateAlertAlreadyDisplayed(alertAlreadyDisplayed) {
    
    return {
        type: 'UPDATE_ALERT_ALREADY_DISPLAYED',
        alertAlreadyDisplayed,
    }
}