/* global FB */
import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Avatar, Typography, TextField, Paper } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/rulesattendance';
import Api2Talk from "../../services/api2talk";
import Api from '../../services/api';
import * as ServiceOrganization from '../../services/organization.service';
import * as RegistratioPhoneNumberRequestService from '../../services/2talk//registrationphonenumber';
import * as ServicePhoneRegistrationRequests from '../../services/2talk/phoneregistrationrequests';
import * as ServiceCompany from '../../services/2talk/company';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import ColorlibStepIcon from '../../components/colorlib-step-icon';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import PhoneInput from 'react-phone-input-2'
import { PapiroConsole } from '../../utils/papiroConsole';
import Dropzone from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StepButton from '@mui/material/StepButton';
import DialogGeneral from '../../components/dialog-general';
import CheckIcon from '@mui/icons-material/Check';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, #3f51b4 0%, #3949ab 50%, #303f9f 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, #3f51b4 0%, #3949ab 50%, #303f9f 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },

}));
class PhoneRegistrationRequestsRegistration extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;

        //estado inicial da empresa
        this.initialcompanyRegistrationState = {
            companyNameName: '',
            companyAddress: '',
            companyWebsite: '',
            companyLogo: '',
            companyMainActivity: '',
            companyAccountId: '',
            companyPhone: '',
        }

        //estado inicial solicitação telefone
        this.initialItemState = {
            organizationid: null,
            phoneNumber: "",
            messageproviderid: null,
            cnpj: ""
        }

        this.initialMetaAuthentication = {
            accountName: "",
            smsCode: "",
            messageProviderConfigAccountsid: "",
            accountId: "",
            phoneNumberId: "",
            templateNamespace : ""

        }

        this.state = {
            urlRegisterMetaAccount: 'https://mexx.ai/login-meta-2talk.html',
            companyRegistration: this.initialcompanyRegistrationState,
            item: this.initialItemState,
            metaAuthentication: this.initialMetaAuthentication,
            itemError: {
                cnpj: '',
            },
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            organizationList: [],
            techinicalTeamList: [],
            usersList: [],
            files: [],
            conditionOptions: [{ id: 1, name: 'OU' }, { id: 2, name: 'E' }],
            messageProviderList: [{ id: 1000, name: 'Twilio' }],

            company: null,
            hasCompany: false,
            activeStep: 0,
            DialogRemoveState: false,
            verifiedState: false,



        };




        this.closeNotification = this.closeNotification.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeCompanyRegistration = this.handleInputChangeCompanyRegistration.bind(this);
        this.handleInputChangeMetaAuthentication = this.handleInputChangeMetaAuthentication.bind(this);
        this.launchWhatsAppSignup = this.launchWhatsAppSignup.bind(this);

    }







    componentDidMount() {

        if (Api != null && (Api.getSassHirerId() == 1 || Api.getSassHirerId() == 15) || (document.location.host.indexOf("localhost") > -1) || (document.location.host.indexOf("192.168.") > -1) || (document.location.host.indexOf("homologa.") > -1)) {
            this.setState({
                urlRegisterMetaAccount: 'https://mexx.ai/login-meta-2talk-homologa.html',
            }); 
        }

        this.getOrganization();
        this.getMessageProviders()
    }

    handleStep(step) {
        PapiroConsole.log(this.state.activeStep)
        PapiroConsole.log(step)
        if (this.state.activeStep > step) {
            this.setState({
                activeStep: step,
            });
        }

    };



    metaRequest = async e => {



        this.setState({ loading: true })

        var metaValidation = this.metaValidations()

        var data = this.state.metaAuthentication;
        data.MessageProviderId = this.state.item.messageproviderid ? this.state.item.messageproviderid.id : 0

        if (metaValidation) {
            var result = await RegistratioPhoneNumberRequestService.metaRquest(this.state.metaAuthentication);

            PapiroConsole.log("result aqui 123")
            PapiroConsole.log(result)
            if (result && result.data && result.success) {

                this.setState(prevState => ({
                    metaAuthentication: {
                        ...prevState.metaAuthentication,
                        accountId: result.data.accountid,
                        phoneNumberId: result.data.phonenumberid,
                        templateNamespace : result.data.templatenamespace
                    },
                }));

                return true;


            } else {
                PapiroConsole.log("result error")
                PapiroConsole.log(result)
                const intl = this.props.intl
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }),
                })
                Api.kickoff(result)
            }
        }


        return false

    }

    metaDataVerification = async e => {



        this.setState({ loading: true })

        var metaDataVerificationValidations = this.metaDataVerificationValidations()


        if (metaDataVerificationValidations) {
            let data = {
                accountName: this.state.metaAuthentication.accountName,
                phoneNumber: this.state.item.phoneNumber
            }
            var result = await RegistratioPhoneNumberRequestService.metaDataVerification(data);

            PapiroConsole.log("result aqui 123")
            PapiroConsole.log(result)
            if (result && result.data && result.success) {

                if (result && result.data && result.data[0]) {

                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: result.data[0]
                    })

                }

                this.setState({ verifiedState: true, loading: false })

                return true;


            } else {
                PapiroConsole.log("result error")
                PapiroConsole.log(result)
                const intl = this.props.intl
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result && result.errors ? result.errors[0] : intl.formatMessage({ id: "process.error" }),
                })
                Api.kickoff(result)
            }
        }


        return false

    }



    handleNext = async () => {
        this.setState({ loading: true });
        PapiroConsole.log("this.state.activeStep")
        PapiroConsole.log(this.state.activeStep)

        if (this.state.activeStep == 0) {
            if (this.state.item['organizationid'] == null || this.state.item['organizationid']?.id == 0) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário selecionar a organização"
                });
                return;
            }

            if (this.state.item['messageproviderid'] == null || this.state.item['messageproviderid']?.id == 0) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário selecionar o provedor"
                });
                return;
            }


            if (this.state.item['phoneNumber'] == null || this.state.item['phoneNumber'] == "") {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário um número de telefone válido"
                });
                return;
            }
            if (this.state.item['messageproviderid'] != null && this.state.item['messageproviderid']?.id == 2) {

                var endpointValidation = await this.metaRequest()
                if (!endpointValidation) {
                    return;
                }
                // var metaValidation = this.metaValidations()

                // if (metaValidation) {
                //    var endpointValidation = await this.metaRequest()
                //    if(!endpointValidation){
                //         return;
                //    }
                // }
                // else{
                //     return;
                // }

            }



        }


        else if (this.state.activeStep == 1) {
            PapiroConsole.log("entrei aqui")
            if (!this.state.item['cnpj'] || (this.state.item['cnpj'] && this.isCNPJValid(this.state.item.cnpj) == false)) {
                PapiroConsole.log("entrei aqui 2")
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "O campo CPF/CNPJ não foi validado"
                });
                return;
            }

            var result = await ServiceCompany.getCompanyByCnpj(this.state.item['cnpj']);
            PapiroConsole.log(result)
            PapiroConsole.log("result")
            if (result.success) {
                if (result.data) {
                    this.setState({ company: result.data, hasCompany: true });
                }
                else {
                    this.setState({ company: null, hasCompany: false });
                }

            } else {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(result);
            }
        }







        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
            loading: false
        }));


    };


    metaValidations() {

        var returnStatus = true;
        const intl = this.props.intl;
        var data = this.state.metaAuthentication
        PapiroConsole.log(this.state.metaAuthentication) 
        PapiroConsole.log("this.state.metaAuthentication") 

        if (this.state.verifiedState == false) {
            returnStatus = false;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "É necessária a verificação da conta antes de prosseguir para o próximo passo'",
            })
        }
        else {
            Object.keys(data).forEach(element => {
                PapiroConsole.log("entrei aqui")
                PapiroConsole.log(element)
                PapiroConsole.log(data[element])
                if (element == 'accountName' && data[element] === null ) {
                    PapiroConsole.log("entrei aqui 2")

                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: "É necessário o preenchimento do campo 'Nome da conta'",
                    })

                    returnStatus = false;

                }
                else if (element == 'smsCode' && data[element] === null ) {

                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: "É necessário o preenchimento do campo 'Informe aqui o códigode sms recebido'",
                    })

                    returnStatus = false;

                }

            });
        }




        return returnStatus;


    }

    metaDataVerificationValidations() {

        var returnStatus = true;

        if (this.state.metaAuthentication.accountName == null || this.state.metaAuthentication.accountName == "") {

            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "É necessário o preenchimento do campo 'Nome da conta' para fazer a verificação",
            })
            returnStatus = false;

        }
        else if (this.state.item.phoneNumber == null || this.state.item.phoneNumber == "") {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "É necessário o preenchimento do campo 'Número de telefone origem da mensageria' para fazer a verificação",
            })

            returnStatus = false;
        }




        return returnStatus;


    }

    handleBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    getOrganization = async () => {
        var result = await ServiceOrganization.getOrganizations();
        if (result.success) {
            this.setState({ loading: false, organizationList: result.data });
        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }

    getMessageProviders = async () => {

        var result = await ServicePhoneRegistrationRequests.getAllWithAccountSid();
        PapiroConsole.log("result de provedor")
        PapiroConsole.log(result)
        if (result.success) {
            this.setState(prevState => ({
                metaAuthentication: {
                    ...prevState.metaAuthentication,
                    messageProviderConfigAccountsid: result.data.messageproviderconfigaccountsid,


                },
                loading: false, messageProviderList: result.data.messageproviderlist
            }));

            // if (result.data.messageproviderconfigaccountsid) {
            //     this.initializeFacebookSDK(result.data.messageproviderconfigaccountsid);
            // }

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }


    initializeFacebookSDK = (messageproviderconfigaccountsid) => {

        PapiroConsole.log("messageproviderconfigaccountsid")
        PapiroConsole.log(messageproviderconfigaccountsid)
        // Define a função que será chamada quando o SDK do Facebook for carregado
        window.fbAsyncInit = () => {
            FB.init({
                appId: messageproviderconfigaccountsid,
                cookie: true, // enable cookies
                autoLogAppEvents: true,
                xfbml: true,
                version: "v20.0",
            });


        };

        // Carrega o SDK do Facebook
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk")
    }

    launchWhatsAppSignup() {
        // Launch Facebook login
        FB.login(
            function (response) {
                if (response.authResponse) {
                    const accessToken = response.authResponse.accessToken;
                    console.log(accessToken);
                    //Use this token to call the debug_token API and get the shared WABA's ID
                    fetch(
                        `https://graph.facebook.com/v20.0/debug_token?input_token=${accessToken}&access_token=EAARzkHctTewBABQoSZA3gDI36fFBO1ZCW1DV4uW3eUMZClzbZBB8wvmpFNhrShuZA2ej4oZAxxXD0md0vZAkr4NQ6ZBzOzNsK9QMjz0bz3xEzSKQ0BDQU9qD2DoZA3XwGDGyAyAssqgEkGM9iUSmj6CspwPUWwIVuByJdOxtKV4dSNG1Fm44hTWgQ`
                    )
                        .then((response) => response.json())
                        .then((data) => console.log(data));
                } else {
                    console.log("User cancelled login or did not fully authorize.");
                }
            },
            {
                //scope: "business_management,whatsapp_business_management",
                scope: "email,whatsapp_business_messaging,public_profile,business_management,whatsapp_business_management",
                extras: {
                    feature: "whatsapp_embedded_signup",
                    setup: {
                        // ... // Prefilled data can go here
                    },
                },
            }
        );
    }


    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues = (stateName, value) => {
        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));
        console.log(value)
        console.log('changeValues')
    };

    phoneRegistrationRequestValidation() {
        if (Api2Talk == null) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "Api de mensageria não configurada."
            });
            return false;
        }
        if (this.state.item['organizationid'] == null || this.state.item['organizationid']?.id == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "É necessário selecionar a organização"
            });
            return false;
        }

        if (this.state.item['messageproviderid'] == null || this.state.item['messageproviderid']?.id == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "É necessário selecionar o provedor"
            });
            return false;
        }

        if (this.state.item['phoneNumber'] == null || this.state.item['phoneNumber'] == "") {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "É necessário um número de telefone válido"
            });
            return false;
        }

        if (!this.state.item['cnpj'] && this.isCNPJValid(this.state.item.cnpj) == false) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "O campo CPF/CNPJ não foi validado"
            });
            return false;
        }

        if (this.state.hasCompany == false) {

            if (this.state.companyRegistration['companyName'] == null || this.state.companyRegistration['companyName'] == "") {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário preencher o campo 'Nome da empresa'"
                });
                return false;
            }
            if (this.state.companyRegistration['companyAccountId'] == null || this.state.companyRegistration['companyAccountId'] == "") {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário preencher o campo 'Número de Identificação do Gerenciador de Negócios'"

                });
                return false;
            }
            if (this.state.companyRegistration['companyMainActivity'] == null || this.state.companyRegistration['companyMainActivity'] == "") {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário preencher o campo 'Atividade principal da empresa'"
                });
                return false;
            }
            if (this.state.companyRegistration['companyAddress'] == null || this.state.companyRegistration['companyAddress'] == "") {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário preencher o campo 'Endereço'"
                });
                return false;
            }
            if (this.state.companyRegistration['companyPhone'] == null || this.state.companyRegistration['companyPhone'] == "") {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário preencher o campo 'Telefone'"
                });
                return false;
            }
            if (this.state.files.length <= 0 || this.state.files.length > 1) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: "É necessário adicionar um anexo para sua Logo"
                });

                return false;
            }

        }

        return true;
    }

    handleSubmit = async (e) => {
        if (e != null) {
            e.preventDefault();
        }
        let data = new FormData();
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        this.setState({ loading: true });
        const intl = this.props.intl;

        // validações do endpoint
        let phoneRegistrationRequestValidation = this.phoneRegistrationRequestValidation();
        if (phoneRegistrationRequestValidation) {
            for (let key in this.state.item) {
                if (key.toString().toLowerCase().indexOf('organizationid') > -1) {
                    data.append(key, this.state.item[key] ? this.state.item[key].id : null);
                    data.append("organizationname", this.state.item['organizationid'] ? this.state.item['organizationid'].name : null);
                }

                else if (key.toString().toLowerCase().indexOf('messageproviderid') > -1) {
                    data.append(key, this.state.item[key] ? this.state.item[key].id : null);
                }
                else {
                    data.append(key, this.state.item[key])
                }
            }
            if (this.state.hasCompany == false) {
                for (let key in this.state.companyRegistration) {
                    data.append(key, this.state.companyRegistration[key])
                }
                this.state.files.forEach(file => data.append('FilesUpload', file));

            } else {
                data.append("CompanyId", this.state.company ? this.state.company.id : null)
            }
            data.append("HasCompany", this.state.hasCompany)

            PapiroConsole.log("states aqui")
            PapiroConsole.log(this.state.item.messageproviderid)
            PapiroConsole.log(this.state.metaAuthentication.accountId)
            PapiroConsole.log(this.state.metaAuthentication.phoneNumberId)

            if (this.state.item.messageproviderid.id == 2) {
                data.append("AccountId", this.state.metaAuthentication.accountId)
                data.append("PhoneNumberId", this.state.metaAuthentication.phoneNumberId)
            }

            Api2Talk.post('/registrationphonenumber', data, config)
                .then(result => {
                    if (result.data.success) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({ id: "save.success" }),
                            item: this.initialItemState,
                            companyRegistration: this.initialcompanyRegistrationState,
                            activeStep: 0,
                            files: []

                        });
                        this.openOrCloseRemoveModal()
                        this.componentDidMount();
                    } else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                        Api.kickoff(result);
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(err);
                });
        }


        //dados para registro na tabela RegistrationNumberRequest

    }

    handleChangePhone = (e) => {

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                ['phoneNumber']: e
            }
        }));
    }
    handleChangePhoneCompany = (e) => {

        this.setState(prevState => ({
            companyRegistration: {
                ...prevState.companyRegistration,
                ['companyPhone']: e
            }
        }));
    }
    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'cnpj') {

            var valor = 0;

            valor = this.CNPJMask(value)

            valor.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, cnpj: '' } })) : ((value.length !== 14 && value.length !== 18) || !this.isCNPJValid(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, cnpj: 'CPF/CNPJ inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, cnpj: '' } }))
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                    [name]: valor
                }
            }))
        }

    }

    handleInputChangeCompanyRegistration(e) {

        const name = e.target.name;
        const value = e.target.value;



        this.setState(prevState => ({
            companyRegistration: {
                ...prevState.companyRegistration,
                [name]: value
            }
        }))


    }


    handleInputChangeMetaAuthentication(e) {

        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            metaAuthentication: {
                ...prevState.metaAuthentication,
                [name]: value
            }
        }))





    }

    isCNPJValid(value) {
        // Remove caracteres não numéricos
        const numericValue = value.replace(/\D/g, '');

        if (numericValue.length === 14) {
            // Validação CNPJ
            let tamanho = numericValue.length - 2;
            let numeros = numericValue.substring(0, tamanho);
            const digitos = numericValue.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;

            for (let i = tamanho; i >= 1; i--) {
                soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }

            let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

            if (resultado !== parseInt(digitos.charAt(0))) {
                return false;
            }

            tamanho = tamanho + 1;
            numeros = numericValue.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;

            for (let i = tamanho; i >= 1; i--) {
                soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }

            resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

            return resultado === parseInt(digitos.charAt(1));
        }

        return false;
    }



    CNPJMask = (valor) => {
        return valor
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
    };

    validateImagesAtachments(files) {


        const intl = this.props.intl;
        if (files.length > 1) {
            this.setState({
                evAttachFile: {},
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "checklist.modal.select.image.error" }),
            })
            return
        }
        else {
            this.setState({ files: files })
        }

    }
    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    openOrCloseRemoveModal = () => {
        this.setState({ DialogRemoveState: !this.state.DialogRemoveState })
    }



    render() {
        const { classes, headerMenu } = this.props;
        const { activeStep, itemError, companyRegistration } = this.state;
        const steps = ['Dados', 'Consulta de CNPJ', 'Concluir Operação'];
        const intl = this.props.intl;
        PapiroConsole.log("this.state.companyRegistration")
        PapiroConsole.log(this.state.companyRegistration)
        PapiroConsole.log("this.state.item")

        PapiroConsole.log(this.state.item)


        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />

                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={browserHistory.goBack}
                                    >
                                        {<FormattedMessage id="back" />}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                                <AddIcCallIcon />
                            </Avatar>
                            <Typography component='h1' variant='h5' mt={2}>
                                Solicitar registro de novo número <Title />
                            </Typography>
                            <Stack sx={{ width: '100%' }} spacing={4} style={{ marginTop: '60px' }} >
                                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepButton color="inherit" onClick={() => this.handleStep(index)}>

                                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                            </StepButton>

                                        </Step>
                                    ))}
                                </Stepper>
                                <div>
                                    {activeStep === 0 && (
                                        <Grid container spacing={2} style={{ marginTop: 15 }}>
                                            <Grid item xs={12} >
                                                <SimpleAutoComplete
                                                    label={intl.formatMessage({ id: "organization" })}
                                                    options={this.state.organizationList}
                                                    stateName='organizationid'
                                                    changeSelect={this.changeValues}
                                                    selected={this.state.item.organizationid}
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <SimpleAutoComplete
                                                    label="Provedor de mensageria"
                                                    options={this.state.messageProviderList}
                                                    stateName='messageproviderid'
                                                    changeSelect={this.changeValues}
                                                    selected={this.state.item.messageproviderid}
                                                />
                                            </Grid>
                                            {this.state.item.messageproviderid && this.state.item.messageproviderid.id && this.state.item.messageproviderid.id == 2 &&
                                                <Grid item xs={12}>
                                                    <Grid container  >
                                                        <Grid item xs={12} sm={12} md={6} lg={3} >
                                                            <iframe

                                                                src={this.state.urlRegisterMetaAccount}
                                                                width="100%"
                                                                height="50px"
                                                                style={{
                                                                    border: 'none',
                                                                    overflow: 'hidden',
                                                                }}
                                                                scrolling="no"
                                                            />

                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={3} style={{ paddingTop: '10px', fontWeight: 'bold' }}>

                                                            <Button

                                                                onClick={this.metaDataVerification}
                                                                style={{color: this.state.verifiedState ? "#3f51b4" : "#fff"}}
                                                                disabled={this.state.verifiedState ? true : false}
                                                                fullWidth
                                                                variant='contained'
                                                                color='secondary'
                                                                startIcon={this.state.verifiedState && <CheckIcon />}
                                                            >
                                                                {this.state.verifiedState ? "Verificado" : "Verificar"}
                                                            </Button>

                                                        </Grid>

                                                        <Grid item xs={12} >
                                                            <TextField
                                                                label="Nome da conta"
                                                                name="accountName"
                                                                value={this.state.metaAuthentication.accountName}
                                                                onChange={this.handleInputChangeMetaAuthentication}
                                                                variant="outlined"
                                                                fullWidth
                                                                margin="normal"
                                                            />

                                                        </Grid>

                                                    </Grid>



                                                </Grid>



                                            }
                                            <Grid item xs={12}  >
                                                <PhoneInput
                                                    inputStyle={{
                                                        padding: '23.5px 14px 23.5px 58px',
                                                        width: '100%'
                                                    }}
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0
                                                    }}
                                                    id="phone"
                                                    name="phone"
                                                    country={'br'}
                                                    value={this.state.item.phoneNumber}
                                                    onChange={this.handleChangePhone}
                                                    InputLabel={this.props.intl.formatMessage({ id: "phone" })}
                                                    specialLabel="Número de telefone origem da mensageria "
                                                    inputProps={{
                                                        name: this.props.intl.formatMessage({ id: "phone" })
                                                    }}
                                                />
                                            </Grid>
                                            {this.state.item.messageproviderid && this.state.item.messageproviderid.id && this.state.item.messageproviderid.id == 2 && this.state.verifiedState &&
                                                <Grid item xs={12} >
                                                    <TextField
                                                        label="Código pin enviado no processo de registro acima(SMS)"
                                                        name="smsCode"
                                                        value={this.state.metaAuthentication.smsCode}
                                                        onChange={this.handleInputChangeMetaAuthentication}
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            }




                                        </Grid>
                                    )}
                                    {activeStep === 1 && (

                                        <div>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    fullWidth
                                                    label={intl.formatMessage({ id: "cnpj" })}
                                                    inputProps={{ maxLength: 255 }}
                                                    autoComplete="fname"
                                                    type="cnpj"
                                                    variant="outlined"
                                                    name="cnpj"
                                                    onChange={this.handleInputChange}
                                                    value={this.state.item.cnpj}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={itemError.cnpj?.length > 0}
                                                    helperText={itemError.cnpj}
                                                    required
                                                />
                                            </Grid>





                                        </div>

                                    )}
                                    {activeStep === 2 && (

                                        <div>
                                            {this.state.hasCompany == true ?

                                                <Grid container spacing={2} style={{ marginTop: 15 }}>
                                                    <Grid item xs={12} mb={2}>
                                                        <Typography variant="h4" >
                                                            Dados da empresa
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12}  >
                                                        <Paper style={{ padding: 16, border: '1px solid #e0e0e0', borderRadius: 4 }}>
                                                            <Typography variant="h6">
                                                                Nome da empresa:
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {this.state.company ? this.state.company.name : null}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>

                                                    <Grid item xs={12} >
                                                        <Paper style={{ padding: 16, border: '1px solid #e0e0e0', borderRadius: 4 }}>
                                                            <Typography variant="h6">
                                                                {intl.formatMessage({ id: "company.account.id" })} {this.state.item.messageproviderid && (this.state.item.messageproviderid.id == 2 || this.state.item.messageproviderid.id == 3 || this.state.item.messageproviderid.id == 4) ? "(Necessário conta verificada no Facebook)" : ""}:
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {this.state.company ? this.state.company.accountid : null}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>

                                                    <Grid item xs={12}  >
                                                        <Paper style={{ padding: 16, border: '1px solid #e0e0e0', borderRadius: 4 }}>
                                                            <Typography variant="h6">
                                                                CNPJ:
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {this.state.company ? this.state.company.identificationnumber : null}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12}  >
                                                        <Paper style={{ padding: 16, border: '1px solid #e0e0e0', borderRadius: 4 }}>
                                                            <Typography variant="h6">
                                                                Endereço:
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {this.state.company ? this.state.company.address : null}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12}  >
                                                        <Paper style={{ padding: 16, border: '1px solid #e0e0e0', borderRadius: 4 }}>
                                                            <Typography variant="h6">
                                                                Website:
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {this.state.company ? this.state.company.website : null}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12}  >
                                                        <Paper style={{ padding: 16, border: '1px solid #e0e0e0', borderRadius: 4 }}>
                                                            <Typography variant="h6">
                                                                Atividade Principal:
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {this.state.company ? this.state.company.mainactivity : null}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12}  >
                                                        <Paper style={{ padding: 16, border: '1px solid #e0e0e0', borderRadius: 4 }}>
                                                            <Typography variant="h6">
                                                                Número de telefone do chip:
                                                            </Typography>
                                                            <Typography variant="body1">
                                                                {this.state.company ? this.state.company.phone : null}
                                                            </Typography>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>


                                                :
                                                <Grid container spacing={2} style={{ marginTop: 15 }}>


                                                    <Grid item xs={12} mb={2}>
                                                        <Typography variant="h4" >Registre sua empresa</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField label={intl.formatMessage({ id: "company.name" })} autoComplete='fname' variant='outlined' name='companyName' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.companyName} required fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField label={intl.formatMessage({ id: "company.account.id" }) + ((this.state.item.messageproviderid && (this.state.item.messageproviderid.id == 2 || this.state.item.messageproviderid.id == 3 || this.state.item.messageproviderid.id == 4) ? "(Necessário conta verificada no Facebook)" : ""))} autoComplete='fname' variant='outlined' name='companyAccountId' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.companyAccountId} required fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField label={intl.formatMessage({ id: "address" })} autoComplete='fname' variant='outlined' name='companyAddress' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.companyAddress} required fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField label={intl.formatMessage({ id: "company.website" })} autoComplete='fname' variant='outlined' name='companyWebsite' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.companyWebsite} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField label={intl.formatMessage({ id: "company.main.activity" })} autoComplete='fname' variant='outlined' name='companyMainActivity' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.companyMainActivity} required fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <PhoneInput
                                                            inputStyle={{
                                                                padding: '23.5px 14px 23.5px 58px',
                                                                width: '100%'
                                                            }}
                                                            style={{
                                                                marginTop: 16,
                                                                marginBottom: 8
                                                            }}
                                                            id="phone"
                                                            name="companyPhone"
                                                            country={'br'}
                                                            value={companyRegistration.companyPhone}
                                                            onChange={this.handleChangePhoneCompany}
                                                            InputLabel={this.props.intl.formatMessage({ id: "company.chip.phone.number" })}
                                                            specialLabel="Telefone da empresa"
                                                            inputProps={{
                                                                name: this.props.intl.formatMessage({ id: "phone" })
                                                            }}
                                                        />
                                                    </Grid>


                                                    <Grid item xs={12} >
                                                        {/*<TextField label="Logo" autoComplete='fname' variant='outlined' name='logo' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.logo} required fullWidth />*/}
                                                        <Grid item xs={12} sm={12}>
                                                            <section style={{ width: '100%' }}>
                                                                <div className={classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                                                                    <Dropzone onDrop={acceptedFiles => this.validateImagesAtachments([...acceptedFiles, ...this.state.files])}>
                                                                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                                            <div {...getRootProps()}>
                                                                                <input {...getInputProps()} />
                                                                                <AttachFileIcon />
                                                                                <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                                                    <FormattedMessage id="drag.and.drop.logo" />
                                                                                </Typography>
                                                                            </div>
                                                                        )}
                                                                    </Dropzone>
                                                                    {this.state.files.map(file => {
                                                                        return (
                                                                            <div className={classes.dropzoneItem} key={file.name}>
                                                                                {file.name}
                                                                                <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </section>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            }


                                        </div>

                                    )}

                                    <Grid container spacing={2} style={{ marginTop: '20px' }} >
                                        <Grid item xs={6}>

                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={this.handleBack}
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                className={classes.submit}
                                            >
                                                Voltar
                                            </Button>
                                        </Grid>


                                        <Grid item xs={3}>
                                            <Button
                                                onClick={activeStep === steps.length - 1 ? this.openOrCloseRemoveModal : this.handleNext}
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                className={classes.submit}
                                            >
                                                {activeStep === steps.length - 1 ? 'Solicitar' : 'Próximo'}
                                            </Button>
                                        </Grid>

                                    </Grid>
                                    <Button >

                                    </Button>

                                </div>
                            </Stack>

                        </div>


                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                        <DialogGeneral
                            dialogTitle={this.props.intl.formatMessage({ id: "new.number.registration.request" })}
                            dialogSubTitle={this.props.intl.formatMessage({ id: "complete.this.operation" })}
                            open={this.state.DialogRemoveState}
                            openOrCloseModalFunction={this.openOrCloseRemoveModal}
                            handleConfirmation={this.handleSubmit}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
PhoneRegistrationRequestsRegistration.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(PhoneRegistrationRequestsRegistration)));
PhoneRegistrationRequestsRegistration.contextType = AbilityContext;
