import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, Typography, Container, Link, Grid, CircularProgress, createTheme } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Api from "../../services/api";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import CustomizedSnackbars from "../../components/material-snackbars";
import { Overlay } from "../../styles/global";
import { injectIntl } from "react-intl";
import { ThemeProvider } from '@mui/material/styles';
import { PapiroConsole } from "src/utils/papiroConsole";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      firstNumber: "",
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      colorTheme: Api != null ? Api.getPrimaryColorTheme() : "#3F51B4",
      appName: Api != null ? Api.getAppName() : "CMTECH - Mexx 2DO",
      secondaryColorTheme: Api != null ? Api.getSecondaryColorTheme() : "#277afe",
      itemError: {
        firstNumber: ""
      },
      sumChapta: {
        firstNumber: 0,
        secondNumber: 0,
        sum: 0
      }
    };

    this.closeNotification = this.closeNotification.bind(this);
    this.generateSumCaptcha = this.generateSumCaptcha.bind(this)
  }

  componentDidMount() {
    document.title = this.state.appName;
    this.generateSumCaptcha();
  }

  handleInputChange = e => {
    const { name, value } = e.target;

    PapiroConsole.log(name);
    PapiroConsole.log(value);

    if(name == "firstNumber"){
      this.setState({ firstNumber : "" });
      const regex = /^[0-9\b]+$/;
      const isFieldValid = regex.test(value);

      PapiroConsole.log('passou aqui')

      if(isFieldValid)
        this.setState(prevState => ({
          ...prevState,
          firstNumber : value
        }));

    }else if(name == "email"){
      this.setState({ email : value });
    }
    
    
  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    const isCaptchaValid = this.validateCaptcha();

    if(isCaptchaValid)
    {

      this.setState({ loading: true });
      
      let data = new FormData();
      data.append("email", this.state.email);

      Api.post("/users/password-recovery", data)
        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"mail.sent.success.verify.mail"})
            });
          }
        })
        .catch(err => {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
        });
    }else{
      alert(intl.formatMessage({id:"captcha.is.not.valid"}));
      this.generateSumCaptcha();
      this.setState({ firstNumber: '' })
    }
  };
  
  validateCaptcha(){
    const { firstNumber, sumChapta } = this.state;
    let isValid = true;

    if(firstNumber.length === 0) {
      isValid = false;
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } }))
    } else if(firstNumber != sumChapta.firstNumber) {
      isValid = false;
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'CAPTCHA inválido' } }))
    } else if (firstNumber == sumChapta.firstNumber) {
      isValid = true;
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: '' } }))
    } else {
      isValid = false;
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } }))
    }

    return isValid;
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  generateSumCaptcha = () => {
    let firstNumber = Math.floor(Math.random() * 10) + 1
    let secondNumber = Math.floor(Math.random() * 10) + 1
    let sum = firstNumber + secondNumber

    this.setState({
      sumChapta: {
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        sum: sum
      }
    })
  }

  render() {
    const intl = this.props.intl;
    const { classes } = this.props;
    const { email, firstNumber, itemError } = this.state;

    PapiroConsole.log('----state----')
    PapiroConsole.log(this.state)

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const theme = createTheme({
      palette: {
        primary: {
          main: this.state.colorTheme
        },
        secondary: {
          main: this.state.secondaryColorTheme
        }
      }
    });

    return (
      <ThemeProvider theme={theme}>
        <div style={{ background: "white", height: '100%' }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {intl.formatMessage({id:"recover.password"})}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({id:"email"})}
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              value={email}
              onChange={this.handleInputChange}
            />
            <Grid item sm={12} md={12} lg={10}>
              <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                Complete a operação*
              </Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={2} />
            <Grid item sm={12} md={12} lg={10}>
              <Grid container spacing={0} style={{marginBottom:'30px'}}>
                <Grid item sm={2} md={2} lg={2} >
                  <TextField
                    name="firstNumber"
                    label=""
                    required
                    fullWidth
                    value={ firstNumber }
                    onChange={this.handleInputChange}
                    inputProps={{ maxLength: 1 }}
                    InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item sm={10} md={10} lg={10} style={{ display: 'flex', alignItems: 'center' }}>
                    &nbsp; + {this.state.sumChapta.secondNumber} = {this.state.sumChapta.sum}
                </Grid>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {intl.formatMessage({id:"recover"})}
            </Button>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Link href="/">{this.props.intl.formatMessage({id:"back"})}</Link>
              </Grid>
            </Grid>
          </form>
        </div>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container >
      </div>
      </ThemeProvider>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(Styles)(ForgotPassword));
