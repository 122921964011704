import React from 'react';
import { 
  Box, 
  Typography, 
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment
} from '@mui/material';
import {
  FilterList,
  Search,
  Add
} from '@mui/icons-material';

const FilterBar = ({ filterOptions, handleFilterChange, openTicketForm }) => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 3,
        flexWrap: 'wrap',
        gap: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
        <FilterList color="action" />
        <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>Filtros:</Typography>
        
        <Chip 
          icon={<Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#3b82f6', mr: 1 }} />}
          label="Novo" 
          variant={filterOptions.status === 'Novo' ? "filled" : "outlined"}
          onClick={() => handleFilterChange('status', filterOptions.status === 'Novo' ? 'all' : 'Novo')}
          clickable
        />
        
        <Chip 
          icon={<Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#f59e0b', mr: 1 }} />}
          label="Em andamento" 
          variant={filterOptions.status === 'Em andamento' ? "filled" : "outlined"}
          onClick={() => handleFilterChange('status', filterOptions.status === 'Em andamento' ? 'all' : 'Em andamento')}
          clickable
        />
        
        <Chip 
          icon={<Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: '#10b981', mr: 1 }} />}
          label="Finalizado" 
          variant={filterOptions.status === 'Finalizado' ? "filled" : "outlined"}
          onClick={() => handleFilterChange('status', filterOptions.status === 'Finalizado' ? 'all' : 'Finalizado')}
          clickable
        />
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
        <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
          <InputLabel id="priority-filter-label">Prioridade</InputLabel>
          <Select
            labelId="priority-filter-label"
            value={filterOptions.priority}
            label="Prioridade"
            onChange={(e) => handleFilterChange('priority', e.target.value)}
            size="small"
          >
            <MenuItem value="all">Todas</MenuItem>
            <MenuItem value="Alta">Alta</MenuItem>
            <MenuItem value="Média">Média</MenuItem>
            <MenuItem value="Baixa">Baixa</MenuItem>
          </Select>
        </FormControl>
        
        <TextField
          placeholder="Pesquisar tickets..."
          size="small"
          value={filterOptions.search}
          onChange={(e) => handleFilterChange('search', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            ),
            sx: { borderRadius: 2 }
          }}
          sx={{ minWidth: 200 }}
        />
      </Box>
      
      <Button 
        variant="contained" 
        color="primary" 
        startIcon={<Add />}
        onClick={() => openTicketForm()}
      >
        Novo Ticket
      </Button>
    </Box>
  );
};

export default FilterBar;