import { Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import TitleName from "src/components/title-name";
import { Styles } from "src/styles/material-styles";
import PropTypes from "prop-types"
import { PapiroConsole } from "src/utils/papiroConsole";
import SimpleCheckBox from "src/components/checkbox/check";
import Dropzone from "react-dropzone";
import { AttachFile, Delete, DeleteForever } from "@mui/icons-material";
import Api from "src/services/api";
import DialogConfirmationDeleteImage from '../../../components/dialog-confirmation-not-button'
import * as Service from '../../../services/clientportal.service'
import * as FileService from '../../../services/attachment.service';

function FooterStep(props) {

    const { classes, intl, backButton, nextButton, clientPortal, handleNextBarProgress, handleComplete, setLoading, setNotification, setClientPortal } = props

    const [deleteFooterImageDialog, setDeleteFooterImageDialog] = useState(false)

    const [item, setItem] = useState({
        id : clientPortal.id,
        footertext: clientPortal.footertext ? clientPortal.footertext : '',
        footerphone: clientPortal.footerphone ? clientPortal.footerphone : '',
        footeremail: clientPortal.footeremail ? clientPortal.footeremail : '',
        showinstagram: clientPortal.showinstagram ? clientPortal.showinstagram : '',
        instagramlink: clientPortal.instagramlink ? clientPortal.instagramlink : '',
        showlinkedin: clientPortal.showlinkedin ? clientPortal.showlinkedin : '',
        linkedinlink: clientPortal.linkedinlink ? clientPortal.linkedinlink : '',
        showyoutube: clientPortal.showyoutube ? clientPortal.showyoutube : '',
        youtubelink: clientPortal.youtubelink ? clientPortal.youtubelink : '',
        showfacebook: clientPortal.showfacebook ? clientPortal.showfacebook : '',
        facebooklink: clientPortal.facebooklink ? clientPortal.facebooklink : '',
        footerimagefiles: clientPortal.footerimagefiles ? clientPortal.footerimagefiles : '',
        footerimagefiles: [],
    })

    useEffect(() => {
        setItem({
            id : clientPortal.id,
            footertext: clientPortal.footertext ? clientPortal.footertext : '',
            footerphone: clientPortal.footerphone ? clientPortal.footerphone : '',
            footeremail: clientPortal.footeremail ? clientPortal.footeremail : '',
            showinstagram: clientPortal.showinstagram ? clientPortal.showinstagram : '',
            instagramlink: clientPortal.instagramlink ? clientPortal.instagramlink : '',
            showlinkedin: clientPortal.showlinkedin ? clientPortal.showlinkedin : '',
            linkedinlink: clientPortal.linkedinlink ? clientPortal.linkedinlink : '',
            showyoutube: clientPortal.showyoutube ? clientPortal.showyoutube : '',
            youtubelink: clientPortal.youtubelink ? clientPortal.youtubelink : '',
            showfacebook: clientPortal.showfacebook ? clientPortal.showfacebook : '',
            facebooklink: clientPortal.facebooklink ? clientPortal.facebooklink : '',
            footerimagefiles: clientPortal.footerimagefiles ? clientPortal.footerimagefiles : '',
            footerimagefiles: [],
        })
    }, [clientPortal])

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setItem({
            ...item,
            [name]: value
        })
    }

    const handleChangeSelect = (stateName, value) => {
        setItem({
            ...item,
            [stateName]: value
        })
    }

    const validateImagesAtachments = (files, inputName) => {

        if (inputName === 'footerimagefiles') {
            if (files.length > 1) {
                setItem({
                    ...item,
                    footerimagefiles: []
                })
                setNotification(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
                return
            }
            else {
                setItem({
                    ...item,
                    footerimagefiles: files
                })
            }
        }
    }

    const removeItem = (file, inputName) => {
        if (inputName === 'footerimagefiles') {
            let array = [...item.footerimagefiles]
            let index = array.indexOf(file)
            if (index !== -1) {
                array.splice(index, 1)
                setItem({
                    ...item,
                    footerimagefiles: array
                })
            }
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()

        let data = new FormData()

        data.append('id', clientPortal.id)
        data.append('footertext', item.footertext)
        data.append('footerphone', item.footerphone)
        data.append('footeremail', item.footeremail)
        data.append('showinstagram', item.showinstagram)
        data.append('instagramlink', item.instagramlink)
        data.append('showlinkedin', item.showlinkedin)
        data.append('linkedinlink', item.linkedinlink)
        data.append('showyoutube', item.showyoutube)
        data.append('youtubelink', item.youtubelink)
        data.append('showfacebook', item.showfacebook)
        data.append('facebooklink', item.facebooklink)
        item.footerimagefiles.forEach(file => { data.append('footerimagefiles', file) })

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        await Api.put("/clientportal/footer", data, config)
            .then(result => {
                if (result.data.success) {
                    setClientPortal(result.data.data)
                    setLoading(false)
                    handleNextBarProgress()
                    handleComplete()
                }
                else {
                    setLoading(false)
                    setNotification(true, 'error', result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                    Api.kickoff(result)
                }
            })
            .catch(err => {
                setLoading(false)
                setNotification(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            })
    }

    const deleteImage = async () => {

        setLoading(true)
        const result = await Service.deleteImage(item.id, "footer")
        if (result.success) {
            setClientPortal({ ...item, footerimage: null })
            setNotification(true, 'success', intl.formatMessage({ id: "delete.sucess" }))
        } else {
            setNotification(true, 'error', result != null ? result : intl.formatMessage({ id: "process.error" }))
        }
        setDeleteFooterImageDialog(false)
        setLoading(false)
    }

    const getFile = async (hirerid, fileName, filerepositorymethod) => {
        setLoading(true)

        let url = await FileService.getFileByPath(hirerid, fileName, filerepositorymethod);
        if (url && url.data && url.data.data && url.data.success) {
            setLoading(false)
            window.open(`${url.data.data}`, '_blank')
        } else {
            setLoading(false)
            setNotification(true, 'error', intl.formatMessage({ id: "file.not.found" }))
        }
    }

    const getFileName = (fileName) => {
        let file = fileName != null ? fileName.substring(fileName.lastIndexOf('/') + 1) : "";
        return file;
    }

    return (
        <div className={classes.root}>
            <Container>
                <CssBaseline />
                <div className={classes.paper} style={{ marginTop: 0 }}>
                    <Typography component='h1' variant='h5'>
                        {<FormattedMessage id="footer" />}
                        <TitleName />
                    </Typography>
                    <form name='myForm' className={classes.form} onSubmit={handleSubmit} encType='multipart/form-data'>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "mail.body" })} name="footertext" value={item.footertext} onChange={handleChange} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "phone" })} name="footerphone" value={item.footerphone} onChange={handleChange} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "email" })} name="footeremail" value={item.footeremail} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.instagram" })} name='showinstagram' stateName='showinstagram' changeSelect={handleChangeSelect} selected={item.showinstagram} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label="instagram" name="instagramlink" value={item.instagramlink} onChange={handleChange} disabled={!item.showinstagram} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.facebook" })} name='showfacebook' stateName='showfacebook' changeSelect={handleChangeSelect} selected={item.showfacebook} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label="facebook" name="facebooklink" value={item.facebooklink} onChange={handleChange} disabled={!item.showfacebook} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.youtube" })} name='showyoutube' stateName='showyoutube' changeSelect={handleChangeSelect} selected={item.showyoutube} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label="youtube" name="youtubelink" value={item.youtubelink} onChange={handleChange} disabled={!item.showyoutube} />
                            </Grid>
                            <Grid item xs={10} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({ id: "show.linkedin" })} name='showlinkedin' stateName='showlinkedin' changeSelect={handleChangeSelect} selected={item.showlinkedin} />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <TextField fullWidth label="linkedin" name="linkedinlink" value={item.linkedinlink} onChange={handleChange} disabled={!item.showlinkedin} />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{ marginBottom: '0' }}>
                                <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagem de Rodapé</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <section style={{ width: '100%' }}>
                                    <div className={classes.dropzoneArea}>
                                        <Dropzone onDrop={acceptedFiles => validateImagesAtachments(acceptedFiles, "footerimagefiles")}>
                                            {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>{<FormattedMessage id="drag.and.drop" />}</p>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {item.footerimagefiles.map(file => {
                                            return (
                                                <div className={classes.dropzoneItem} key={file.name}>
                                                    {file.name}
                                                    <DeleteForever className={classes.deleteItem} onClick={() => { removeItem(file, "footerimagefiles") }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </section>
                            </Grid>
                            {item.footerimage != null && item.footerimage != "null" && item.footerimage != "undefined" && (
                                <Grid item xs={12}>
                                    <label>Anexo</label>
                                    <ul>
                                        <li key={item.footerimage.path}>
                                            <Grid container alignItems='center'>
                                                <Grid item sm={.5}>
                                                    <AttachFile />
                                                </Grid>
                                                <Grid item sm={10.5}>
                                                    <Button onClick={() => getFile(item.footerimage.hirerid, item.footerimage.path, item.footerimage.filerepositorymethod)}>
                                                        {getFileName(item.footerimage.path)}
                                                    </Button>
                                                </Grid>
                                                <Grid item sm={1}>
                                                    <Button color='inherit' onClick={_ => setDeleteFooterImageDialog(true)}>
                                                        <Delete />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </li>
                                    </ul>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={0} style={{ marginBottom: '60px', display: 'flex', marginTop: '20px' }}>
                            <Grid item xs={12} sm={2} >
                                {backButton}
                            </Grid>
                            <Grid item xs={12} sm={2} >
                                {nextButton}
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginLeft: 'auto' }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                >
                                    {<FormattedMessage id="save.next" />}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    {deleteFooterImageDialog && (
                        <DialogConfirmationDeleteImage
                            msgButton={intl.formatMessage({ id: "confirm" })}
                            msg={intl.formatMessage({ id: "delete.image.confirmation" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            msgAgree={intl.formatMessage({ id: "yes" })}
                            handleConfirmation={_ => deleteImage()}
                            handleClose={_ => setDeleteFooterImageDialog(false)}
                            classes={classes}
                            disabled={false}
                            icon={<Delete />}
                        />
                    )}
                </div>
            </Container >
        </div >
    )
}

FooterStep.propTypes = {
    classes: PropTypes.object.isRequired,
    back: PropTypes.func,
    next: PropTypes.func
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,

});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(FooterStep)));