import React, { useState, useEffect, forwardRef } from 'react';
import { 
  Box, 
  Grid, 
  Container, 
  CircularProgress, 
  Typography, 
  Chip, 
  Card, 
  CardContent,
  Button
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  AccessTime as ClockIcon,
  Warning as AlertIcon,
  CheckCircle as CheckCircleIcon,
  KeyboardArrowDown as ArrowDownIcon
} from '@mui/icons-material';

import { mockTickets } from '../../data/mock-data';

import ModernAppBar from '../../components/kanban/ModernAppBar';
import TicketDetailModal from '../../components/kanban/TicketDetailModal';
import ConfirmationDialog from '../../components/kanban/ConfirmationDialog';
import ToastNotification from '../../components/kanban/ToastNotification';


// Wrapper para o Droppable - soluciona problemas com o Strict Mode no React 18
const StrictModeDroppable = forwardRef((props, ref) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props} ref={ref} />;
});

StrictModeDroppable.displayName = 'StrictModeDroppable';

// Ticket Card com layout original
const EnhancedTicketCard = ({ ticket, index, onClick }) => {
  // Determina a cor baseada na prioridade
  const getPriorityColor = (priority) => {
    switch(priority.toLowerCase()) {
      case 'alta':
        return { bg: '#fee2e2', color: '#ef4444' };
      case 'média':
        return { bg: '#fff7cd', color: '#f59e0b' };
      case 'baixa':
        return { bg: '#d1fae5', color: '#10b981' };
      default:
        return { bg: '#f3f4f6', color: '#6b7280' };
    }
  };

  const priorityStyle = getPriorityColor(ticket.priority);

  return (
    <Draggable draggableId={String(ticket.id)} index={index}>
      {(provided, snapshot) => (
        <Card 
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ 
            mb: 2, 
            borderRadius: 2,
            cursor: 'pointer',
            transition: 'transform 0.2s, box-shadow 0.2s',
            boxShadow: snapshot.isDragging ? '0 10px 20px rgba(0,0,0,0.15)' : '0 1px 3px rgba(0,0,0,0.1)',
            transform: snapshot.isDragging ? `${provided.draggableProps.style?.transform || ''} rotate(2deg)` : provided.draggableProps.style?.transform,
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: 3
            },
            ...provided.draggableProps.style
          }}
          onClick={() => onClick(ticket)}
        >
          <CardContent sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                #{ticket.id}
              </Typography>
              <Chip 
                label={ticket.priority} 
                size="small"
                sx={{ 
                  bgcolor: priorityStyle.bg,
                  color: priorityStyle.color,
                  fontWeight: 'medium',
                  fontSize: '0.7rem',
                  height: '22px'
                }}
              />
            </Box>
            
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1.5 }}>
              {ticket.title}
            </Typography>
            
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 'medium' }}>
              {ticket.client}
            </Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="caption" sx={{ color: 'text.secondary', mr: 0.5 }}>
                Previsão:
              </Typography>
              <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
                {ticket.predictedDate}
              </Typography>
            </Box>
            
            {ticket.assignedTo && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                <Typography variant="caption" sx={{ color: 'text.secondary', mr: 0.5 }}>
                  Responsável:
                </Typography>
                <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
                  {ticket.assignedTo}
                </Typography>
              </Box>
            )}
            
            <Chip 
              label={ticket.category} 
              size="small"
              sx={{ 
                bgcolor: '#f3f4f6',
                color: '#4b5563',
                fontSize: '0.7rem',
                height: '22px'
              }}
            />
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};

// Coluna de status com o layout original e paginação
const EnhancedStatusColumn = ({ column, tickets, onTicketClick, paginationInfo, onLoadMore }) => {
  const { visibleTickets, hasMore, totalCount } = paginationInfo;
  
  return (
    <Box 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: 1,
        bgcolor: 'white'
      }}
    >
      <Box 
        sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderTop: 3,
          borderColor: column.color,
          bgcolor: 'white'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <column.icon sx={{ color: column.color }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {column.title}
          </Typography>
        </Box>
        <Chip 
          label={totalCount}
          size="small"
          sx={{ 
            bgcolor: '#f3f4f6',
            fontWeight: 'medium',
            minWidth: '28px'
          }}
        />
      </Box>

      <StrictModeDroppable droppableId={column.id}>
        {(provided, snapshot) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ 
              p: 2, 
              flexGrow: 1, 
              overflowY: 'auto', 
              bgcolor: snapshot.isDraggingOver ? '#edf2f7' : '#f9fafb',
              transition: 'background-color 0.2s ease',
              display: 'flex',
              flexDirection: 'column',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#d1d5db',
                borderRadius: '3px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#9ca3af',
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              {visibleTickets.length > 0 ? (
                visibleTickets.map((ticket, index) => (
                  <EnhancedTicketCard 
                    key={ticket.id}
                    ticket={ticket}
                    index={index}
                    onClick={onTicketClick}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    py: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: '#f3f4f6',
                    color: '#6b7280',
                    borderRadius: 2
                  }}
                >
                  <Typography variant="body2">Nenhum ticket neste status</Typography>
                </Box>
              )}
              {provided.placeholder}
            </Box>
            
            {/* Botão "Carregar mais" */}
            {hasMore && (
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => onLoadMore(column.id)}
                  startIcon={<ArrowDownIcon />}
                  sx={{ borderRadius: 2 }}
                >
                  Carregar mais ({totalCount - visibleTickets.length} restantes)
                </Button>
              </Box>
            )}
          </Box>
        )}
      </StrictModeDroppable>
    </Box>
  );
};

const TicketKanban = () => {
  // Estados principais
  const [tickets, setTickets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Estado para controlar paginação para cada coluna
  const [pagination, setPagination] = useState({
    'novo': { page: 1, perPage: 10 },
    'em-andamento': { page: 1, perPage: 10 },
    'finalizado': { page: 1, perPage: 10 }
  });
  
  // Estados para diálogo de confirmação
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingMove, setPendingMove] = useState(null);
  
  // Estado para toast de notificação
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // Status das colunas do Kanban
  const kanbanColumns = [
    { id: 'novo', title: 'Novo', icon: ClockIcon, color: '#3b82f6' },
    { id: 'em-andamento', title: 'Em andamento', icon: AlertIcon, color: '#f59e0b' },
    { id: 'finalizado', title: 'Finalizado', icon: CheckCircleIcon, color: '#10b981' }
  ];
  
  // Função para obter o título formatado do status
  const getStatusTitle = (statusId) => {
    const column = kanbanColumns.find(col => col.id === statusId);
    return column ? column.title : statusId;
  };
  
  // Carregar tickets
  useEffect(() => {
    try {
      // Simula um carregamento de dados
      setTimeout(() => {
        const formattedTickets = mockTickets.map(ticket => ({
          ...ticket,
          id: String(ticket.id) // Converte IDs para string
        }));
        setTickets(formattedTickets);
        setLoading(false);
      }, 500);
    } catch (err) {
      console.error("Erro ao carregar tickets:", err);
      setError("Erro ao carregar tickets. Tente novamente.");
      setLoading(false);
    }
  }, []);
  
  // Configurações de cor e estilo baseado no status
  const getStatusConfig = (status) => {
    switch(status?.toLowerCase()) {
      case 'novo':
        return { bgColor: '#ebf5ff', color: '#3b82f6', icon: ClockIcon };
      case 'em andamento':
        return { bgColor: '#fffbeb', color: '#f59e0b', icon: AlertIcon };
      case 'finalizado':
        return { bgColor: '#ecfdf5', color: '#10b981', icon: CheckCircleIcon };
      default:
        return { bgColor: '#f3f4f6', color: '#6b7280', icon: null };
    }
  };
  
  // Filtra os tickets com base no termo de busca
  const filterTickets = (ticketsToFilter) => {
    if (!searchTerm) return ticketsToFilter;
    
    return ticketsToFilter.filter(ticket => 
      ticket.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ticket.id.includes(searchTerm) ||
      ticket.client.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };
  
  // Obter tickets filtrados por status e com paginação
  const getTicketsByStatus = (status) => {
    const normalizedStatus = status.replace('-', ' ');
    const filteredTickets = filterTickets(tickets.filter(ticket => 
      ticket.status.toLowerCase() === normalizedStatus
    ));
    
    // Obter configuração de paginação para a coluna
    const paginationConfig = pagination[status];
    const startIndex = 0;
    const endIndex = paginationConfig.page * paginationConfig.perPage;
    
    // Retornar tickets visíveis com base na paginação
    return {
      visibleTickets: filteredTickets.slice(startIndex, endIndex),
      hasMore: filteredTickets.length > endIndex,
      totalCount: filteredTickets.length
    };
  };
  
  // Carregar mais tickets para uma coluna específica
  const handleLoadMore = (columnId) => {
    setPagination(prev => ({
      ...prev,
      [columnId]: {
        ...prev[columnId],
        page: prev[columnId].page + 1
      }
    }));
  };
  
  // Abre o modal para visualizar os detalhes do ticket
  const openTicketDetails = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };
  
  // Fecha o modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTicket(null);
  };
  
  // Fecha o toast de notificação
  const handleCloseToast = () => {
    setToast({ ...toast, open: false });
  };
  
  // Manipula o drag and drop
  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    
    // Verifica se o destino é válido (se não tiver destino, o ticket volta ao lugar original automaticamente)
    if (!destination) {
      return;
    }
    
    // Verifica se o ticket realmente mudou de lugar
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    
    // Encontra o ticket que está sendo arrastado
    const ticketToMove = tickets.find(ticket => ticket.id === draggableId);
    
    if (!ticketToMove) {
      return;
    }
    
    // Em vez de atualizar o status imediatamente, armazena a operação pendente
    // e abre o diálogo de confirmação
    setPendingMove({
      ticketId: draggableId,
      fromStatus: ticketToMove.status,
      toStatusId: destination.droppableId,
      fromStatusId: source.droppableId
    });
    
    setConfirmDialogOpen(true);
  };
  
  // Manipula a confirmação ou cancelamento da movimentação do ticket
  const handleConfirmMove = (confirmed) => {
    setConfirmDialogOpen(false);
    
    if (confirmed && pendingMove) {
      // Extrai informações do movimento pendente
      const { ticketId, toStatusId } = pendingMove;
      
      // Converte o ID da coluna de destino para um status
      let newStatus = toStatusId.replace('-', ' ');
      // Primeira letra maiúscula, resto minúsculo
      newStatus = newStatus.charAt(0).toUpperCase() + newStatus.slice(1);
      
      // Cria uma cópia dos tickets atuais
      const newTickets = [...tickets];
      
      // Encontra o ticket no array e atualiza seu status
      const ticketIndex = newTickets.findIndex(ticket => ticket.id === ticketId);
      
      if (ticketIndex !== -1) {
        // Guarda o status antigo para exibição no toast
        const oldStatus = newTickets[ticketIndex].status;
        
        // Atualiza o status do ticket
        newTickets[ticketIndex] = {
          ...newTickets[ticketIndex],
          status: newStatus
        };
        
        // Atualiza o estado dos tickets
        setTickets(newTickets);
        
        // Exibe toast de confirmação
        setToast({
          open: true,
          message: `Ticket #${ticketId} movido de ${oldStatus} para ${newStatus}`,
          severity: 'success'
        });
      }
    }
    
    // Limpa o movimento pendente
    setPendingMove(null);
  };
  
  // Exibe um indicador de carregamento
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  // Exibe uma mensagem de erro, se houver
  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: '#f9fafb' }}>
      {/* AppBar Moderno */}
      <ModernAppBar
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
      />
      
      {/* Conteúdo Principal */}
      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 3 }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Grid container spacing={3} sx={{ height: 'calc(100vh - 120px)' }}>
            {kanbanColumns.map(column => (
              <Grid item xs={12} md={4} key={column.id} sx={{ height: '100%' }}>
                <EnhancedStatusColumn 
                  column={column} 
                  tickets={getTicketsByStatus(column.id).visibleTickets}
                  paginationInfo={getTicketsByStatus(column.id)}
                  onTicketClick={openTicketDetails}
                  onLoadMore={handleLoadMore}
                />
              </Grid>
            ))}
          </Grid>
        </DragDropContext>
      </Container>
      
      {/* Modal de detalhes do ticket */}
      <TicketDetailModal
        ticket={selectedTicket}
        open={isModalOpen}
        onClose={closeModal}
        getStatusConfig={getStatusConfig}
      />
      
      {/* Diálogo de confirmação de mudança de status */}
      {pendingMove && (
        <ConfirmationDialog
          open={confirmDialogOpen}
          onClose={handleConfirmMove}
          onConfirm={handleConfirmMove}
          fromStatus={pendingMove.fromStatus}
          toStatus={getStatusTitle(pendingMove.toStatusId)}
        />
      )}
      
      {/* Toast de notificação */}
      <ToastNotification
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={handleCloseToast}
      />
    </Box>
  );
};

export default TicketKanban;