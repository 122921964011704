import Api from './api';

export const getByConfigId = async (configId) => {
    let data = [];
    await Api.get('/clientportal/configid/' + configId)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err;
        });
    return data;
}

export const deleteImage = async (clientPortalId, image) => {
    let data = []
    await Api.put(`/clientportal/delete${image}/${clientPortalId}`)
        .then(result => {
            if (result.data.success) {
                data = result.data
            } else {
                data = result.errors ? result.errors[0] : null
            }
        })
        .catch(err => {
            data = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : err.message ? err.message : null
        })
    return data
}