import React, { useState, useEffect } from 'react';
import { 
  Box, 
  CircularProgress,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';


// Componentes
import CalendarHeader from '../../components/calendar/CalendarHeader';
import FilterBar from '../../components/calendar/FilterBar';
import MonthView from '../../components/calendar/MonthView';
import WeekView from '../../components/calendar/WeekView';
import DayView from '../../components/calendar/DayView';
import DayDetailModal from '../../components/calendar/DayDetailModal';
import TicketFormModal from '../../components/calendar/TicketFormModal';


// Utilitários e dados
import { mockTickets } from '../../data/mock-data';
import {
  getStatusColor,
  getStatusIcon,
  getPriorityColor,
  formatDate,
  formatDateForSubmission,
  isToday,
  getDaysInMonth,
  getFirstDayOfMonth,
  monthNames,
  weekdayNames
} from '../../utils/ticketUtils';

// Estilos
import './ticket-calendar.css';


const TicketCalendar = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  // Estados principais
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [modalPagination, setModalPagination] = useState({ page: 1, perPage: 10 });
  const [modalSearch, setModalSearch] = useState('');
  
  // Estados para novas funcionalidades
  const [view, setView] = useState('month'); // 'month', 'week', 'day'
  const [isTicketFormOpen, setIsTicketFormOpen] = useState(false);
  const [ticketToEdit, setTicketToEdit] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    status: 'all',
    priority: 'all',
    search: ''
  });
  
  // Extrair mês e ano atual
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  
  // Carregar tickets
  useEffect(() => {
    try {
      // Simula um carregamento de dados
      setTimeout(() => {
        setTickets(mockTickets);
        setFilteredTickets(mockTickets);
        setLoading(false);
      }, 800);
    } catch (err) {
      console.error("Erro ao carregar tickets:", err);
      setLoading(false);
    }
  }, []);
  
  // Aplicar filtros quando mudam
  useEffect(() => {
    applyFilters();
  }, [filterOptions, tickets]);
  
  // Função para aplicar filtros
  const applyFilters = () => {
    if (!tickets.length) return; // Proteção contra erro quando tickets estiver vazio
    
    let filtered = [...tickets];
    
    // Filtrar por status
    if (filterOptions.status !== 'all') {
      filtered = filtered.filter(ticket => ticket.status === filterOptions.status);
    }
    
    // Filtrar por prioridade
    if (filterOptions.priority !== 'all') {
      filtered = filtered.filter(ticket => ticket.priority === filterOptions.priority);
    }
    
    // Filtrar por pesquisa
    if (filterOptions.search) {
      const searchLower = filterOptions.search.toLowerCase();
      filtered = filtered.filter(ticket => {
        // Verificar se as propriedades existem antes de chamar toLowerCase()
        return (
          (ticket.title && ticket.title.toLowerCase().includes(searchLower)) || 
          (ticket.description && ticket.description.toLowerCase().includes(searchLower)) ||
          (ticket.requester && ticket.requester.toLowerCase().includes(searchLower)) ||
          (ticket.id && ticket.id.toString().includes(searchLower))
        );
      });
    }
    
    setFilteredTickets(filtered);
  };
  
  // Handler para mudança de filtros
  const handleFilterChange = (type, value) => {
    setFilterOptions(prev => ({
      ...prev,
      [type]: value
    }));
  };
  
  // Navegar para o mês anterior
  const prevMonth = () => {
    setCurrentDate(new Date(currentYear, currentMonth - 1, 1));
  };
  
  // Navegar para o próximo mês
  const nextMonth = () => {
    setCurrentDate(new Date(currentYear, currentMonth + 1, 1));
  };
  
  // Ir para hoje
  const goToToday = () => {
    setCurrentDate(new Date());
  };
  
  // Verificar se é o dia atual (wrapper da função utilitária)
  const isTodayWrapper = (day) => {
    return isToday(day, currentMonth, currentYear);
  };
  
  // Formatar data (wrapper da função utilitária)
  const formatDateWrapper = (day) => {
    return formatDate(day, currentMonth, currentYear);
  };
  
  // Obter tickets para um dia específico
  const getTicketsForDate = (day) => {
    if (filteredTickets.length === 0) return [];
    
    const dateStr = formatDateWrapper(day);
    
    return filteredTickets.filter(ticket => {
      const predictedDate = ticket.predictedDate.split(' ')[0];
      return predictedDate === dateStr;
    });
  };
  
  // Filtrar tickets por pesquisa no modal
  const getFilteredModalTickets = (dayTickets) => {
    if (!modalSearch.trim()) {
      return dayTickets; // Retorna todos os tickets se não houver pesquisa
    }
    
    const searchLower = modalSearch.toLowerCase();
    return dayTickets.filter(ticket => {
      // Verificar se as propriedades existem antes de chamar toLowerCase()
      return (
        (ticket.title && ticket.title.toLowerCase().includes(searchLower)) || 
        (ticket.description && ticket.description.toLowerCase().includes(searchLower)) ||
        (ticket.requester && ticket.requester.toLowerCase().includes(searchLower)) ||
        (ticket.id && ticket.id.toString().includes(searchLower))
      );
    });
  };
  
  // Obter tickets paginados para o modal
  const getPaginatedTickets = (dayTickets) => {
    // Primeiro aplicar filtro de pesquisa
    const filteredModalTickets = getFilteredModalTickets(dayTickets);
    
    const startIndex = 0;
    const endIndex = modalPagination.page * modalPagination.perPage;
    
    return {
      visibleTickets: filteredModalTickets.slice(startIndex, endIndex),
      hasMore: filteredModalTickets.length > endIndex,
      totalCount: filteredModalTickets.length,
      filteredCount: filteredModalTickets.length,
      totalOriginalCount: dayTickets.length
    };
  };
  
  // Carregar mais tickets no modal
  const handleLoadMore = () => {
    setModalPagination(prev => ({
      ...prev,
      page: prev.page + 1
    }));
  };
  
  // Lidar com pesquisa no modal
  const handleModalSearch = (searchTerm) => {
    setModalSearch(searchTerm);
    setModalPagination({ page: 1, perPage: 10 }); // Reset da paginação quando pesquisar
  };
  
  // Abre o modal com os tickets do dia
  const openDayDetails = (day) => {
    const dayTickets = getTicketsForDate(day);
    setSelectedDay({ day, month: currentMonth + 1, year: currentYear, tickets: dayTickets });
    setModalPagination({ page: 1, perPage: 10 }); // Reset pagination
    setModalSearch(''); // Reset da pesquisa
    setIsModalOpen(true);
  };
  
  // Fechar o modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDay(null);
    setSelectedTicket(null);
    setModalSearch('');
  };
  
  // Mostrar detalhes do ticket
  const showTicketDetails = (ticket) => {
    setSelectedTicket(ticket);
  };
  
  // Voltar para a lista de tickets do dia
  const backToTicketList = () => {
    setSelectedTicket(null);
  };
  
  // Funções para o form de criar/editar ticket
  const openTicketForm = (ticket = null, day = null) => {
    if (ticket) {
      setTicketToEdit(ticket);
    } else if (day) {
      setTicketToEdit({
        id: '',
        title: '',
        description: '',
        requester: '',
        assignedTo: '',
        status: 'Novo',
        priority: 'Média',
        predictedDate: `${formatDateWrapper(day)} 12:00`
      });
    } else {
      setTicketToEdit(null);
    }
    setIsTicketFormOpen(true);
  };
  
  const closeTicketForm = () => {
    setIsTicketFormOpen(false);
    setTicketToEdit(null);
  };
  
  const handleSaveTicket = (ticketData) => {
    if (ticketData.id) {
      // Atualizar ticket existente
      const updatedTickets = tickets.map(t => 
        t.id === ticketData.id ? { ...t, ...ticketData } : t
      );
      setTickets(updatedTickets);
    } else {
      // Criar novo ticket
      const newTicket = {
        ...ticketData,
        id: Math.max(...tickets.map(t => t.id), 0) + 1
      };
      setTickets([...tickets, newTicket]);
    }
    
    closeTicketForm();
  };
  
  // Excluir ticket
  const handleDeleteTicket = (ticketId) => {
    const updatedTickets = tickets.filter(t => t.id !== ticketId);
    setTickets(updatedTickets);
    closeModal();
  };
  
  // Escolher a visualização baseada no estado
  const renderViewContent = () => {
    const viewProps = {
      currentDate,
      currentMonth,
      currentYear,
      weekdayNames,
      monthNames,
      getTicketsForDate,
      getStatusColor,
      getStatusIcon,
      getPriorityColor,
      formatDate: formatDateWrapper
    };
    
    switch(view) {
      case 'week':
        return (
          <WeekView 
            {...viewProps}
            filteredTickets={filteredTickets}
            openTicketForm={openTicketForm}
            showTicketDetails={showTicketDetails}
          />
        );
      case 'day':
        return (
          <DayView 
            {...viewProps}
            openTicketForm={openTicketForm}
            showTicketDetails={showTicketDetails}
          />
        );
      case 'month':
      default:
        return (
          <MonthView 
            {...viewProps}
            getFirstDayOfMonth={getFirstDayOfMonth}
            getDaysInMonth={getDaysInMonth}
            isToday={isTodayWrapper}
            openDayDetails={openDayDetails}
            openTicketForm={openTicketForm}
          />
        );
    }
  };
  
  // Exibe um indicador de carregamento
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Box sx={{ p: isTablet ? 2 : 3, maxWidth: 1400, mx: 'auto' }}>
        <CalendarHeader 
          currentDate={currentDate}
          monthNames={monthNames}
          view={view}
          setView={setView}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
          goToToday={goToToday}
        />
        
        <FilterBar 
          filterOptions={filterOptions}
          handleFilterChange={handleFilterChange}
          openTicketForm={openTicketForm}
        />
        
        {renderViewContent()}
        
        {/* Modal de detalhes do dia */}
        {selectedDay && (
          <DayDetailModal
            open={isModalOpen}
            onClose={closeModal}
            selectedDay={selectedDay}
            selectedTicket={selectedTicket}
            onTicketClick={showTicketDetails}
            onBackToList={backToTicketList}
            paginationInfo={getPaginatedTickets(selectedDay.tickets)}
            onLoadMore={handleLoadMore}
            getStatusColor={getStatusColor}
            getPriorityColor={getPriorityColor}
            onEditTicket={openTicketForm}
            onDeleteTicket={handleDeleteTicket}
            searchTerm={modalSearch}
            onSearchChange={handleModalSearch}
          />
        )}
        
        {/* Modal de criar/editar ticket */}
        <TicketFormModal
          open={isTicketFormOpen}
          onClose={closeTicketForm}
          onSave={handleSaveTicket}
          ticketData={ticketToEdit}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default TicketCalendar;