import { AttachFile, Delete, DeleteForever } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Styles } from '../../../styles/material-styles';
import { PapiroConsole } from '../../../utils/papiroConsole';
import SimpleAutoComplete from 'src/components/auto-complete/autocomplete-dialog-with-z-index';
import Dropzone from 'react-dropzone';
import * as FileService from '../../../services/attachment.service';

const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  margin: 20px auto;
`;

const StyledTable = styled(Table)`
  min-width: 650px;
  background-color: #ffffff;
`;

const StyledTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

const StyledTableBodyCell = styled(TableCell)`
  && {
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledSimpleAutoComplete = styled(SimpleAutoComplete)`
  width: 100%;
`;

const StyledDropZone = styled(Dropzone)`
  width: 100%;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${props => props.delete ? '#f44336' : '#687EFC'};
    &:hover {
      color: ${props => props.delete ? '#f44336' : '#3f51b4'};
    }
  }
`;

function EditableTableBlocks(props) {

    const { rows, intl, options, setLoading, setNotification, classes, typeOptions, setDeleteImageDialog, setIdToDeleteImage } = props

    PapiroConsole.log("EditableTableBlocks rows")
    PapiroConsole.log(rows)

    const handleInputChange = (e, id, field) => {

        let value

        if (field === "type") {
            value = e
        }
        else if (field === "relation") {
            const row = rows.find(r => r.id == id)
            if (row.type.id == 1 || row.type.id == 2) {
                value = e
            } else if (row.type.id == 3) {
                value = e.target.value
            }
        } else {
            value = e.target.value
        }

        props.handleInputChangeTable(field, value, id)
    }

    const handleSaveRow = row => {
        props.handleSaveRow(row)
    }

    const handleDeleteRow = row => {
        props.handleDeleteRow(row);
    }

    const validateImagesAtachments = (files, inputName, id) => {

        if (inputName === 'blockimagefiles') {
            if (files.length > 1) {
                props.handleInputChangeTable('blockimagefiles', [], id)
                setNotification(true, 'error', intl.formatMessage({ id: "checklist.modal.select.image.error" }))
                return
            }
            else {
                props.handleInputChangeTable('blockimagefiles', files, id)
            }
        }
    }

    const getFile = async (hirerid, fileName, filerepositorymethod) => {
        setLoading(true)

        let url = await FileService.getFileByPath(hirerid, fileName, filerepositorymethod);
        if (url && url.data && url.data.data && url.data.success) {
            setLoading(false)
            window.open(`${url.data.data}`, '_blank')
        } else {
            setLoading(false)
            setNotification(true, 'error', intl.formatMessage({ id: "file.not.found" }))
        }
    }

    const getFileName = (fileName) => {

        let file = fileName != null ? fileName.substring(fileName.lastIndexOf('/') + 1) : "";
        return file;
    }

    const removeItem = (file, inputName, item) => {
        if (inputName === 'blockimagefiles') {
            let array = [...item.blockimagefiles]
            let index = array.indexOf(file)
            if (index !== -1) {
                array.splice(index, 1)
                props.handleInputChangeTable('blockimagefiles', array, item.id)
            }
        }
    }

    return (
        <StyledTableContainer component={Paper}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>{intl.formatMessage({ id: "title" })}</StyledTableCell>
                        <StyledTableCell>{intl.formatMessage({ id: "image" })}</StyledTableCell>
                        <StyledTableCell>{intl.formatMessage({ id: "type" })}</StyledTableCell>
                        <StyledTableCell>{intl.formatMessage({ id: "targeting" })}</StyledTableCell>
                        <StyledTableCell>{intl.formatMessage({ id: "save" })}</StyledTableCell>
                        <StyledTableCell>{intl.formatMessage({ id: "delete" })}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.id}>
                            <StyledTableBodyCell>
                                <StyledTextField
                                    value={row.title}
                                    onChange={(e) => handleInputChange(e, row.id, 'title')}
                                />
                            </StyledTableBodyCell>
                            <StyledTableBodyCell>
                                <div className={classes.dropzoneArea} >
                                    <StyledDropZone
                                        onDrop={acceptedFiles => validateImagesAtachments(acceptedFiles, "blockimagefiles", row.id)}
                                    //disabled={row.id === 0}
                                    >
                                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p>{<FormattedMessage id={"drag.and.drop"} />}</p>
                                            </div>
                                        )}
                                    </StyledDropZone>
                                    {row.blockimagefiles.map(file => {
                                        return (
                                            <div className={classes.dropzoneItem} key={file.name}>
                                                {file.name}
                                                <DeleteForever className={classes.deleteItem} onClick={() => { removeItem(file, "blockimagefiles", row) }} />
                                            </div>
                                        )
                                    })}
                                </div>
                                {row.image != null && row.image != "null" && row.image != "undefined" && (
                                    <Grid item xs={12}>
                                        <ul>
                                            <li key={row.image.path}>
                                                <Grid container alignItems='center'>
                                                    <Grid item sm={.5}>
                                                        <AttachFile />
                                                    </Grid>
                                                    <Grid item sm={10.5}>
                                                        <Button onClick={() => getFile(row.image.hirerid, row.image.path, row.image.filerepositorymethod)}>
                                                            {getFileName(row.image.path)}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item sm={1}>
                                                        <Button color='inherit' onClick={_ => {
                                                            setDeleteImageDialog(true)
                                                            setIdToDeleteImage(row.id)
                                                        }}>
                                                            <Delete />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </li>
                                        </ul>
                                    </Grid>
                                )}
                            </StyledTableBodyCell>
                            <StyledTableBodyCell>
                                <StyledSimpleAutoComplete
                                    label={intl.formatMessage({ id: "type" })}
                                    stateName='type'
                                    selected={row.type}
                                    options={typeOptions}
                                    changeSelect={(stateName, value) => { handleInputChange(value, row.id, stateName) }}
                                />
                            </StyledTableBodyCell>
                            {
                                row.type.id === 0
                                &&
                                <StyledTableBodyCell>
                                    <Typography>Selecione um tipo</Typography>
                                </StyledTableBodyCell>
                            }
                            {
                                row.type.id === 1
                                && <StyledTableBodyCell>
                                    <StyledSimpleAutoComplete
                                        label={intl.formatMessage({ id: "knowledgebase" })}
                                        stateName='relation'
                                        selected={row.relation}
                                        options={options}
                                        changeSelect={(stateName, value) => { handleInputChange(value, row.id, stateName) }}
                                    />
                                </StyledTableBodyCell>
                            }
                            {
                                row.type.id === 2
                                && <StyledTableBodyCell>
                                    <StyledSimpleAutoComplete
                                        label={intl.formatMessage({ id: "article" })}
                                        stateName='relation'
                                        selected={row.relation}
                                        options={options}
                                        changeSelect={(stateName, value) => { handleInputChange(value, row.id, stateName) }}
                                    />
                                </StyledTableBodyCell>
                            }
                            {
                                row.type.id === 3
                                && <StyledTableBodyCell>
                                    <StyledTextField
                                        value={row.relation}
                                        onChange={(e) => handleInputChange(e, row.id, 'relation')}
                                    />
                                </StyledTableBodyCell>
                            }
                            <StyledTableBodyCell>
                                {row.id === 0 ? (
                                    <StyledIconButton onClick={() => handleSaveRow(row)}>
                                        <SaveIcon />
                                    </StyledIconButton>
                                ) : (
                                    <StyledIconButton onClick={() => handleSaveRow(row)}>
                                        <SaveIcon />
                                    </StyledIconButton>
                                )}
                            </StyledTableBodyCell>
                            <StyledTableBodyCell>
                                <StyledIconButton delete disabled={row.id === 0} onClick={() => handleDeleteRow(row)}>
                                    <Delete />
                                </StyledIconButton>
                            </StyledTableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </StyledTableContainer>
    )
}

export default injectIntl(withStyles(Styles)(EditableTableBlocks))