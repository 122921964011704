import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import SimpleHelp from "src/components/help";
import SimpleSelect from '../../../components/select/simple';
import SimpleCheckBox from "src/components/checkbox/check";
import { Gr } from "react-flags-select";
import SimpleAutoComplete from "src/components/auto-complete/autocomplete-dialog-with-z-index";

const MailReceiving = props => {
    const { intl, item, handleInputChange, changeValues, OrgsList } = props

    return (
        <Grid container spacing={2} marginTop={2} alignItems="center">
            <Grid item xs={11} sm={11}>
                <SimpleCheckBox label={intl.formatMessage({ id: 'active' })} name='active' stateName='active' changeSelect={changeValues} selected={item.active} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleCheckBox label={intl.formatMessage({ id: 'ResponseEmailIsNewCalled' })} name='responseemailisnewcalled' stateName='responseemailisnewcalled' changeSelect={changeValues} selected={item.responseemailisnewcalled} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.email.reply.new.called" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleCheckBox label={intl.formatMessage({ id: 'AccompanimentCalledForResponseEmail' })} name='accompanimentcalledforresponseemail' stateName='accompanimentcalledforresponseemail' changeSelect={changeValues} selected={item.accompanimentcalledforresponseemail} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.email.reply.new.accompaniment" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={intl.formatMessage({ id: 'server' })} autoComplete='fname' variant='outlined' name='serverReceive' onChange={handleInputChange} value={item.serverReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.imap.receiving.server" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label="E-mail" autoComplete='fname' variant='outlined' name='fromMailReceive' onChange={handleInputChange} value={item.fromMailReceive} type="email" />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "from.mail.receive" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={intl.formatMessage({ id: 'port' })} autoComplete='fname' variant='outlined' name='portReceive' onChange={handleInputChange} value={item.portReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.imap.receiving.port" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleSelect options={props.CryptoList} label={intl.formatMessage({ id: 'crypto' })} name='cryptotypeReceive' stateName='cryptotypeReceive' changeSelect={changeValues} selected={item.cryptotypeReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.imap.receiving.encryption" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={intl.formatMessage({ id: 'user' })} autoComplete='fname' variant='outlined' name='userReceive' onChange={handleInputChange} value={item.userReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.imap.user" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField type="password" fullWidth label={intl.formatMessage({ id: 'password' })} autoComplete='fname' variant='outlined' name='passwordReceive' onChange={handleInputChange} value={item.passwordReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.imap.password" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleSelect options={props.CloudTypeList} label={intl.formatMessage({ id: 'cloud.type' })} name='cloudTypeReceive' stateName='cloudTypeReceive' changeSelect={changeValues} selected={item.cloudTypeReceive} disabled />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "cloud.type.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleSelect options={props.AuthTypeList} label={intl.formatMessage({ id: 'auth.type' })} name='authTypeReceive' stateName='authTypeReceive' changeSelect={changeValues} selected={item.authTypeReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "auth.type.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Client Id"} autoComplete='fname' variant='outlined' name='clientIdReceive' onChange={handleInputChange} value={item.clientIdReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "client.id.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Client Secret"} autoComplete='fname' variant='outlined' name='clientSecretReceive' onChange={handleInputChange} value={item.clientSecretReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "client.secret.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Redirect Uris"} autoComplete='fname' variant='outlined' name='redirectUrisReceive' onChange={handleInputChange} value={item.redirectUrisReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "redirect.uris.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Tenant Id"} autoComplete='fname' variant='outlined' name='tenantIdReceive' onChange={handleInputChange} value={item.tenantIdReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "tenant.id.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Project Id"} autoComplete='fname' variant='outlined' name='projectIdReceive' onChange={handleInputChange} value={item.projectIdReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "project.id.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={'Token Uri'} autoComplete='fname' variant='outlined' name='tokenUriReceive' onChange={handleInputChange} value={item.tokenUriReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "token.uri.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={'Auth Uri'} autoComplete='fname' variant='outlined' name='authUriReceive' onChange={handleInputChange} value={item.authUriReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "auth.uri.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={'Auth Provider X509 Cert Url'} autoComplete='fname' variant='outlined' name='authProviderX509CertUrlReceive' onChange={handleInputChange} value={item.authProviderX509CertUrlReceive} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "auth.provider.x509.cert.url.imap" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleAutoComplete label={intl.formatMessage({ id: "menu.organization" })} stateName="organization" selected={item.organization} changeSelect={changeValues} options={OrgsList} />
            </Grid>
            <Grid item xs={4} sm={4}>
                <Button
                    onClick={props.testReceivedMail}
                    fullWidth
                    variant='contained'
                    color='primary'
                >
                    {intl.formatMessage({ id: "test" })}
                </Button>
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.test.message.box.info" })} />
            </Grid>
        </Grid>
    )
}

export default MailReceiving