import { makeStyles, withStyles } from "@mui/styles"
import React, { useEffect, useState } from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { Styles } from "src/styles/material-styles"
import PropTypes from "prop-types"
import { Check } from "@mui/icons-material"
import { useHistory } from 'react-router-dom'
import { PapiroConsole } from "src/utils/papiroConsole"
import { Button, CircularProgress, Container, Divider, MobileStepper, Step, StepButton, Stepper, Typography } from "@mui/material"
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Overlay } from "src/styles/global"
import CustomizedSnackbars from "src/components/material-snackbars"
import Footer from "src/components/footer"
import { bindActionCreators } from "redux"
import * as calledsActions from '../../../redux/actions/calleds';
import "./wizard.css";
import AppearanceStep from "./appearance-step"
import BlocksStep from "./blocks-step"
import ArticlesStep from "./articles-step"
import CreatecalledStep from "./createcalled-step"
import FooterStep from "./footer-step"


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    progressBar: {
        // maxWidth: 800,
        //flexGrow: 2,
    },
}));

const progressBar = makeStyles((theme) => ({
    root: {
        maxWidth: 450,
        flexGrow: 1,
    },
}));

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

var arrayStespCompletedes = []
var clientPortalId = 0
var checkMenu = false
var headerConditional = null

var saveId = undefined



function QontoStepIcon(props) {

    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

function getSteps(intl) {
    //return ['Aparência', 'Blocos', 'Artigos', 'Criar chamado','Rodapé'];
    return [intl.formatMessage({ id: "appearance" }), intl.formatMessage({ id: "blocks" }), intl.formatMessage({ id: "articles" }), intl.formatMessage({ id: "called.creation" }), intl.formatMessage({ id: "footer" })];
}

function getStepContent(step, intl) {
    switch (step) {
        case 0:
            return intl.formatMessage({ id: "tutorial.progress.steps.config.organization" })
        case 1:
            return intl.formatMessage({ id: "tutorial.progress.steps.register.address" })
        case 2:
            return intl.formatMessage({ id: "tutorial.progress.steps.create.slas" })
        case 3:
            return intl.formatMessage({ id: "tutorial.progress.steps.associate.categories" })
        case 4:
            return intl.formatMessage({ id: "wizard.add.departaments.to.organization" })
        default:
            return intl.formatMessage({ id: "tutorial.progress.steps.complete" })
    }
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
}

function Wizard(props) {

    const classed = useStyles();
    const progressBars = progressBar();
    const [activeStep, setActiveStep] = useState(0);
    const [activeProgress, setActiveProgress] = useState(0);
    const [completed, setCompleted] = useState({});
    const [resetArray, setResetArray] = useState(false)
    const [data, setData] = useState({ data: false });

    const intl = props.intl;

    const steps = getSteps(intl);
    const history = useHistory()
    var completedTask = 0;

    const { classes, userSession, headerMenu, actionsHeaderMenu, calleds, clientPortal } = props;

    PapiroConsole.log("props aqui do wizard")
    PapiroConsole.log(props)

    const setNotification = (notification) => {
        props.setNotification(notification)
    }

    const setLoading = (loading) => {
        props.setLoading(loading)
    }

    const setClientPortal = (item) => {
        props.setClientPortal(item)
    }

    const closeNotification = () => {
        setNotification({ openNotification: false, notificationVariant: 'success', notificationMessage: intl.formatMessage({ id: "edit.success" }) })
    }

    const getContent = (step, clientPortal, handleBack, handleNext, handleReset) => {
        PapiroConsole.log("Wizard getContent")
        switch (step) {
            case 0:
                return (
                    <AppearanceStep
                        handleNextBarProgress={handleNextBarProgress}
                        handleComplete={handleComplete}
                        clientPortal={clientPortal}
                        back={handleBack}
                        next={handleNext}
                        setNotification={setNotification}
                        setLoading={setLoading}
                        setClientPortal={setClientPortal}
                        backButton={
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                {intl.formatMessage({ id: "back" })}
                            </Button>
                        }
                        nextButton={
                            <Button
                                onClick={handleNext}
                                className={classes.button}
                                style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
                            >
                                {intl.formatMessage({ id: "next" })}
                            </Button>
                        }
                    />
                )
            case 1:
                return (
                    <BlocksStep
                        handleNextBarProgress={handleNextBarProgress}
                        handleComplete={handleComplete}
                        clientPortal={clientPortal}
                        back={handleBack}
                        next={handleNext}
                        setNotification={setNotification}
                        setLoading={setLoading}
                        setClientPortal={setClientPortal}
                        backButton={
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                {intl.formatMessage({ id: "back" })}
                            </Button>
                        }
                        nextButton={
                            <Button
                                onClick={handleNext}
                                className={classes.button}
                                style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
                            >
                                {intl.formatMessage({ id: "next" })}
                            </Button>
                        }
                    />
                )
            case 2:
                return (
                    <ArticlesStep
                        handleNextBarProgress={handleNextBarProgress}
                        handleComplete={handleComplete}
                        clientPortal={clientPortal}
                        back={handleBack}
                        next={handleNext}
                        setNotification={setNotification}
                        setLoading={setLoading}
                        setClientPortal={setClientPortal}
                        backButton={
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                {intl.formatMessage({ id: "back" })}
                            </Button>
                        }
                        nextButton={
                            <Button
                                onClick={handleNext}
                                className={classes.button}
                                style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
                            >
                                {intl.formatMessage({ id: "next" })}
                            </Button>
                        }
                    />
                )
            case 3:
                return (
                    <CreatecalledStep
                        handleNextBarProgress={handleNextBarProgress}
                        handleComplete={handleComplete}
                        clientPortal={clientPortal}
                        back={handleBack}
                        next={handleNext}
                        setNotification={setNotification}
                        setLoading={setLoading}
                        setClientPortal={setClientPortal}
                        backButton={
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                {intl.formatMessage({ id: "back" })}
                            </Button>
                        }
                        nextButton={
                            <Button
                                onClick={handleNext}
                                className={classes.button}
                                style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
                            >
                                {intl.formatMessage({ id: "next" })}
                            </Button>
                        }
                    />
                )
            case 4:
                return (
                    <FooterStep
                        handleNextBarProgress={handleNextBarProgress}
                        handleComplete={handleComplete}
                        clientPortal={clientPortal}
                        back={handleBack}
                        next={handleNext}
                        setNotification={setNotification}
                        setLoading={setLoading}
                        setClientPortal={setClientPortal}
                        backButton={
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                {intl.formatMessage({ id: "back" })}
                            </Button>
                        }
                        nextButton={
                            <Button
                                onClick={handleNext}
                                className={classes.button}
                                style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
                            >
                                {intl.formatMessage({ id: "next" })}
                            </Button>
                        }
                    />
                )
            default:
                return <div>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link} {...{ to: `/pt/calleds` }}
                    >
                        {intl.formatMessage({ id: "end" })}
                    </Button>
                </div>;
        }
    }

    const totalSteps = () => {
        return steps.length;
    }

    const completedSteps = () => {
        return Object.keys(completed).length;
    }

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    }

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    }

    const handleNext = () => {
        PapiroConsole.log("handleNext")
        const newActiveStep = isLastStep() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1
        PapiroConsole.log(newActiveStep)
        setActiveStep(newActiveStep)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleNextBarProgress = () => {
        setActiveProgress((prevActiveStep) => prevActiveStep + 1)
        completedTask = completedTask + 1
    }

    const handleStep = async (step) => {
        setActiveStep(step)
    }

    const handleFinish = () => {
        setActiveStep(5);
    }

    const handleReset = () => {
        history.push({
            pathname: `/pt/calleds`,
        })
    }

    const checkStyle = (i) => {

        let arraystepcomplete = []

        arrayStespCompletedes.forEach(element => {
            if (element === i) {
                arraystepcomplete.push(i)
            }
        })

        if (arraystepcomplete.length > 0) {
            return "classeteste"
        }
        else {
            return ""
        }
    }

    const componentHeader = () => {

        if (checkMenu === true) {
            return (
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                />
            )
        }
        else {
            return (
                <main
                    className={clsx(classes.content)}
                />
            )
        }
    }

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;

        arrayStespCompletedes.push(activeStep)

        setCompleted(newCompleted);
        PapiroConsole.log("setnotification")
        setNotification({ openNotification: true, notificationVariant: 'success', notificationMessage: intl.formatMessage({ id: "edit.success" }) })
        handleNext();
    }

    return (

        <div className={classes.form}>
            <div>
                <h2 className="h4-principal">{clientPortal.id} | {clientPortal.title}</h2>
                <h4 className="h4-title"> {intl.formatMessage({ id: "wizard.client.portal.info" })} </h4>
                <h4 className="h4-subtitle"> {intl.formatMessage({ id: "wizard.client.portal.config" })} </h4>
                <MobileStepper
                    variant="progress"
                    steps={5}
                    position="static"
                    activeStep={activeProgress}
                    className={progressBars.root}
                    nextButton={
                        <Button size="small" disabled>
                            <span style={{ color: "black" }}> {intl.formatMessage({ id: "wizard.organization.operation.completed" })} {activeProgress} {intl.formatMessage({ id: "of" })} 5</span>
                        </Button>
                    }
                />
                <Stepper nonLinear activeStep={activeStep} >
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton onClick={handleStep.bind(null, index)} completed={completed[index]}>
                                <div className={checkStyle(index)}>
                                    {label}
                                </div>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {allStepsCompleted() ? (
                        <div>
                            <Typography className={classed.instructions}>
                                {intl.formatMessage({ id: "wizard.organization.config.setup.completed.view.calleds" })}
                            </Typography>
                            <Button onClick={handleReset} variant="outlined" color="primary">Listagem de chamados</Button>
                        </div>
                    ) : (
                        <div>

                        </div>
                    )}
                    <div>
                        <Typography style={{ marginLeft: "30px", }} className={classed.instructions}>
                            {getStepContent(activeStep, intl)}
                        </Typography>

                        {getContent(activeStep, props.clientPortal, handleBack, handleNext)}

                        <div>
                            {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                    <Typography variant="caption" className={classed.completed}>

                                    </Typography>
                                ) : (
                                    <div>

                                    </div>
                                ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

Wizard.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    history: PropTypes.object.isRequired,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Wizard)));