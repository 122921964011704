import { Autocomplete, Button, Chip, CircularProgress, Container, Grid, TextField } from "@mui/material";
import { withStyles } from '@mui/styles';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from '../../../../config/ability-context';
import * as componentActions from '../../../../redux/actions/calledflows';
import * as WebchatOriginService from "../../../../services/2talk/webchat-origin.service.js";
import Api from "../../../../services/api";
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';

class WebchatOrigin extends Component {

  constructor(props) {
    super(props);

    const { userSession, webchats } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.itemInitialState = {
      webchatid: webchats?.webchat?.id,
      origins: []
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      optionsMessageProviders: [],
    };

    this.closeNotification = this.closeNotification.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    this.setState({ loading: true });

    try {
      if (this.state.item.webchatid) {
        const output = await WebchatOriginService.getAllWebchatOrigin(this.state.item.webchatid);

        if (output.success) {
          this.setState({
            item: {
              ...this.state.item,
              origins: output?.data
            },
          });
        } else {
          this.fetchDataNotSuccess(output);
          Api.kickoff(output);
        }
      }
    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });
    
    try {
      if (this?.state?.item?.webchatid != null) {
        let input = {
          webchatid: this?.state?.item?.webchatid,
          origins: this.state.item.origins
        }

        const output = await WebchatOriginService.postWebchatOriginSave(input);

        if (output.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Origens atualizada com sucesso',
          });
        } else {
          this.fetchDataNotSuccess(output);
          Api.kickoff(output);
        }
      } 
    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { classes } = this.props;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div style={{ paddingTop: '10px' }}>
          <Container component='main' maxWidth='md'>
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={[]}
                        freeSolo
                        value={this.state.item.origins}
                        onChange={(event, newValue, reason) => {
                            this.setState(prevState => ({
                                item: {
                                    ...prevState.item,
                                    origins: newValue
                                }
                            }));
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
                            })
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="filled" label="Origens" />
                        )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  webchats: state.webchats
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(WebchatOrigin)));

WebchatOrigin.contextType = AbilityContext;