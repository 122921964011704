import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../redux/actions/chats'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles-omnichannel'
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base-omnichannel'
import ToTalkOmniChannelObserveContent from './index.content'
import { injectIntl } from 'react-intl';
import { getPhoneNumberListFromChannel as getPhoneNumberListService } from '../../services/2talk/registrationphonenumber';
import {
  getProviderList as getProviderListService, getStatusList as getStatusListService, getChatClosingStatus
  , getChannelFromTechinicalTeam as getChannelFromTechinicalTeamListService
  , getTechinicalTeamFromUser as getTechinicalTeamListService
  , sendMessage as sendMessageService, reSendMessage as reSendMessageService, sendMessageAttachment, GetChatList as GetChatListService, GetChatListByTech as GetChatListByTechService, getTechList
} from '../../services/2talk/omnichannel';
import Api from '../../services/api';
import ChatUserDetailsSidebar from './components/chat/chat-user-details-sidebar'
import { PapiroConsole } from '../../utils/papiroConsole'
import Api2Talk from "../../services/api2talk";
import CustomizedSnackbars from '../../components/material-snackbars'
import { Overlay } from '../../styles/global'
import { CircularProgress } from '@mui/material'
import { now } from 'moment'

class ToTalkOmniChannelObserve extends Component {

  constructor(props) {
    super(props)
    const { userSession } = this.props;
    this.globalAdm = false;

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    const intl = this.props.intl;

    this.state = {
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      search: '',
      item: {
        statusAttendance: 1,
        phoneNumber: "",
        phoneSender: ""
      },

      userSession: userSession,
      chatId : null,
      statusAttendance: [{ id: 1, name: 'Em andamento' }],
      techinicalTeamList: [],
      phoneNumberList: [],
      channelList: [],
      providerList: [],
      statusList: [],
      chatMessages: [],
      customerList: [],
      providerid: 0,
      chatstatusid: 0,
      techinicalteamid: 0,
      channelid: 0,
      title: '',
      phonenumberid: 0,
      loadingcustomerid: false,
      customerid: 0,
      DialogRemoveState: false,
      DialogAddNoteState: false,
      DialogEndChatState: false,
      DialogTransferChatState: false,
      DialogSyncDataState: false,
      newStatusValue: 0,
      newStatusValueWaitingConfirm: 0,
      automaticallyCreateCalledAfterEndingChat: this.props.userSession && this.props.userSession.user && this.props.userSession.user.automaticallycreatecalledafterendingchat ? this.props.userSession.user.automaticallycreatecalledafterendingchat : false,
      createCalledToChat: this.props.userSession && this.props.userSession.user && this.props.userSession.user.automaticallycreatecalledafterendingchat ? this.props.userSession.user.automaticallycreatecalledafterendingchat : false,
      chatClosingStatus: [],
      chatClosingStatusId: { id: 0, name: '' },
      techList: [],
      techId: { id: 0, name: '' },
      notes: {
        files: [],
        noteDescription: ''
      }


    };
    this.getTechinicalTeamListList = this.getTechinicalTeamListList.bind(this)
    this.getPhoneNumberList = this.getPhoneNumberList.bind(this)
    this.getChannelFromTechinicalTeamList = this.getChannelFromTechinicalTeamList.bind(this)
    this.getProviderList = this.getProviderList.bind(this)
    this.getStatusList = this.getStatusList.bind(this)
    this.getChatClosingStatus = this.getChatClosingStatus.bind(this);
    this.getChatList = this.getChatList.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.reSendMessage = this.reSendMessage.bind(this)
    this.changeValues = this.changeValues.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleTransferChatConfirmation = this.handleTransferChatConfirmation.bind(this);
    this.validateAtachments = this.validateAtachments.bind(this);
    this.removeItem = this.removeItem.bind();
    this.getChatsByTechWithFilters = this.getChatsByTechWithFilters.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    if(this.props &&  this.props.match && this.props.match.params ){
      var { id } = this.props.match.params
      this.setState({chatId : id})
      this.getTechinicalTeamListList(this.state.userSession.user.id);
      this.getProviderList();
      this.getStatusList();
      this.getChatClosingStatus();
    }
   

  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  getTechinicalTeamListList = async (id) => {
    this.setState({ loading: true });
    let result = await getTechinicalTeamListService(id)
    if (result && result.success) {
      //console.error(result.data);
      this.setState({
        loading: false,
        techinicalTeamList: result.data
      });
    } else {
      this.setState({ loading: false });
      Api.kickoff(result);
    }
  }

  getTechList = async () => {

    PapiroConsole.log(this.props)
    //validação de parâmetros para pegar a lista de técnicos que poderá ser feita a transferência de chat (chat,time,canal)
    if (this.props.chats && this.props.chats.chat && this.props.chats.chat.id && this.props.chats.chat.channelid && this.props.chats.chat.techinicalteamid) {
      let result = await getTechList(this.props.chats.chat.id, this.props.chats.chat.channelid, this.props.chats.chat.techinicalteamid)
      if (result && result.success) {
        let actualAssignedUserId = this.props.chats &&  this.props.chats.chat ? this.props.chats.chat.assigneduserid : null
        let list = result.data.filter(c => c.id != actualAssignedUserId)
        this.setState({
          loading: false,
          techList: list
        });
      } else {
        this.setState({ loading: false });
        Api.kickoff(result);
      }
    }
    else {

    }
    PapiroConsole.log("props para o techlist aqui")


  }
  getChannelFromTechinicalTeamList = async (stateName, value) => {
    this.setState({ channelList: [] });
    if (value && value.id) {
      this.setState({ loading: true });
      let result = await getChannelFromTechinicalTeamListService(value.id)
      if (result && result.success) {
        //console.error(result.data);
        this.setState({
          loading: false,
          channelList: result.data
        });
      } else {
        this.setState({ loading: false });
        Api.kickoff(result);
      }
    }
  }
  //getPhoneNumberList = async _ => {
  getPhoneNumberList = async (stateName, value) => {
    this.setState({ phoneNumberList: [] });
    if (value && value.id) {
      this.setState({ loading: true });
      let result = await getPhoneNumberListService(value.id);
      if (result && result.success) {
        //console.error(result.data);
        this.setState({
          loading: false,
          phoneNumberList: result.data
        });

      } else {
        this.setState({ loading: false });
        Api.kickoff(result);
      }
    }
  }

  getProviderList = async _ => {
    this.setState({ loading: true });
    let result = await getProviderListService()
    if (result && result.success) {
      //console.error(result.data);
      this.setState({
        loading: false,
        providerList: result.data
      });
    } else {
      this.setState({ loading: false });
      Api.kickoff(result);
    }
  }


  getStatusList = async _ => {
    this.setState({ loading: true });
    let result = await getStatusListService()
    if (result && result.success) {
      let statusList = [];
      if (result.data) {
        statusList = result.data.filter(item => item.id !== 1)
      }

      this.setState({
        loading: false,
        statusList: statusList
      });
    } else {
      this.setState({ loading: false });
      Api.kickoff(result);
    }
  }

  getChatClosingStatus = async _ => {
    this.setState({ loading: true });
    let result = await getChatClosingStatus()
    if (result && result.success) {
      //console.error(result.data);
      this.setState({
        loading: false,
        chatClosingStatus: result.data
      });
    } else {
      this.setState({ loading: false });
      Api.kickoff(result);
    }
  }
  getChatList = async _ => {
    this.setState({ loading: true });
    let result = await GetChatListService()
    if (result && result.success) {
      //console.error(result.data);
      this.setState({
        loading: false,
      });
    } else {
      this.setState({ loading: false });
      Api.kickoff(result);
    }
  }

  async getChatsByTechWithFilters(stateName, value) {

    PapiroConsole.log("entrei aqui")
    PapiroConsole.log(this.state.title)

    this.setState({
      loading: true,
    });
    let filters = {
      title: this.state.title ? this.state.title : '',
      status: this.state.chatstatusid ? this.state.chatstatusid.id : null,
      techinicalteam: this.state.techinicalteamid ? this.state.techinicalteamid.id : null,
      channel: this.state.channelid ? this.state.channelid.id : null,
      origin: this.state.phonenumberid ? this.state.phonenumberid : null
    }
    if (stateName == "chatstatusid") {
      filters.status = value.id
    }
    else if (stateName == "techinicalteamid") {
      filters.techinicalteam = value.id
    }
    else if (stateName == "channelid") {
      filters.channel = value.id
    }
    else if (stateName == "phonenumberid") {
      filters.origin = value.id
    }


    let result = await GetChatListByTechService(null, null, filters)
    if (result && result.success) {
      let chatList = result.data;
      this.props.addCurrentOmnichannelChatList(chatList);
      this.props.addTotalChatCount(chatList.length);
      this.props.addCurrentChat(null)

      this.setState({
        loading: false,
      });

    }
    else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
    }
  }

  sendMessageAttachment = async (msg, chatid, arrayAttachments, guidList, error = false, errorMessage) => {

    this.setState({ loading: true });
    let data = new FormData();
    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    if(error == true){

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: errorMessage
      });

      return;

    }

    PapiroConsole.log(msg)
    PapiroConsole.log("mensagem aqui")
    arrayAttachments.forEach(file => data.append('filesUpload', file));
    guidList.forEach(guid => data.append('GuidList', guid));
    data.append('id', chatid)
    data.append('techinicalteamid', msg.techinicalteamid && msg.techinicalteamid.id ? msg.techinicalteamid : this.state.techinicalteamid)
    data.append('channelid', msg.channelid && msg.channelid.id ? msg.channelid : this.state.channelid)
    data.append('phonenumberid', msg.phonenumberid ? msg.phonenumberid : this.state.phonenumberid)
    data.append('connectionid', this.state.userSession.connectionid)
    PapiroConsole.log("propriedades aqui ")
    PapiroConsole.log(msg.techinicalteamid)
    PapiroConsole.log(this.state.techinicalteamid)
    PapiroConsole.log(msg.channelid)
    PapiroConsole.log(this.state.channelid)
    PapiroConsole.log(this.state.phonenumberid)
    PapiroConsole.log(this.state.userSession.connectionid)
    // data.append('message',msg)

    if (msg.techinicalteamid && msg.techinicalteamid.id) {
      Object.keys(msg.techinicalteamid).forEach(key => {
        data.append(`TechinicalTeamId[${key}]`, msg.techinicalteamid[key]);
      });
    }
    if (msg.channelid && msg.channelid.id) {
      Object.keys(msg.channelid).forEach(key => {
        data.append(`ChannelId[${key}]`, msg.channelid[key]);
      });
    }
    if (msg.phonenumberid && msg.phonenumberid.id) {
      Object.keys(msg.phonenumberid).forEach(key => {
        data.append(`PhoneNumberId[${key}]`, msg.phonenumberid[key]);
      });
    }
    if(msg){
      Object.keys(msg).forEach(key => {
        data.append(`message[${key}]`, msg[key]);
      });
    }

    let result = await sendMessageAttachment(data, config)
    PapiroConsole.log(result)
    PapiroConsole.log("result anexos")
    if (result && result.success) {

      this.setState({ loading: false });

     
     
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.errors && result.errors[0] ? result.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      //Api.kickoff(result);
    }
  }


  sendMessage = async (msg, chatid) => {
    //return false;
    //this.setState({ loading: true });
    //var connectionid = localStorage.getItem('connectionid2talk');
    //alert(JSON.stringify(msg));
    //console.error(this.state.userSession);
    var post = {
      message: msg,
      id: chatid,
      techinicalteamid: msg.techinicalteamid && msg.techinicalteamid.id ? msg.techinicalteamid : this.state.techinicalteamid,
      channelid: msg.channelid && msg.channelid.id ? msg.channelid : this.state.channelid,
      phonenumberid: msg.phonenumberid ? msg.phonenumberid : this.state.phonenumberid,
      connectionid: this.state.userSession.connectionid,
    };
    //console.error(post);
    //alert(JSON.stringify(post));
    let result = await sendMessageService(post)
    if (result && result.success) {
      //console.error(result.data);
      //this.setState({ loading: false, });
    } else {
      this.setState({ loading: false });
      Api.kickoff(result);
    }
  }
  reSendMessage = async (msg, chatid) => {
    var post = {
      message: msg,
      id: chatid,
      techinicalteamid: {},
      channelid: {},
      phonenumberid: {},
      connectionid: this.state.userSession.connectionid,
    };
    //console.error(post);
    //alert(JSON.stringify(post));
    let result = await reSendMessageService(post)
    if (result && result.success) {
      //console.error(result.data);
      //this.setState({ loading: false, });
    } else {
      this.setState({ loading: false });
      Api.kickoff(result);
    }
  }

  async changeValues(stateName, value, text = '') {
    this.setState(prevState => ({
      ...prevState,
      [stateName]: value
    }));

    if (stateName == "chatstatusid") {
      this.getChatsByTechWithFilters(stateName, value)
    }

    if (stateName === "techinicalteamid") {
      this.getChannelFromTechinicalTeamList(stateName, value);
      this.getChatsByTechWithFilters(stateName, value)
    }
    else if (stateName === "channelid") {
      this.getPhoneNumberList(stateName, value);
      this.getChatsByTechWithFilters(stateName, value)
    }
    else if (stateName === "phonenumberid") {
      this.getChatsByTechWithFilters(stateName, value)
    }

    else if (stateName === "customerid") {
      if (value && !isNaN(value))
        this.props.setCurrentChatByCustomerId(value)
      else if (value && value.id && !isNaN(value.id))
        this.props.setCurrentChatByCustomerId(value.id)
    }
    else if (stateName === "statusupdate") {
      if (value != 0) {
        PapiroConsole.log("entrei aqui no select")
        PapiroConsole.log(value)
        // se a opção escolhida for diferente de finalizado, ele vai abrir o  modal de mudança de status, do contrario abre o modal de finalização
        if (value != 4) {
          this.openOrCloseConfirmationModal()
        }
        else {
          this.openOrCloseEndChatModal()
        }

        this.setState({ newStatusValueWaitingConfirm: value })
      }


    }


  }

  handleInputChange = (e) => {

    const name = e.target.name;
    const value = e.target.value;

    PapiroConsole.log(name)
    PapiroConsole.log(value)

    if (name == 'searchChat') {
      this.setState({ title: value })

    }
  }



  handleChangeEditor = (content, delta, source, editor, name) => {

    PapiroConsole.log(content)
    PapiroConsole.log(delta)
    PapiroConsole.log(name)
    PapiroConsole.log(this.state.noteDescription)

    this.setState(prevState => ({
      notes: {
        ...prevState.notes,
        [name]: content || ""
      }
    }));
  };

  handleStatusChangeConfirmation = () => {

    this.changeChatStatusConfirmation(this.state.newStatusValueWaitingConfirm)

  }

  handleAddnoteConfirmation = () => {

    this.setState({ loading: true })

    let data = new FormData();
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    let chatId = this.props.chats && this.props.chats.chat && this.props.chats.chat.id ? this.props.chats.chat.id : 0
    data.append("chatId", chatId);

    for (let key in this.state.notes) {

      if (key == "noteDescription") {
        if (this.state.notes[key]) {
          data.append("Description", this.state.notes[key]);
        }
      }

    }
    PapiroConsole.log(this.state.notes.files)
    PapiroConsole.log("this.state.notes.files")
    this.state.notes.files.forEach(file => data.append('filesUpload', file));

    Api2Talk.post("/chatnotes", data, config)
      .then(result => {

        PapiroConsole.log("resultado enable users")
        PapiroConsole.log(result)
        if (result.data.success) {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Nota adicionada com sucesso',
          });
          if (result.data.data) {
            this.props.updateChatNotes(result.data.data, chatId)
          }
          this.openOrCloseDialogAddNoteModal()

          //this.getChatsByTech();
          //this.props.addCurrentChat(null)

        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
        }
      });



  }

  handleDataSyncConfirmation = () => {

    this.DataSyncConfirmation()

  }
  syncDataValidations = (data) => {
    let result = true;

    for (let element in data) {
      PapiroConsole.log(element)
      PapiroConsole.log(data[element])
      PapiroConsole.log("Dados aqui")
      if (data[element] == null || data[element] == '') {
        if (element == 'name') {


          this.setState({ notificationMessage: 'Campo Nome é obrigatório' })

        }
        else if (element == 'email') {


          this.setState({ notificationMessage: 'Campo E-mail é obrigatório' })

        }
        else if (element == 'phone') {

          this.setState({ notificationMessage: 'Campo Telefone é obrigatório' })
        }

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',

        });

        return false
      }
    };

    return true;


  }

  //checa se qualquer item do objeto é nulo
  generalValidations = (data) => {


    for (let element in data) {
      PapiroConsole.log(element)
      PapiroConsole.log(data[element])
      PapiroConsole.log("Dados aqui")
      if (!data[element]) {

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: 'Erro ao processar a solicitação'

        });

        return false
      }
    };

    return true;


  }
  validateAtachments(files) {

    /*const intl = this.props.intl;
    PapiroConsole.log(files)
    PapiroConsole.log("files")
    if(files.length > 1) {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "É possível adicionar apenas um anexo por nota.",
    })
      return
    }
    else{
      
    }*/
    files.forEach((file, index) => {
      if (!file || file.length == 0) {
        files.splice(index, 1);
      }

    });
    PapiroConsole.log(files)
    PapiroConsole.log("files")

    //this.setState({ files: files })

    this.setState(prevState => ({
      notes: {
        ...prevState.notes,
        files: files
      }
    }));

  }

  removeItem = (file) => {
    let array = [...this.state.notes.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);

      //this.setState({ files: array });

      this.setState(prevState => ({
        notes: {
          ...prevState.notes,
          files: array
        }
      }));
    }
  }

  DataSyncConfirmation = () => {
    const intl = this.props.intl;
    let data = {

      name: (this.props.chats && this.props.chats.chat && this.props.chats.chat.customer && this.props.chats.chat.customer.name) ? this.props.chats.chat.customer.name : "",
      email: (this.props.chats && this.props.chats.chat && this.props.chats.chat.customer && this.props.chats.chat.customer.email) ? this.props.chats.chat.customer.email : "",
      phone: (this.props.chats && this.props.chats.chat && this.props.chats.chat.customer && this.props.chats.chat.customer.phone) ? this.props.chats.chat.customer.phone : ""

    }
    var validation = this.syncDataValidations(data)
    data.id = this.props.chats && this.props.chats.chat && this.props.chats.chat.customer && this.props.chats.chat.customer.id ? this.props.chats.chat.customer.id : 0
    data.chatid = this.props.chats && this.props.chats.chat && this.props.chats.chat.id ? this.props.chats.chat.id : 0



    
    

    if (validation) {

      this.setState({ loading: true })

      Api2Talk.put('customer/syncdata', data)
        .then((result) => {
          if (result.data.success) {
            PapiroConsole.log("result aqui depois do submit")
            PapiroConsole.log(result)
            if (result.data && result.data.data) {

              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: 'Dados sincronizados com Sucesso',
                //newStatusValue: statusId
              });
              this.openOrCloseDialogSyncDataModal()

            }

          } else {

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: 'Erro ao processar a solicitação',
              //newStatusValue: statusId
            });
          }
        })
        .catch((err) => {

          PapiroConsole.log(err)
          PapiroConsole.log("err aqui")
          if (err.response && err.response.data && err.response.data.errors) {
            PapiroConsole.log("err aqui 2")
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });


          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
        });
      PapiroConsole.log(data)
      PapiroConsole.log("data aqui")
    }
  }

  handleEndChatConfirmation = async () => {
    await this.ChatFinalization();
    setTimeout(() => {
      this.redirectToChatList();
    }, 1300);
  }

  redirectToChatList = () => {
    const urlRedirect = "/" + this.props.match.params.lang + `/chat-attendance-list`;
    window.location.href = urlRedirect;
  }

  async handleTransferChatConfirmation() {

    this.setState({ loading: true })
    let userId = this.state.techId ? this.state.techId.id : 0
    let chatId = this.props.chats && this.props.chats.chat && this.props.chats.chat.id ? this.props.chats.chat.id : 0

    if (userId) {

      let data = {
        chatid: chatId,
        userid: userId,
        connectionid :  this.state.userSession.connectionid
      }
      Api2Talk.put("/chat/transferchat", data)
        .then(result => {

          PapiroConsole.log("resultado enable users")
          PapiroConsole.log(result)
          if (result.data.success) {

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: 'Transferência de conversa realizada com sucesso',

            });

            this.openOrCloseTransferChatModal()
            this.getChatsByTech();
            this.props.addCurrentChat(null)

          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });

    }
    else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: 'É necessário preencher o campo "Técnico" para fazer a transferência de atendimento.'

      });

    }



  }


  validations(data) {
    const intl = this.props.intl;
    if (!data.chatclosingstatusid) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({ id: 'end.chat.confirmation.error' })
      });
      return false;
    }

    return true;
  }

  async ChatFinalization() {

    this.setState({ loading: true })
    let chatId = this.props.chats && this.props.chats.chat && this.props.chats.chat.id ? this.props.chats.chat.id : 0

    let data = {
      chatid: this.props.chats && this.props.chats.chat && this.props.chats.chat.id ? this.props.chats.chat.id : 0,
      chatclosingstatusid: this.state.chatClosingStatusId ? this.state.chatClosingStatusId.id : 0,
      createcalledtochat: this.state.createCalledToChat,
      customerid: this.props.chats && this.props.chats.chat && this.props.chats.chat.customer && this.props.chats.chat.customer.id ? this.props.chats.chat.customer.id : 0
    }

    let validations = this.validations(data)
    if (validations) {
      Api2Talk.put("/chat/finalizationstatus", data)
        .then(result => {

          PapiroConsole.log("resultado enable users")
          PapiroConsole.log(result)
          if (result.data.success) {

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: 'Status alterado com sucesso',
              //newStatusValue: statusId
            });

            //status finalizado
            let getStatus = this.state.statusList.find(item => item.id == 4)

            PapiroConsole.log(getStatus)
            PapiroConsole.log("getStatus")

            this.props.updateChatStatusId(getStatus, chatId)

            this.openOrCloseEndChatModal()

            this.getChatsByTech(getStatus);

          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });
    }






  }

  openOrCloseConfirmationModal = () => {

    this.setState({ DialogRemoveState: !this.state.DialogRemoveState })
  }


  openOrCloseDialogAddNoteModal = () => {



    this.setState(prevState => ({
      DialogAddNoteState: !this.state.DialogAddNoteState,
      notes: {
        files: [],
        noteDescription: ''
      }
    }));


    //this.setState({ DialogAddNoteState: !this.state.DialogAddNoteState })
  }



  openOrCloseEndChatModal = () => {
    PapiroConsole.log("entrei aqui")

    this.setState({ DialogEndChatState: !this.state.DialogEndChatState })
  }

  openOrCloseDialogSyncDataModal = () => {
    PapiroConsole.log("entrei aqui")

    this.setState({ DialogSyncDataState: !this.state.DialogSyncDataState })
  }

  openOrCloseTransferChatModal = () => {
    if (this.state.DialogTransferChatState == false) {
      this.getTechList();
    }

    this.setState({ DialogTransferChatState: !this.state.DialogTransferChatState })
  }






  async changeChatStatusConfirmation(statusId) {

    this.setState({ loading: true })
    let chatId = this.props.chats && this.props.chats.chat && this.props.chats.chat.id ? this.props.chats.chat.id : 0
    if (statusId && chatId) {

      Api2Talk.put(`/chat/changestatus/${chatId}/${statusId}`)
        .then(result => {

          PapiroConsole.log("resultado enable users")
          PapiroConsole.log(result)
          if (result.data.success) {

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: 'Status alterado com sucesso',
              //newStatusValue: statusId
            });

            let getStatus = this.state.statusList.find(item => item.id == statusId)

            PapiroConsole.log(getStatus)
            PapiroConsole.log("getStatus")

            this.props.updateChatStatusId(getStatus, chatId)

            this.openOrCloseConfirmationModal()

            this.getChatsByTech(getStatus);


          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });
    }
    else {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'false',
        notificationMessage: 'Ocorreu algum erro na atualização do status do chat'
      });

    }


  }

  async getChatsByTech(status) {
    this.setState({ loading: true })

    let result = await GetChatListByTechService()
    if (result && result.success) {
      let chatList = result.data;
      PapiroConsole.log(result.data)
      PapiroConsole.log("result.data getChatsByTech")
      PapiroConsole.log(chatList.length)
      this.props.addCurrentOmnichannelChatList(chatList);
      this.props.addTotalChatCount(chatList.length);
      PapiroConsole.log(this.props)
      PapiroConsole.log("propriedades aqui")
      //caso os status sejam diferentes de 1 e 2, nenhum chat fica ativo, caso os status continuem sendo 1 ou 2, o atual chat continua ativo
      if (status && status.id != 1 && status.id != 2) {
        this.props.addCurrentChat(null)
      }

      this.setState({ loading: false })

    }
    else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });

    }
  }

  render() {
    PapiroConsole.log(this.props)
    PapiroConsole.log("props atualizada")
    return (
      <Base props={this.props} customStyle={{ maxWidth: '100%', width: '100%', paddingBottom: 0 }} footerFabPosition="vertical">
        <ToTalkOmniChannelObserveContent
          techinicalTeamList={this.state.techinicalTeamList}
          phoneNumberList={this.state.phoneNumberList}
          channelList={this.state.channelList}
          providerList={this.state.providerList}
          statusList={this.state.statusList}
          chatClosingStatus={this.state.chatClosingStatus}
          techList={this.state.techList}
          changeValues={this.changeValues}
          handleInputChange={this.handleInputChange}
          getChatsByTechWithFilters={this.getChatsByTechWithFilters}
          handleChangeEditor={this.handleChangeEditor}
          noteDescription={this.state.notes.noteDescription}
          sendMessage={this.sendMessage}
          reSendMessage={this.reSendMessage}
          load={this.state.loading}
          phonenumberid={this.state.phonenumberid}
          customerid={this.state.customerid}
          loadingcustomerid={this.state.loadingcustomerid}
          customerList={this.state.customerList}
          openOrCloseConfirmationModal={this.openOrCloseConfirmationModal}
          openOrCloseEndChatModal={this.openOrCloseEndChatModal}
          openOrCloseTransferChatModal={this.openOrCloseTransferChatModal}
          openOrCloseDialogSyncDataModal={this.openOrCloseDialogSyncDataModal}
          openOrCloseDialogAddNoteModal={this.openOrCloseDialogAddNoteModal}
          DialogRemoveState={this.state.DialogRemoveState}
          DialogEndChatState={this.state.DialogEndChatState}
          DialogSyncDataState={this.state.DialogSyncDataState}
          DialogTransferChatState={this.state.DialogTransferChatState}
          DialogAddNoteState={this.state.DialogAddNoteState}
          newStatusValue={this.state.newStatusValue}
          handleStatusChangeConfirmation={this.handleStatusChangeConfirmation}
          handleAddnoteConfirmation={this.handleAddnoteConfirmation}
          handleDataSyncConfirmation={this.handleDataSyncConfirmation}
          handleEndChatConfirmation={this.handleEndChatConfirmation}
          handleTransferChatConfirmation={this.handleTransferChatConfirmation}
          notificationType={this.state.notificationVariant}
          messageNotification={this.state.notificationMessage}
          openNotificationMessage={this.state.openNotification}
          automaticallyCreateCalledAfterEndingChat={this.state.automaticallyCreateCalledAfterEndingChat}
          createCalledToChat={this.state.createCalledToChat}
          validateAtachments={this.validateAtachments}
          removeItem={this.removeItem}
          files={this.state.notes.files}
          sendMessageAttachment={this.sendMessageAttachment}
          chatId = {this.state.chatId}
          isGlobalAdm ={this.globalAdm}
        />
        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}
      </Base>
    )
  }
}

ToTalkOmniChannelObserve.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ToTalkOmniChannelObserve)));
ToTalkOmniChannelObserve.contextType = AbilityContext;