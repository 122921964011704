import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CircularProgress, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import { withRouter } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table/index-report';
import { AbilityContext } from '../../config/ability-context'
import { injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/calleds';
import { PapiroConsole } from 'src/utils/papiroConsole';
import moment from 'moment';
import { ContentCopy } from '@mui/icons-material';

class IntegrationKeyApplications extends Component {

    constructor(props) {
        super(props);

        const { userSession, calleds, intl } = this.props;
        const { id } = this.props.match.params

        this.isClient = true;
        this.isGlobalAdm = false;

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.isGlobalAdm = true;
            }
        });

        this.urlId = id

        this.state = {
            calledId: id ? id : calleds.called.id,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            columns: [
                { title: intl.formatMessage({ id: "id" }), field: 'id' },
                { title: intl.formatMessage({ id: "type" }), field: 'integrationtypesname' },
                { title: intl.formatMessage({ id: "external.application.id" }), field: 'applicationid' },
                { title: intl.formatMessage({ id: "date" }), field: 'createdat' },
                { title: intl.formatMessage({ id: "response" }), field: 'response' },
                {
                    title: intl.formatMessage({ id: "request.payload" }),
                    field: 'requestpayload',
                    render: (row) => (
                        <div style={{ display: 'flex' }}>
                            <span style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '12px' }}>
                                {row.requestpayload}
                            </span>
                            <Tooltip title={<h5>{intl.formatMessage({ id: "copy.clipboard" })}</h5>} color="secondary" style={{ cursor: "pointer" }}>
                                <ContentCopy onClick={_ => navigator.clipboard.writeText(row.requestpayload)} />
                            </Tooltip>
                        </div>
                    )
                },
            ],
        };

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        await this.getItens();
    }

    async getItens() {
        this.setState({ loading: true });

        await Api.get(`integrationkeyapplication/calledid/${this.state.calledId}`)

            .then(result => {
                if (result.data.success) {
                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,
                            integrationtypesname: item.integrationtypes ? item.integrationtypes.name : "",
                            createdat: item.createdat ? moment(item.createdat).format('DD/MM/YYYY HH:mm:ss') : null
                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes } = this.props;
        const intl = this.props.intl;

        return (
            <>
                <MyMaterialTable
                    columns={this.state.columns}
                    data={this.state.resultList}
                />

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}
            </>
        );
    }
}
IntegrationKeyApplications.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    userSession: state.userSession,
    calleds: state.calleds,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(IntegrationKeyApplications))));

IntegrationKeyApplications.contextType = AbilityContext;            