import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types'
import MyMaterialTable from '../../../components/table';
import { PapiroConsole } from "src/utils/papiroConsole";
import * as ClientPortalService from "../../../services/clientportal.service"

class ClientPortalTab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            clientPortalList: []
        }
        this.handleItemListClick = this.handleItemListClick.bind(this)
    }

    async componentDidMount() {
        const { configId, setLoading, setMessage, intl } = this.props
        setLoading(true)

        const result = await ClientPortalService.getByConfigId(configId)

        if (result && result.success == true) {
            this.setState({ clientPortalList: [result.data] })
            setLoading(false)
        } else {
            const error = result && result.errors && result.errors[0] ? result.errors[0] : intl.formatMessage({ id: "process.error" })
            setLoading(false)
            setMessage(true, 'error', error)
        }
    }

    handleItemListClick = (item) => {
        PapiroConsole.log(item)
    }

    render() {
        const { intl } = this.props
        return (
            <div>
                <MyMaterialTable
                    title={intl.formatMessage({ id: 'mail.box.config.list' })}
                    rowClick={(event, rowData) => {
                        this.handleItemListClick(rowData)
                    }}
                    columns={[
                        { title: intl.formatMessage({ id: "id" }), field: 'id' },
                        { title: intl.formatMessage({ id: "title" }), field: 'title' },
                    ]}
                    data={this.state.clientPortalList}
                />
            </div>
        )
    }
}

ClientPortalTab.prototypes = {
    configId: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
}

export default connect()(ClientPortalTab)