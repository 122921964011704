import { AttachFile, DeleteForever, QueuePlayNext } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  ImageList,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import DialogGeneral from "src/components/dialog-general";
import Footer from "../../components/footer";
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars";
import Title from "../../components/title-name";
import { browserHistory } from "../../helpers/history";
import Api from "../../services/api";
import { getFileDownloadLink } from "../../services/attachment.service";
import { Overlay } from "../../styles/global";
import { Styles } from "../../styles/material-styles";
import { PapiroConsole } from "src/utils/papiroConsole";
import * as componentActions from "../../redux/actions/articles";
import articles from "src/redux/reducers/articles";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import SimpleCheckBox from "src/components/checkbox/check";

class ArticlesEdit extends Component {
  constructor(props) {
    super(props);

    const { userSession, articles } = this.props;

    PapiroConsole.log("Dados de articles");
    PapiroConsole.log(articles);
    PapiroConsole.log(props);

    this.globalAdm = false;
    this.isClient = true;
    if (
      userSession == null ||
      userSession.user == null ||
      userSession.user.userrole == null
    ) {
      Api.logoff();
    }

    this.initialState = {
      id: articles.article?.id,
      title: articles.article?.title,
      subtitle: articles.article?.subtitle,
      readingtime: articles.article?.readingtime,
      sectionid: articles.article?.sectionid,
      content: articles.article?.content,
      promote: articles.article?.promote,
    };

    this.state = {
      item: this.initialState,
      files: [],
      ArticlesAttachments: [],
      sectionList: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      DialogRemoveState: false,
      attachmentRemoveId: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getFile = this.getFile.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.openOrCloseRemoveModal = this.openOrCloseRemoveModal.bind(this);
    this.validateAtachments = this.validateAtachments.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    await this.getSections();
    await this.getData();
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
  }

  getData = async () => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    Api.get(`/articles/${this.state.item.id}`)
      .then((result) => {
        if (result.data.success) {
          this.setState({
            loading: false,
            item: {
              ...this.state.item,
              sectionid: this.state.sectionList?.find(item => item.id === result.data.data?.sectionid) ?? this.state.item.sectionid,
              content: result.data.data?.content ?? "",
            },
            ArticlesAttachments: result.data.data?.articlesattachments ?? [],
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  async getSections() {
    this.setState({ loading: true });

    var result = await Api.get(`articles-section/get-sections-name`)
      .then((rs) => rs.data)
      .catch((err) => err);

    if (result.success) {
      this.setState({ loading: false, sectionList: result?.data?.map(item => ({ id: item.id, name: item.name })) });
    } else {
      const intl = this.props.intl;

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
            ? result.data.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }

    return result.data;
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = { ...this.state.item };

    let payload = new FormData();

    for (let key in data) {
      if (key === "sectionid") {
        let valueSectionid = data[key];
        payload.append(key, valueSectionid?.id ?? null);
      } else {
        payload.append(key, data[key]);
      }
    }

    let files = this.state.files ?? [];

    if (files?.length > 3) {
      files = files.slice(0, 3);
    }

    files.forEach((file) => payload.append(`FilesUpload`, file));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.put("/articles", payload, config)

      .then((result) => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
          });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data && result.data.errors
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
          Api.kickoff(result);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = "") {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
    this.setState({ loading: false });
  }

  handleChangeEditor(value, name) {
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value || "",
      },
    }));
  }

  /* INÍCIO ANEXOS */

  getFile = async (attachment) => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    let url = await getFileDownloadLink(attachment.attachmentid);
    if (url && url.data && url.data.data && url.data.success) {
      this.setState({ loading: false });
      window.open(`${url.data.data}`, "_blank");
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "file.not.found" }),
      });
    }
  };

  validateAtachments = (files) => {
    this.setState({
      files: files,
    });
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  async removeFile(attachmentId) {
    this.setState({
      DialogRemoveState: true,
      attachmentRemoveId: attachmentId,
    });
  }

  openOrCloseRemoveModal = () => {
    this.setState({ DialogRemoveState: false, attachmentRemoveId: null });
  };

  async removeAttachment(attachmentId) {
    try {
      const response = await Api.delete(
        `articles/remove-attachment/${attachmentId}`
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async handleDeleteAttachment() {
    try {
      const attachmentId = this?.state?.attachmentRemoveId;
      if (attachmentId) {
        await this.removeAttachment(attachmentId);
        this.openOrCloseRemoveModal();
        this.setState((prev) => ({
          ...prev,
          ArticlesAttachments: [
            ...prev.ArticlesAttachments.filter(
              (file) => file.attachmentid !== attachmentId
            ),
          ],
        }));
      } else {
        this.openOrCloseRemoveModal();
      }
    } catch (error) {
      this.setState({
        attachmentRemoveId: null,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Não foi possível remover o anexo.",
      });
    }
  }

  useStyles = () => {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      },
    });
  };
  /* FIM ANEXOS */

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`;
    }

    const classes2 = this.useStyles();

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(
            classes.content,
            {
              [classes.contentShift]: headerMenu.open,
            },
            classes.actionsContent,
            {
              [classes.actionscontentShift]: actionsHeaderMenu.open,
            }
          )}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />} <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="application/json"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      inputProps={{ maxLength: 250 }}
                      fullWidth
                      label={intl.formatMessage({ id: "title.articles" })}
                      autoComplete="fname"
                      variant="outlined"
                      name="title"
                      onChange={this.handleInputChange}
                      value={item.title}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      inputProps={{ maxLength: 250 }}
                      fullWidth
                      label={intl.formatMessage({ id: "subtitle.articles" })}
                      autoComplete="fname"
                      variant="outlined"
                      name="subtitle"
                      onChange={this.handleInputChange}
                      value={item.subtitle}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      type="time"
                      inputProps={{ maxLength: 250 }}
                      fullWidth
                      label={intl.formatMessage({ id: "reading.time" })}
                      autoComplete="fname"
                      variant="outlined"
                      name="readingtime"
                      onChange={this.handleInputChange}
                      value={item.readingtime}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "section.name" })}
                      name="sectionid"
                      options={this.state.sectionList}
                      stateName="sectionid"
                      changeSelect={this.changeValues}
                      selected={item.sectionid}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="content.articles" />
                    </Typography>
                    <div
                      style={{
                        resize: "vertical",
                        overflow: "auto",
                        height: "250px",
                        marginBottom: "24px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <ReactQuill
                        name="content"
                        value={item.content}
                        style={{ height: "100%" }}
                        modules={this.reactQuillModules}
                        formats={this.reactQuillFormats}
                        fullWidth
                        variant="outlined"
                        id="description"
                        onChange={(value) =>
                          this.handleChangeEditor(value, "content")
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12} sm={12}>
                      <section style={{ width: "100%" }}>
                        <div
                          className={classes.dropzoneArea}
                          style={{ minHeight: "70px", marginTop: "5px " }}
                        >
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              this.validateAtachments([
                                ...this.state.files,
                                ...acceptedFiles,
                              ])
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <AttachFileIcon />
                                <Typography
                                  component="span"
                                  fontWeight="bold"
                                  fontSize={16}
                                  color="gray"
                                >
                                  <FormattedMessage id="drag.and.drop.logo.45" />
                                </Typography>
                              </div>
                            )}
                          </Dropzone>
                          {this.state.files.map((file) => {
                            return (
                              <div
                                className={classes.dropzoneItem}
                                key={file?.name}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {file?.name}
                                <DeleteForever
                                  className={classes.deleteItem}
                                  onClick={() => {
                                    this.removeItem(file);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </section>
                    </Grid>
                  </Grid>

                  {this.state.ArticlesAttachments.length > 0 && (
                    <Grid item xs={12} style={{ marginTop: 15 }}>
                      <div className={classes.attachmentsBox}>
                        <label>{<FormattedMessage id="attachments" />}</label>
                        <ImageList
                          cellHeight={100}
                          className={classes2.ImageList}
                          cols={1}
                        >
                          <ul>
                            {this.state.ArticlesAttachments.map((file) => {
                              return (
                                <li
                                  key={file.attachmentid}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <AttachFile />
                                  <Button onClick={() => this.getFile(file)}>
                                    {file.name}
                                  </Button>
                                  <Tooltip title={<h5>Remover arquivo</h5>}>
                                    <IconButton
                                      aria-label="textevidence"
                                      onClick={() =>
                                        this.removeFile(file.attachmentid)
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                </li>
                              );
                            })}
                          </ul>
                        </ImageList>
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "promote.article" })} selected={item.promote} stateName="promote" changeSelect={this.changeValues} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      marginTop: 15,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Grid
                      container
                      justify="flex-end"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Grid item xs={12} sm={4}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />

            <DialogGeneral
              dialogTitle="Remover"
              dialogSubTitle="Você deseja remover este anexo?"
              open={this.state.DialogRemoveState}
              openOrCloseModalFunction={this.openOrCloseRemoveModal}
              handleConfirmation={this.handleDeleteAttachment}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
ArticlesEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  articles: state.articles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ArticlesEdit))
);
