import React from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Badge,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Add } from '@mui/icons-material';

const MonthView = ({ 
  currentYear, 
  currentMonth, 
  weekdayNames,
  getFirstDayOfMonth,
  getDaysInMonth,
  isToday,
  getTicketsForDate,
  openDayDetails,
  openTicketForm,
  getStatusColor,
  getStatusIcon
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  // Calcular a altura dos blocos de dia baseado no tamanho da tela
  const getCellHeight = () => {
    if (isMobile) return 120; // Altura menor para dispositivos móveis
    if (isTablet) return 150; // Altura média para tablets
    return 180; // Altura maior para desktop
  };
  
  const cellHeight = getCellHeight();
  
  const renderCalendarDays = () => {
    const days = [];
    const daysInMonth = getDaysInMonth(currentYear, currentMonth);
    const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonth);
    
    // Adicionar células vazias para os dias antes do começo do mês
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(
        <Grid item xs={1} key={`empty-${i}`}>
          <Paper 
            sx={{ 
              height: cellHeight, 
              bgcolor: '#f5f5f5', 
              p: 1,
              boxShadow: 0,
              borderRadius: 2
            }}
          />
        </Grid>
      );
    }
    
    // Renderizar os dias do mês
    for (let day = 1; day <= daysInMonth; day++) {
      const dayTickets = getTicketsForDate(day);
      const isTodayFlag = isToday(day);
      
      days.push(
        <Grid item xs={1} key={`day-${day}`}>
          <Paper 
            elevation={1} 
            onClick={() => openDayDetails(day)}
            sx={{ 
              height: cellHeight,
              p: 1,
              cursor: 'pointer',
              transition: 'all 0.2s',
              border: isTodayFlag ? `2px solid ${theme.palette.primary.main}` : '1px solid #e5e7eb',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: 3
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 1,
              mt: 0.5, // Adicionado margem superior para evitar corte
              mx: 0.5 // Margens laterais para evitar corte nos lados
            }}>
              <Typography 
                sx={{ 
                  fontWeight: isTodayFlag ? 'bold' : 'normal',
                  color: isTodayFlag ? 'primary.main' : 'inherit'
                }}
              >
                {day}
              </Typography>
              {dayTickets.length > 0 && (
                <Box sx={{ 
                  // Envolve o badge em um Box para garantir espaço adequado
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: 22, // Altura fixa para acomodar o badge
                  minWidth: 22  // Largura fixa para acomodar o badge
                }}>
                  <Badge 
                    badgeContent={dayTickets.length} 
                    color="primary" 
                    sx={{ 
                      transform: 'scale(0.9)', // Reduz ligeiramente o tamanho do badge
                      '& .MuiBadge-badge': { 
                        fontSize: '0.65rem',
                        minWidth: '18px', // Largura mínima para garantir que o conteúdo caiba
                        height: '18px', // Altura fixa
                        padding: '0 4px', // Padding horizontal para números maiores
                        transform: 'translate(8px, -8px)' // Ajustar posição manualmente
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
            
            {/* Container para os tickets - com altura fixa */}
            <Box sx={{ 
              flexGrow: 1, 
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}>
              {dayTickets.length > 0 ? (
                <>
                  {/* Mostra até 2 tickets (ou apenas 1 em mobile) */}
                  {dayTickets.slice(0, isMobile ? 1 : 2).map((ticket, idx) => (
                    <Box 
                      key={idx} 
                      sx={{ 
                        py: 0.5, 
                        px: 1, 
                        mb: 0.5, 
                        bgcolor: '#f3f4f6',
                        borderLeft: `3px solid ${getStatusColor(ticket.status)}`,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {ticket.title}
                    </Box>
                  ))}
                  
                  {/* Indicador de mais tickets */}
                  {dayTickets.length > (isMobile ? 1 : 2) && (
                    <Typography variant="caption" color="text.secondary">
                      + {dayTickets.length - (isMobile ? 1 : 2)} mais
                    </Typography>
                  )}
                </>
              ) : (
                <Box 
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    color: 'text.secondary',
                  }}
                >
                  <IconButton 
                    size="small" 
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      openTicketForm(null, day);
                    }}
                  >
                    <Add fontSize="small" />
                  </IconButton>
                  {!isMobile && (
                    <Typography variant="caption">Adicionar</Typography>
                  )}
                </Box>
              )}
            </Box>
            
            {/* Botão de adicionar para dias com tickets */}
            {dayTickets.length > 0 && (
              <Box 
                sx={{ 
                  mt: 'auto',
                  display: 'flex',
                  justifyContent: 'center' 
                }}
              >
                <Tooltip title="Adicionar ticket">
                  <IconButton 
                    size="small" 
                    onClick={(e) => {
                      e.stopPropagation();
                      openTicketForm(null, day);
                    }}
                  >
                    <Add fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Paper>
        </Grid>
      );
    }
    
    return days;
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Dias da semana - responsivos */}
      <Grid 
        container 
        columns={{ xs: 7 }} 
        sx={{ 
          mb: 1,
          display: { xs: 'none', sm: 'flex' } // Oculta em dispositivos muito pequenos
        }}
      >
        {weekdayNames.map((day, i) => (
          <Grid item xs={1} key={i}>
            <Box sx={{ 
              p: 1, 
              textAlign: 'center', 
              fontWeight: 'bold',
              color: i === 0 || i === 6 ? 'text.secondary' : 'text.primary'
            }}>
              {/* Versão abreviada para tablet */}
              {isTablet ? day.substring(0, 1) : day}
            </Box>
          </Grid>
        ))}
      </Grid>
      
      {/* Grade do calendário - responsiva */}
      <Grid 
        container 
        columns={{ xs: 7 }} 
        spacing={1}
      >
        {renderCalendarDays()}
      </Grid>
    </Box>
  );
};

export default MonthView;