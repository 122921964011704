const extractPrimitives = (obj, integrationService) => {
  const result = [];
  var index = 1;

  function recurse(currentObj, path = null) {

    if (Array.isArray(currentObj)) {
      currentObj = currentObj[0];
    } 

    for (let key in currentObj) {
      if (currentObj.hasOwnProperty(key)) {
        const value = currentObj[key];

        if (Array.isArray(value)) {
          continue;
        } 
        else if ((typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || typeof value === 'string')) 
        {
          let _key = key;

          if (path !== null) {
            _key = `${path}.${key}`;
          }

          result.push({
            id: index,
            fieldname: _key,
            isEditable: true
          });
          
          index++;
        } 
        else if (value && typeof value === 'object') 
        {
          let _path = path ? `${path}.` : '';
          recurse(value, `${_path}${key}`)
        }
      }
    }
  }

  if (obj) {
    recurse(obj);
  }
  
  return result;
}

export default extractPrimitives;