
// Função para gerar um array maior de tickets com base nos existentes
const generateMockTickets = () => {
    // Base de clientes para gerar dados mais variados
    const clients = [
      'Google', 'Microsoft', 'Apple', 'Amazon', 'Lidiane Gomes', 'LOJA RECIFE - BOA VIAGEM',
      'Banco ABC', 'Consultoria XYZ', 'Hospital Santa Clara', 'Escola Futuro', 'Distribuidora Norte'
    ];
  
    // Categoria para gerar dados mais variados
    const categories = [
      'Segurança', 'Acesso', 'Infraestrutura', 'Manutenção', 'Software', 'Hardware', 
      'Backup', 'Treinamento', 'Redes', 'Compras', 'Banco de Dados'
    ];
  
    // Responsáveis para gerar dados mais variados
    const assignedTos = [
      'EQUIPE MIGUEL N1', 'Luiz técnico', 'Suporte N2', 'Equipe Desenvolvimento',
      'Pedro Analista', 'Maria Suporte', 'João Técnico', 'Ana Desenvolvedora',
      'Carlos Administrador', 'Fernanda Redes', '', 'Roberto Segurança'
    ];
  
    // Status possíveis
    const statuses = ['Novo', 'Em andamento', 'Finalizado'];
  
    // Prioridades possíveis
    const priorities = ['Alta', 'Média', 'Baixa'];
  
    // Array base de tickets
    const baseTickets = [
      {
        id: "3324",
        title: "Alerta de segurança",
        description: "Investigar alerta de segurança no sistema principal. Possível tentativa de acesso não autorizado.",
        status: "Novo",
        priority: "Alta",
        client: "Google",
        assignedTo: "EQUIPE MIGUEL N1",
        openDate: "07/03/2025 13:31",
        predictedDate: "15/03/2025 13:31",
        category: "Segurança"
      },
      {
        id: "3322",
        title: "Erro no sistema de login",
        description: "Usuários não conseguem fazer login após atualização do sistema para a versão 2.5.7. Erro ocorre apenas em navegadores Firefox.",
        status: "Em andamento",
        priority: "Alta",
        client: "Lidiane Gomes",
        assignedTo: "EQUIPE MIGUEL N1",
        openDate: "26/02/2025 19:48",
        predictedDate: "15/03/2025 19:48",
        category: "Acesso"
      },
      {
        id: "3317",
        title: "Configuração de rede",
        description: "Configurar nova rede para filial de Boa Viagem. Necessário configurar 5 computadores, 2 impressoras e 1 servidor local.",
        status: "Em andamento",
        priority: "Média",
        client: "LOJA RECIFE - BOA VIAGEM",
        assignedTo: "Luiz técnico",
        openDate: "26/02/2025 16:07",
        predictedDate: "10/03/2025 19:07",
        category: "Infraestrutura"
      },
      {
        id: "3314",
        title: "Manutenção preventiva",
        description: "Realizar manutenção preventiva nos servidores de produção. Verificar discos, memória e temperatura.",
        status: "Em andamento",
        priority: "Baixa",
        client: "LOJA RECIFE - BOA VIAGEM",
        assignedTo: "Luiz técnico",
        openDate: "26/02/2025 15:53",
        predictedDate: "10/03/2025 18:53",
        category: "Manutenção"
      },
      {
        id: "3313",
        title: "Instalação de software",
        description: "Instalar software de contabilidade em 10 máquinas do departamento financeiro. Versão 3.2.1 com atualizações fiscais.",
        status: "Finalizado",
        priority: "Média",
        client: "LOJA RECIFE - BOA VIAGEM",
        assignedTo: "Luiz técnico",
        openDate: "26/02/2025 14:30",
        predictedDate: "15/03/2025 16:30",
        category: "Software"
      }
    ];
  
    // Títulos genéricos para tickets
    const ticketTitles = [
      "Problema com impressora",
      "Atualização de sistema",
      "Backup falhou",
      "Configuração VPN",
      "Internet lenta",
      "Compra de equipamentos",
      "Instalação de aplicativo",
      "Erro no servidor",
      "Treinamento de usuários",
      "Falha na conexão",
      "Atualização de drivers",
      "Problema com e-mail",
      "Configuração de firewall",
      "Lentidão no sistema",
      "Recuperação de dados"
    ];
  
    // Descrições genéricas para tickets
    const ticketDescriptions = [
      "O usuário relatou problemas ao tentar utilizar o sistema. É necessário verificar as configurações e permissões de acesso.",
      "Está ocorrendo um erro quando o usuário tenta salvar informações no banco de dados. Verificar logs e conexão.",
      "Sistema apresenta lentidão em horários de pico. Analisar performance e possíveis gargalos na rede ou banco de dados.",
      "Atualização necessária para correção de bugs e implementação de novas funcionalidades solicitadas pelo cliente.",
      "Falha de segurança identificada pelo sistema de monitoramento. Necessário análise e implementação de correções.",
      "Usuário não consegue acessar determinados recursos do sistema. Verificar permissões e configurações de perfil."
    ];
  
    // Gerar aproximadamente 150 tickets espalhados ao longo de dois meses
    const generatedTickets = [];
    
    // Primeiro, adicionar os existentes
    baseTickets.forEach(ticket => {
      generatedTickets.push(ticket);
    });
    
    // Definir datas específicas que terão muitos tickets
    const targetDates = [
      { day: 10, month: 3, year: 2025, count: 25 }, // 10 de março de 2025 - 25 tickets
      { day: 15, month: 3, year: 2025, count: 20 }  // 15 de março de 2025 - 20 tickets
    ];
    
    // Gerar tickets para as datas específicas
    targetDates.forEach(targetDate => {
      for (let i = 0; i < targetDate.count; i++) {
        const id = `${4000 + generatedTickets.length}`;
        const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
        const randomPriority = priorities[Math.floor(Math.random() * priorities.length)];
        const randomClient = clients[Math.floor(Math.random() * clients.length)];
        const randomAssignedTo = assignedTos[Math.floor(Math.random() * assignedTos.length)];
        const randomCategory = categories[Math.floor(Math.random() * categories.length)];
        const randomTitle = ticketTitles[Math.floor(Math.random() * ticketTitles.length)];
        const randomDescription = ticketDescriptions[Math.floor(Math.random() * ticketDescriptions.length)];
        
        // Formatar a data-alvo
        const formattedDate = `${String(targetDate.day).padStart(2, '0')}/${String(targetDate.month).padStart(2, '0')}/${targetDate.year}`;
        
        // Gerar horário aleatório
        const hour = Math.floor(Math.random() * 24);
        const minute = Math.floor(Math.random() * 60);
        const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
        
        // Data de abertura (2 dias antes da data prevista)
        const openDate = new Date(targetDate.year, targetDate.month - 1, targetDate.day);
        openDate.setDate(openDate.getDate() - 2);
        const formattedOpenDate = `${String(openDate.getDate()).padStart(2, '0')}/${String(openDate.getMonth() + 1).padStart(2, '0')}/${openDate.getFullYear()} ${formattedTime}`;
        
        // Criar novo ticket
        const newTicket = {
          id: id,
          title: `${randomTitle} #${i+1}`,
          description: randomDescription,
          status: randomStatus,
          priority: randomPriority,
          client: randomClient,
          assignedTo: randomAssignedTo,
          openDate: formattedOpenDate,
          predictedDate: `${formattedDate} ${formattedTime}`,
          category: randomCategory
        };
        
        generatedTickets.push(newTicket);
      }
    });
    
    // Adicionar mais 50 tickets distribuídos aleatoriamente em outros dias
    for (let i = 0; i < 50; i++) {
      const id = `${4000 + generatedTickets.length}`;
      const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
      const randomPriority = priorities[Math.floor(Math.random() * priorities.length)];
      const randomClient = clients[Math.floor(Math.random() * clients.length)];
      const randomAssignedTo = assignedTos[Math.floor(Math.random() * assignedTos.length)];
      const randomCategory = categories[Math.floor(Math.random() * categories.length)];
      const randomTitle = ticketTitles[Math.floor(Math.random() * ticketTitles.length)];
      const randomDescription = ticketDescriptions[Math.floor(Math.random() * ticketDescriptions.length)];
      
      // Gerar data aleatória entre 1 de março e 31 de março de 2025
      // (evitando as datas-alvo que já têm muitos tickets)
      let day;
      do {
        day = Math.floor(Math.random() * 31) + 1;
      } while (day === 10 || day === 15); // Evitar dias 10 e 15 que já estão cheios
      
      const month = 3; // Março
      const year = 2025;
      
      // Formatar a data
      const formattedDate = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
      
      // Gerar horário aleatório
      const hour = Math.floor(Math.random() * 24);
      const minute = Math.floor(Math.random() * 60);
      const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      
      // Data de abertura (entre 1 e 5 dias antes da data prevista)
      const daysBeforeOpen = Math.floor(Math.random() * 5) + 1;
      const openDate = new Date(year, month - 1, day);
      openDate.setDate(openDate.getDate() - daysBeforeOpen);
      const formattedOpenDate = `${String(openDate.getDate()).padStart(2, '0')}/${String(openDate.getMonth() + 1).padStart(2, '0')}/${openDate.getFullYear()} ${formattedTime}`;
      
      // Criar novo ticket
      const newTicket = {
        id: id,
        title: randomTitle,
        description: randomDescription,
        status: randomStatus,
        priority: randomPriority,
        client: randomClient,
        assignedTo: randomAssignedTo,
        openDate: formattedOpenDate,
        predictedDate: `${formattedDate} ${formattedTime}`,
        category: randomCategory
      };
      
      generatedTickets.push(newTicket);
    }
    
    return generatedTickets;
  };
  
  // Exportar a lista expandida de tickets mockados
  export const mockTickets = generateMockTickets();
/*
  // Dados mockados simples
export const mockTickets = [
  {
    id: 1,
    title: "Alerta de segurança",
    description: "Investigar alerta de segurança no sistema principal",
    status: "Novo",
    priority: "Alta",
    requester: "Equipe de Segurança",
    assignedTo: "Suporte Técnico",
    predictedDate: "15/03/2025 13:00"
  },
  {
    id: 2,
    title: "Erro no sistema de login",
    description: "Usuários não conseguem fazer login após atualização",
    status: "Em andamento",
    priority: "Alta",
    requester: "Departamento Comercial",
    assignedTo: "Equipe de Desenvolvimento",
    predictedDate: "15/03/2025 15:00"
  },
  {
    id: 3,
    title: "Configuração de rede",
    description: "Configurar nova rede para filial",
    status: "Em andamento",
    priority: "Média",
    requester: "TI Corporativo",
    assignedTo: "Equipe de Infraestrutura",
    predictedDate: "17/03/2025 10:00"
  },
  {
    id: 4,
    title: "Manutenção preventiva",
    description: "Realizar manutenção preventiva nos servidores",
    status: "Finalizado",
    priority: "Baixa",
    requester: "Administração",
    assignedTo: "Equipe de Suporte",
    predictedDate: "18/03/2025 09:00"
  },
  {
    id: 5,
    title: "Instalação de software",
    description: "Instalar software de contabilidade",
    status: "Finalizado",
    priority: "Média",
    requester: "Financeiro",
    assignedTo: "Suporte Técnico",
    predictedDate: "20/03/2025 14:00"
  }
];

  */
