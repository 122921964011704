import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react';

import ReactQuill from 'react-quill';
import HelperCustomField from '../../HelperCustomField/HelperCustomField';
import Input from '../Input/Input';
import { useBaseInspector } from './useBaseInspector';
import { Switch } from '@mui/material';

interface Props {
    cell: shapes.app.Message;
    chatbotFlowId: string;
}

interface InspectorPort {
    id: string;
    label: string;
}

const cellProps = {
    label: ['attrs', 'label', 'text'],
    description: ['attrs', 'description', 'text'],
    icon: ['attrs', 'icon', 'xlinkHref'],
    portLabel: ['attrs', 'portLabel', 'text'],
    isTextRich: ['attrs', 'isTextRich', 'isTextRich'],
};

const MessageInspector = (props: Props): ReactElement => {

    const { cell, chatbotFlowId } = props;

    const [label, setLabel] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [icon, setIcon] = useState<string>('');
    const [ports, setPorts] = useState<InspectorPort[]>([]);
    const [canAddPort, setCanAddPort] = useState<boolean>(false);
    const [isTextRich, setIsTextRich] = useState<boolean>(false);

    const assignFormPorts = useCallback((): void => {
        setCanAddPort(cell.canAddPort('out'));
        setPorts(cell.getGroupPorts('out').map(({ id }) => {
            return {
                id,
                label: cell.portProp(id, cellProps.portLabel)
            };
        }));
    }, [cell]);

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
        setDescription(cell.prop(cellProps.description));
        setIcon(cell.prop(cellProps.icon));
        setIsTextRich(cell.prop(cellProps.isTextRich));
        assignFormPorts();
    }, [cell, assignFormPorts]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    const addCellPort = (): void => {
        cell.addDefaultPort();
        assignFormPorts();
    };

    const removeCellPort = (portId: string): void => {
        cell.removePort(portId);
        assignFormPorts();
    };

    const changeCellPort = (port: InspectorPort, value: string): void => {
        cell.portProp(port.id, cellProps.portLabel, value);
    };

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.label, e.target.value)}
                       disabled={true}
                />
            </label>

            <div style={{ marginBottom: '8px', marginTop: '8px'}}>
                <Switch
                    value={isTextRich}
                    checked={isTextRich}
                    onChange={(e:any) => changeCellProp(cellProps.isTextRich, e?.target?.checked)}     
                />
                <label>É um texto rico?</label>
            </div>

            {
                isTextRich
                ? (
                    <label>Descrição
                        <div style={{ resize: "vertical", overflow: "auto", height: "250px", marginBottom: "24px", border: "1px solid #ccc" }}>
                            <ReactQuill
                                style={{ height: '100%' }}
                                name="description"
                                fullWidth
                                variant="outlined"
                                id="descriprion"
                                value={description}
                                onChange={(value: any) => changeCellProp(cellProps.description, value)}
                            />
                        </div>
                    </label>
                )
                : (
                    <label>Descrição
                        <Input 
                            type="text"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                        />
                    </label>
                )
            }

            <HelperCustomField
                isRichText={isTextRich}
                description={description}
                param1={cellProps.description}
                onChangeDescription={changeCellProp}
                chatbotFlowId={chatbotFlowId}
            />

            <div className="ports">
                <div className="out-ports-bar">
                    <span>Opções</span>
                    <button disabled={!canAddPort}
                            onClick={addCellPort}
                            className="add-port"
                            data-tooltip="Add Output Port">
                    </button>
                </div>
                {ports.map(port => {
                    return (
                        <div key={port.id} className="port">
                            <Input defaultValue={port.label}
                                   onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellPort(port, e.target.value)}
                            />
                            <button className="remove-port"
                                    onClick={() => removeCellPort(port.id)}
                                    data-tooltip="Remove Output Port">
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default MessageInspector;
