import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Typography,
  Divider,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

const TicketFormModal = ({ open, onClose, onSave, ticketData }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const [formData, setFormData] = useState({
    id: '',
    title: '',
    description: '',
    requester: '',
    assignedTo: '',
    status: 'Novo',
    priority: 'Média',
    predictedDate: new Date().toISOString()
  });
  
  const [errors, setErrors] = useState({});
  
  // Atualizar form quando ticketData mudar
  useEffect(() => {
    if (ticketData) {
      if (ticketData.predictedDate) {
        // Se for uma string, converter para data
        const predictedDate = typeof ticketData.predictedDate === 'string'
          ? new Date(ticketData.predictedDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'))
          : ticketData.predictedDate;
          
        setFormData({
          ...ticketData,
          predictedDate
        });
      } else {
        setFormData(ticketData);
      }
    } else {
      // Reset do formulário
      setFormData({
        id: '',
        title: '',
        description: '',
        requester: '',
        assignedTo: '',
        status: 'Novo',
        priority: 'Média',
        predictedDate: new Date().toISOString()
      });
    }
    
    // Reset dos erros
    setErrors({});
  }, [ticketData, open]);
  
  // Lidar com mudanças nos campos
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Limpar erro se o campo foi preenchido
    if (value.trim() !== '') {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };
  
  // Lidar com mudança na data
  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      predictedDate: date
    });
  };
  
  // Validar o formulário
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'O título é obrigatório';
    }
    
    if (!formData.description.trim()) {
      newErrors.description = 'A descrição é obrigatória';
    }
    
    if (!formData.requester.trim()) {
      newErrors.requester = 'O solicitante é obrigatório';
    }
    
    if (!formData.predictedDate) {
      newErrors.predictedDate = 'A data prevista é obrigatória';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Formatar data para exibição
  const formatDateForSubmission = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  
  // Lidar com o envio do formulário
  const handleSubmit = () => {
    if (validateForm()) {
      // Formatar data antes de enviar
      const ticketToSave = {
        ...formData,
        predictedDate: formatDateForSubmission(formData.predictedDate)
      };
      
      onSave(ticketToSave);
    }
  };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Dialog 
        open={open} 
        onClose={onClose}
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
          <Typography variant="h6">
            {formData.id ? 'Editar Ticket' : 'Novo Ticket'}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </DialogTitle>
        
        <Divider />
        
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Título"
                name="title"
                value={formData.title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
                required
                autoFocus
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
                error={!!errors.description}
                helperText={errors.description}
                required
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Solicitante"
                name="requester"
                value={formData.requester}
                onChange={handleChange}
                error={!!errors.requester}
                helperText={errors.requester}
                required
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Atribuído para"
                name="assignedTo"
                value={formData.assignedTo}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  label="Status"
                >
                  <MenuItem value="Novo">Novo</MenuItem>
                  <MenuItem value="Em andamento">Em andamento</MenuItem>
                  <MenuItem value="Finalizado">Finalizado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="priority-label">Prioridade</InputLabel>
                <Select
                  labelId="priority-label"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  label="Prioridade"
                >
                  <MenuItem value="Alta">Alta</MenuItem>
                  <MenuItem value="Média">Média</MenuItem>
                  <MenuItem value="Baixa">Baixa</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <DateTimePicker
                label="Data Prevista"
                value={formData.predictedDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    fullWidth 
                    variant="outlined"
                    error={!!errors.predictedDate}
                    helperText={errors.predictedDate}
                    required
                  />
                )}
                ampm={false}
                format="dd/MM/yyyy HH:mm"
              />
            </Grid>
            
            {formData.id && (
              <Grid item xs={12}>
                <Box sx={{ bgcolor: '#f9fafb', p: 2, borderRadius: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    ID do Ticket: #{formData.id}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={onClose} variant="outlined">
            Cancelar
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            color="primary"
          >
            {formData.id ? 'Atualizar' : 'Criar'} Ticket
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default TicketFormModal;