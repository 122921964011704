import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HeaderMenu from '../../components/header-menu';
import { bindActionCreators } from 'redux';
import * as calledsActions from '../../redux/actions/calleds';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import clsx from 'clsx';
import PropTypes, { array } from "prop-types";
import { CssBaseline, CircularProgress, Container, Grid } from '@mui/material';
import Api from "../../services/api";
import Configuration from '../wizard-pages/configuration';
import Address from '../wizard-pages/address';
import Slas from '../wizard-pages/slas';
import Categories from '../wizard-pages/categories';
import Departaments from '../wizard-pages/departaments';
import Materials from '../wizard-pages/materials';
import Patrimony from '../wizard-pages/patrimony';
import Check from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import * as Service from '../../services/organizationsla.service';
import CustomizedSnackbars from '../../components/material-snackbars';
import Divider from '@mui/material/Divider';
import "./wizard.css";
import image2 from "./images/organization2.png";
import MobileStepper from '@mui/material/MobileStepper';
import { PapiroConsole } from '../../utils/papiroConsole';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  progressBar: {
    // maxWidth: 800,
    //flexGrow: 2,
  },
}));

const progressBar = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    flexGrow: 1,
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

var arrayStespCompletedes = []
var organizationIds = 0
var checkMenu = false
var headerConditional = null

var saveId = undefined



function QontoStepIcon(props) {

  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function getSteps(intl) {
  //return ['Configuração', 'Endereço', 'Sla', 'Categorias','Departamentos','Material','Patrimônios'];
  return [intl.formatMessage({id:"config"}), intl.formatMessage({id:"address"}), intl.formatMessage({id:"sla.filter"}),intl.formatMessage({id:"categories"}),intl.formatMessage({id:"menu.organizationdepartaments"}),intl.formatMessage({id:"components"}),intl.formatMessage({id:"menu.organizationpatrimonies"})];
}

function getStepContent(step, intl) {
  switch (step) {
    case 0:
      return intl.formatMessage({id:"tutorial.progress.steps.config.organization"});
    case 1:
      return intl.formatMessage({id:"tutorial.progress.steps.register.address"});
    case 2:
      return intl.formatMessage({id:"tutorial.progress.steps.create.slas"});
    case 3:
    return intl.formatMessage({id:"tutorial.progress.steps.associate.categories"});
    case 4:
        return intl.formatMessage({id:"wizard.add.departaments.to.organization"});
    case 5:
        return intl.formatMessage({id:"wizard.create.materials.to.organization"});
    case 6:
        return intl.formatMessage({id:"wizard.add.patrimonies.to.organization"});
    default:
        return intl.formatMessage({id:"tutorial.progress.steps.complete"});
  }
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

function Wizard(props) {


  const classed = useStyles();
  const progressBars = progressBar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [activeProgress, setActiveProgress] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [resetArray, setResetArray] = React.useState(false)
  //const [array,setArray]= React.useState([]) 
  //const [saveId, setsaveId] =  React.useState(undefined);
  const [data, setData] = React.useState({ data: false });

  const intl = props.intl;

  const [notifications, setnotification] = React.useState({ notificationVariant: 'error', openNotification: false, notificationMessage: '', });
  const steps = getSteps(intl);
  const history = useHistory()
  var completedTask = 0;

  PapiroConsole.log("props aqui do wizard")
  PapiroConsole.log(props)
  var hasValue = null
  var x = 0

  if (props.location !== undefined) {
    if (props.location.state !== undefined || saveId !== undefined) {

      if (props.location.state !== "") {
        saveId = props.location.state.organization.id
      }


      x = saveId

    }
    else {

      history.push({
        pathname: `/pt/calleds`,

      });
      //x = 7953
    }

  }
  else {
    x = props.organizations.organization.id

  }

  const { classes, userSession, headerMenu, actionsHeaderMenu, calleds } = props;

  useEffect(() => {



    if (props.location !== undefined) {

      checkMenu = true
      headerConditional = componentHeader()

      if (props.location.state !== undefined) {


        let id = 0;


        if (organizationIds !== x) {

          arrayStespCompletedes = []
          organizationIds = x
        }
        props.location.state.copyed.forEach(element => {

          switch (element.name) {
            case intl.formatMessage({id:"menu.configs"}):
              id = 0
              break;
            case intl.formatMessage({id:"slas"}):
              id = 2
              break;
            case intl.formatMessage({id:"menu.organizationdepartaments"}):
              id = 4
              break;
            case intl.formatMessage({id:"menu.organizationmaterials"}):
              id = 5
              break;
            case intl.formatMessage({id:"menu.organizationpatrimonies"}):
              id = 6
              break;

          }

          arrayStespCompletedes.push(id)


          handleCompleteInitials(id)



        });
      }
    }
    else {


      checkMenu = false
      headerConditional = componentHeader()
      async function getData(id) {
        await Api.get(`organizations/wizard/${id}`)
          .then((result) => {
            if (result.data.success) {

              var arrayOtherCaseStepsCompletedes = []


              arrayOtherCaseStepsCompletedes = {
                configuration: [result.data.data.organizationsettings],
                addresses: result.data.data.addresses,
                sla: result.data.data.organizationslas,
                categories: result.data.data.organizationcategories,
                departaments: result.data.data.organizationdepartaments,
                materials: result.data.data.organizationmaterials,
                patrimony: result.data.data.organizationpatrimonies,

              }
              Object.keys(arrayOtherCaseStepsCompletedes).forEach(function (key, index) {


                let id = null
                if (arrayOtherCaseStepsCompletedes[key]) {

                  switch (index) {
                    case 0:
                      if (arrayOtherCaseStepsCompletedes[key].length > 0) {
                        if (arrayOtherCaseStepsCompletedes[key][0] === null) {

                        }
                        else {

                          id = 0
                        }

                      }
                      break;
                    case 1:
                      if (arrayOtherCaseStepsCompletedes[key].length > 0) {
                        id = 1
                      }

                      break;
                    case 2:
                      if (arrayOtherCaseStepsCompletedes[key].length > 0) {
                        id = 2
                      }

                      break;
                    case 3:
                      if (arrayOtherCaseStepsCompletedes[key].length > 0) {
                        id = 3
                      }

                      break;
                    case 4:
                      if (arrayOtherCaseStepsCompletedes[key].length > 0) {
                        id = 4
                      }

                      break;
                    case 5:
                      if (arrayOtherCaseStepsCompletedes[key].length > 0) {
                        id = 5
                      }

                      break;
                    case 6:
                      if (arrayOtherCaseStepsCompletedes[key].length > 0) {
                        id = 6
                      }

                      break;


                  }
                }
                if (id !== null) {


                  if (organizationIds !== x) {

                    arrayStespCompletedes = []

                    organizationIds = x

                  }

                  arrayStespCompletedes.push(id)

                  //let localArray = arraySteps.array 
                  //localArray.push(id)
                  handleCompleteInitials(id)

                }
                else {


                  if (organizationIds !== x) {


                    arrayStespCompletedes = []

                    organizationIds = x
                    resetArrayFunction()

                  }
                }
              });
            }
          })

      }
      getData(x);

    }
    localStorage.setItem('calledlistdatasTableFilter', '')
    localStorage.setItem('calledlistdatasTablePage', '1')
  }, []);

  const closeNotification = () => {
    setnotification({ openNotification: false, notificationVariant: 'success', notificationMessage: intl.formatMessage({id:"edit.success"}) })
  }

  const getContent = (step, organizationid, handleBack, handleNext, handleReset) => {
    switch (step) {
      case 0:
        return <Configuration handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton={<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
          {intl.formatMessage({id:"back"})}
        </Button>} nextButton={<Button
          type='submit'
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >

          {intl.formatMessage({id:"next"})}
        </Button>} />;
      case 1:
        return <Address handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton={<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
          {intl.formatMessage({id:"back"})}
        </Button>} nextButton={<Button
          type='submit'
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >

          {intl.formatMessage({id:"next"})}
        </Button>} />;
      case 2:
        return <Slas handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton={<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
          {intl.formatMessage({id:"back"})}
        </Button>} nextButton={<Button
          type='submit'
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >

          {intl.formatMessage({id:"next"})}
        </Button>} />;

      case 3:
        return <Categories handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton={<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
          {intl.formatMessage({id:"back"})}
        </Button>} nextButton={<Button
          type='submit'
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >

          {intl.formatMessage({id:"next"})}
        </Button>} />;
      case 4:
        return <Departaments handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} 
          backButton={<Button 
              disabled={activeStep === 0} 
              onClick={handleBack} 
              className={classes.button}
            >
              {intl.formatMessage({id:"back"})}
          </Button>} 
          finishButton={<Button
          type='button'
          onClick={handleFinish}
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >
          {intl.formatMessage({id:"end"})}
        </Button>} 
        nextButton={<Button
          type='button'
          onClick={handleNext}
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >

          {intl.formatMessage({id:"next"})}
        </Button>} />;
      case 5:
        return <Materials handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton={<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
          {intl.formatMessage({id:"back"})}
        </Button>} nextButton={<Button
          type='button'
          onClick={handleNext}
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >
          {intl.formatMessage({id:"next"})}
        </Button>} finishButton={<Button
          type='button'
          fullWidth
          onClick={handleFinish}
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >
          {intl.formatMessage({id:"end"})}
        </Button>} />;
      case 6:
        return <Patrimony handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext}
        backButton={<Button 
          disabled={activeStep === 0} 
          onClick={handleBack} 
          className={classes.button}
        >
          {intl.formatMessage({id:"back"})}
        </Button>} 
        finishButton={<Button
          type='button'
          fullWidth
          onClick={handleFinish}
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ textAlign: 'center', marginLeft: '5px', marginRight: '5px' }}
        >
          {intl.formatMessage({id:"end"})}
        </Button>}  />;


      default:
        return <div>
          <Button
            variant="contained"
            color="primary"
            component={Link} {...{ to: `/pt/calleds` }}
          >
            {intl.formatMessage({id:"end"})}
          </Button>
        </div>;


    }
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const allNecessaryStepsCompleted = () => {
    let isAllComplete = true
    for (let i = 0; i <= 3; i++) {
      isAllComplete = completed[i] === true ? true : false
    }
    return isAllComplete
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allNecessaryStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
        
    if (newActiveStep === 3) {

      if (hasValue === true || data.data === true) {
        setActiveStep(newActiveStep);
      }
      else {
        setActiveStep(newActiveStep + 1);
      }
    }
    else {
      setActiveStep(newActiveStep);
    }


  };


  const handleBack = () => {
    if (activeStep - 1 === 3) {
      if (data.data == true || hasValue == true) {
        //setActiveStep(newActiveStep);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
      else {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
      }
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

  };

  const handleNextBarProgress = () => {
    setActiveProgress((prevActiveStep) => prevActiveStep + 1);
    completedTask = completedTask + 1;
  };



  const handleStep = async(step) =>{

    if (step === 3) {
      var result = await Service.getSLA(x);
      if (result.success) {
        if (result.data.length > 0) {
          setData({ data: true })
          hasValue = true;
          setActiveStep(step);
        }
        else {
          setnotification({ openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({id:"wizard.register.sla.first"}) })
        }
      }
    }
    else {
      setActiveStep(step);
    }


  };

  const resetArrayFunction = () => {

    setResetArray(true)

  };

  const handleCompleteInitials = (step) => {

    const newCompleted = completed;
    newCompleted[step] = true;

    if (step === 2) {
      hasValue = true;
      setData({ data: true })
    }
    setCompleted(newCompleted);
    setActiveProgress((prevActiveStep) => prevActiveStep + 1);
    //handleNext(); //  comentado para resolver a bronca de sempre iniciar na aba endereço; Será ajustado com uma tarefa para parar na aba pendente, caso contrário, na primeira mesmo; PORTO
  };

  const handleFinish = () => {
    const newActiveStep =
      !allNecessaryStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : 7;
        
    setActiveStep(newActiveStep);
  };

  const checkStyle = (i) => {


    let arraystepcomplete = []


    arrayStespCompletedes.forEach(element => {

      if (element === i) {
        arraystepcomplete.push(i)


      }
    });

    if (arraystepcomplete.length > 0) {

      return "classeteste"
    }
    else {

      return ""
    }


  };

  const componentHeader = () => {

    if (checkMenu === true) {
      return (
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        />

      )
    }
    else {


      return (
        <main
          className={clsx(classes.content)}
        />
      )
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;


    if (activeStep === 2) {
      hasValue = true;
      setData({ data: true })
    }

    arrayStespCompletedes.push(activeStep)


    setCompleted(newCompleted);
    console.log("setnotification")
    setnotification({ openNotification: true, notificationVariant: 'success', notificationMessage: intl.formatMessage({id:"edit.success"}) })
    handleNext();
  };

  const handleReset = () => {
    //setActiveStep(0);
    //setCompleted({});
    history.push({
      pathname: `/pt/calleds`,

    });
  };

  return (

    <div className={classes.root}>

      <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginLeft: 0 }}>
        {props.location !== undefined &&
          <div>
            <h4 style={{ color: "#566573", fontWeight: 700, marginTop: "100px", marginBottom: "25px" }}> {intl.formatMessage({id:"organization.generalconfigurations"})}</h4>
            <Divider style={{ height: '4px', marginBottom: "35px" }} />
            <h3 style={{ color: "#566573", fontWeight: 700, marginBottom: "25px" }}> {intl.formatMessage({id:"wizard.organization.config"})} </h3>
          </div>

        }

        <div className="containerimgstep">
          <div>
            <h2 className="h4-principal">{props.location && props.location.state && props.location.state.organization && props.location.state.organization.id ? props.location.state.organization.id : props.organizations && props.organizations.organization ? props.organizations.organization.id : ""  } | {props.location && props.location.state && props.location.state.organization && props.location.state.organization.name ? props.location.state.organization.name : props.organizations && props.organizations.organization ? props.organizations.organization.name: ""}</h2>
            <h4 className="h4-title"> {intl.formatMessage({id:"wizard.organization.organization.info"})} </h4>
            <h4 className="h4-subtitle"> {intl.formatMessage({id:"wizard.organization.organization.config"})} </h4>
            <MobileStepper
              variant="progress"
              steps={8}
              position="static"
              activeStep={activeProgress}
              className={progressBars.root}
              nextButton={
                <Button size="small" disabled>
                  <span style={{ color: "black" }}> {intl.formatMessage({id:"wizard.organization.operation.completed"})} {activeProgress} {intl.formatMessage({id:"wizard.organization.out.of.seven"})}</span>
                </Button>
              }
            />
            <Stepper nonLinear activeStep={activeStep} >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={handleStep.bind(null, index)} completed={completed[index]}>
                    <div className={checkStyle(index)}>
                      {label}
                    </div>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {/* {allStepsCompleted() ? (
                <div>
                  <Typography className={classed.instructions}>
                   {intl.formatMessage({id:"wizard.organization.config.setup.completed.view.calleds"})}
                  </Typography>
                  <Button onClick={handleReset} variant="outlined" color="primary">Listagem de chamados</Button>
                </div>
              ) : (
                <div>

                </div>
              )} */}
              <div>
                <Typography style={{ marginLeft: "30px", }} className={classed.instructions}>
                  {getStepContent(activeStep, intl)}
                </Typography>

                {getContent(activeStep, x, handleBack, handleNext, handleReset,)}

                <div>
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography variant="caption" className={classed.completed}>

                      </Typography>
                    ) : (
                      <div>

                      </div>
                    ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </Container>




      <Footer />

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notifications.notificationVariant}
        message={notifications.notificationMessage}
        isOpen={notifications.openNotification}
        toClose={closeNotification}
      />
    </div>



  );
}

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  history: PropTypes.object.isRequired,
  organizations: state.organizations,
  org: state.organization.organization

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Wizard)));
