import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Chip, 
  Paper, 
  InputBase, 
  Typography, 
  Tooltip,
  useTheme,
  alpha
} from '@mui/material';

/**
 * Componente TagInput avançado estilo Freshdesk usando Material-UI
 * 
 * @param {Object} props - Propriedades do componente
 * @param {string} [props.label="Contém"] - Texto do rótulo do campo
 * @param {string} [props.placeholder="Digite para inserir"] - Texto de placeholder quando não há tags
 * @param {string[]} [props.initialTags=['casa', 'teste']] - Tags iniciais (ignorado se value estiver definido)
 * @param {string[]} [props.value] - Array de tags para controlar o componente externamente
 * @param {Function} [props.onChange] - Callback chamado quando as tags mudam: (tags: string[]) => void
 * @param {boolean} [props.disabled=false] - Define se o componente está desabilitado
 * @param {boolean} [props.error=false] - Define se há erro no campo
 * @param {string} [props.helperText] - Texto de ajuda exibido abaixo do campo
 * @param {number} [props.maxTags=null] - Número máximo de tags permitidas (null para ilimitado)
 * @param {boolean} [props.allowDuplicates=false] - Permite duplicatas de tags
 * @param {boolean} [props.allowCaseSensitive=false] - Considera maiúsculas/minúsculas ao verificar duplicatas
 * @param {string} [props.className] - Classe CSS adicional
 * @param {boolean} [props.onlyNumbers] - Use apenas caso queira que o input aceite apenas números
 */
const TagInput = ({
  label = "Contém",
  placeholder = "Digite para inserir",
  initialTags = ['casa', 'teste'],
  value,
  onChange,
  disabled = false,
  error = false,
  helperText,
  maxTags = null,
  allowDuplicates = false,
  allowCaseSensitive = false,
  className = '',
  onlyNumbers = false,
}) => {
  // Usar value se fornecido, caso contrário usar initialTags
  const isControlled = value !== undefined;
  const [tags, setTags] = useState(isControlled ? value : initialTags);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const theme = useTheme();

  // Sincronizar com prop value quando controlado externamente
  useEffect(() => {
    if (isControlled) {
      setTags(value);
    }
  }, [value, isControlled]);
  
  // Sincronizar com prop initialTags quando não controlado
  useEffect(() => {
    if (!isControlled) {
      setTags(initialTags);
    }
  }, [initialTags, isControlled]);
  
  // Notificar parent component quando as tags mudarem
  const updateTags = (newTags) => {
    if (!isControlled) {
      setTags(newTags);
    }
    if (onChange) {
      onChange(newTags);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log('onlyNumber', onlyNumbers);
    if (onlyNumbers) {
      if (/^\d*$/.test(value)) {
        setInputValue(value);
      }
    } else {
      setInputValue(value);
    }
    
  };

  const handleInputKeyDown = (e) => {
    // Remove a última tag ao pressionar Backspace com input vazio
    if (e.key === 'Backspace' && !inputValue && tags.length > 0) {
      const newTags = [...tags];
      newTags.pop();
      updateTags(newTags);
      return;
    }
    
    // Adiciona tag ao pressionar Enter ou Tab
    if ((e.key === 'Enter' || e.key === 'Tab') && inputValue.trim()) {
      e.preventDefault();
      addTag(inputValue);
    }
  };

  const isTagDuplicate = (tag) => {
    if (allowDuplicates) return false;
    
    if (allowCaseSensitive) {
      return tags.includes(tag);
    } else {
      return tags.some(t => t.toLowerCase() === tag.toLowerCase());
    }
  };

  const addTag = (tag) => {
    if (disabled) return;
    
    const trimmedTag = tag.trim();
    
    // Verifica se a tag está vazia
    if (!trimmedTag) return;
    
    // Verifica se já atingiu o número máximo de tags
    if (maxTags !== null && tags.length >= maxTags) return;
    
    // Verifica se a tag já existe
    if (isTagDuplicate(trimmedTag)) return;
    
    const newTags = [...tags, trimmedTag];
    updateTags(newTags);
    setInputValue('');
  };

  const removeTag = (tagToRemove) => {
    if (disabled) return;
    
    const newTags = tags.filter(tag => tag !== tagToRemove);
    updateTags(newTags);
  };

  const handleFocus = () => {
    if (disabled) return;
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (!disabled && inputValue.trim()) {
      addTag(inputValue);
    }
  };
  
  // Foca no input quando clicar em qualquer lugar do container
  const handleContainerClick = (e) => {
    if (disabled) return;
    
    // Verifica se o container foi clicado e se a referência ao input existe
    if (e.target === containerRef.current || containerRef.current.contains(e.target)) {
      // Verificação de segurança para garantir que inputRef.current existe
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };
  
  // Helper text padrão ou personalizado
  const displayHelperText = helperText || "Pressione Enter para adicionar uma tag";

  // Determina as cores baseadas no estado (error, disabled, focused)
  const getBorderColor = () => {
    if (error) return theme.palette.error.main;
    if (isFocused) return theme.palette.primary.main;
    return theme.palette.grey[300];
  };
  
  const getBoxShadow = () => {
    if (error) return `0 0 0 2px ${alpha(theme.palette.error.main, 0.2)}`;
    if (isFocused) return `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`;
    return 'none';
  };

  return (
    <Box 
      className={className} 
      sx={{ width: '100%' }}
      ref={containerRef}
      onClick={handleContainerClick}
    >
      {label && (
        <Typography 
          variant="body2" 
          sx={{ 
            mb: 1, 
            fontWeight: 500, 
            color: error 
              ? theme.palette.error.main 
              : disabled 
                ? theme.palette.text.disabled 
                : theme.palette.text.secondary 
          }}
        >
          {label}
        </Typography>
      )}
      
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          p: 1,
          borderRadius: 1,
          border: `1px solid ${getBorderColor()}`,
          backgroundColor: disabled 
            ? theme.palette.action.disabledBackground 
            : theme.palette.background.paper,
          transition: 'border-color 0.2s, box-shadow 0.2s',
          minHeight: 42,
          boxShadow: getBoxShadow(),
          cursor: disabled ? 'not-allowed' : 'text',
          '&:hover': {
            borderColor: !disabled && !isFocused && !error
              ? theme.palette.text.primary 
              : getBorderColor()
          }
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '100%' }}>
          {tags.map((tag, index) => (
            <Tooltip key={index} title={tag} placement="top" arrow>
              <Chip
                label={tag}
                size="small"
                onDelete={disabled ? undefined : () => removeTag(tag)}
                sx={{
                  backgroundColor: error 
                    ? alpha(theme.palette.error.main, 0.1) 
                    : theme.palette.grey[100],
                  color: error 
                    ? theme.palette.error.main 
                    : theme.palette.text.primary,
                  borderRadius: 1,
                  height: 28,
                  opacity: disabled ? 0.7 : 1,
                  maxWidth: { xs: 150, sm: 200 },
                  '& .MuiChip-label': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                  '& .MuiChip-deleteIcon': {
                    fontSize: 16,
                    color: error 
                      ? theme.palette.error.main 
                      : theme.palette.grey[500],
                    '&:hover': {
                      color: error 
                        ? theme.palette.error.dark 
                        : theme.palette.grey[700],
                    },
                  },
                }}
              />
            </Tooltip>
          ))}
          
          {(!maxTags || tags.length < maxTags) && (
            <InputBase
              inputRef={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={tags.length === 0 ? placeholder : ''}
              disabled={disabled}
              aria-label={label}
              aria-invalid={error}
              aria-describedby={`${label}-helper-text`}
              sx={{
                ml: tags.length > 0 ? 0.5 : 0,
                flex: 1,
                fontSize: 14,
                '& input': {
                  padding: '4px 8px',
                  cursor: disabled ? 'not-allowed' : 'text',
                },
              }}
            />
          )}
        </Box>
      </Paper>
      
      {displayHelperText && (
        <Typography 
          id={`${label}-helper-text`}
          variant="caption" 
          sx={{ 
            mt: 0.5, 
            display: 'block',
            color: error 
              ? theme.palette.error.main 
              : theme.palette.text.secondary 
          }}
        >
          {displayHelperText}
        </Typography>
      )}
    </Box>
  );
};

TagInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  initialTags: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  maxTags: PropTypes.number,
  allowDuplicates: PropTypes.bool,
  allowCaseSensitive: PropTypes.bool,
  className: PropTypes.string
};

export default TagInput;