import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Divider,
  Paper,
  Chip,
  Button,
  Grid,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Close,
  ArrowBack,
  Edit,
  Delete,
  PriorityHigh,
  AccessTime,
  Person,
  Info,
  Search
} from '@mui/icons-material';

const DayDetailModal = ({
  open,
  onClose,
  selectedDay,
  selectedTicket,
  onTicketClick,
  onBackToList,
  paginationInfo,
  onLoadMore,
  getStatusColor,
  getPriorityColor,
  onEditTicket,
  onDeleteTicket,
  searchTerm,
  onSearchChange
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  if (!selectedDay) return null;
  
  const { day, month, year, tickets } = selectedDay;
  const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
  
  // Obter status para legendas
  const statuses = [...new Set(tickets.map(t => t.status))];
  
  // Renderiza a lista de tickets
  const renderTicketsList = () => {
    const { visibleTickets, hasMore, totalCount, filteredCount } = paginationInfo;
    
    const isFiltering = searchTerm && searchTerm.trim() !== '';
    const displayCount = isFiltering ? `${filteredCount} de ${totalCount}` : totalCount;
    
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Tickets para {formattedDate}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {displayCount} ticket{totalCount !== 1 ? 's' : ''}
            </Typography>
          </Box>
          
          {/* Campo de Pesquisa */}
          <TextField
            placeholder="Pesquisar tickets..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            fullWidth
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" color="action" />
                </InputAdornment>
              ),
            }}
          />
          
          {/* Legendas de status */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {statuses.map(status => (
              <Box key={status} sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    bgcolor: getStatusColor(status),
                    mr: 1
                  }}
                />
                <Typography variant="caption">{status}</Typography>
              </Box>
            ))}
          </Box>
          
          <Divider />
        </Box>
        
        <Box sx={{ 
          flexGrow: 1, 
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {visibleTickets.length > 0 ? (
            <>
              <Grid container spacing={2} sx={{ mb: 2, width: '100%', mx: 0 }}>
                {visibleTickets.map((ticket) => (
                  <Grid item xs={12} key={ticket.id}>
                    <Paper
                      elevation={1}
                      sx={{
                        p: 2,
                        borderLeft: `4px solid ${getStatusColor(ticket.status)}`,
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: 2
                        }
                      }}
                      onClick={() => onTicketClick(ticket)}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                          {ticket.title}
                        </Typography>
                        <Chip
                          label={ticket.priority}
                          size="small"
                          color={getPriorityColor(ticket.priority)}
                        />
                      </Box>
                      
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {ticket.description && ticket.description.length > 100
                          ? `${ticket.description.substring(0, 100)}...`
                          : ticket.description}
                      </Typography>
                      
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Person fontSize="small" color="action" sx={{ mr: 0.5 }} />
                          <Typography variant="caption" color="text.secondary">
                            {ticket.requester}
                          </Typography>
                        </Box>
                        
                        <Typography variant="caption" color="text.secondary">
                          ID: #{ticket.id}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              
              {hasMore && (
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  mt: 'auto', 
                  pb: 2 
                }}>
                  <Button onClick={onLoadMore} variant="outlined">
                    Carregar mais tickets
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'center', 
              alignItems: 'center',
              flexGrow: 1,
              p: 3
            }}>
              {searchTerm && searchTerm.trim() !== '' ? (
                <>
                  <Typography variant="body1" color="text.secondary">
                    Nenhum ticket encontrado para a pesquisa "{searchTerm}".
                  </Typography>
                  <Button 
                    variant="outlined" 
                    sx={{ mt: 2 }}
                    onClick={() => onSearchChange('')}
                  >
                    Limpar Pesquisa
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="body1" color="text.secondary">
                    Nenhum ticket encontrado para esta data.
                  </Typography>
                  <Button 
                    variant="contained" 
                    sx={{ mt: 2 }}
                    onClick={() => onEditTicket(null, day)}
                  >
                    Criar Ticket
                  </Button>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  
  // Renderiza os detalhes de um ticket específico
  const renderTicketDetails = () => {
    if (!selectedTicket) return null;
    
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <IconButton onClick={onBackToList} sx={{ mr: 1 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Detalhes do Ticket</Typography>
          </Box>
          
          <Divider />
        </Box>
        
        <Box sx={{ 
          flexGrow: 1,
          overflowY: 'auto',
          pr: 0.5
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
              {selectedTicket.title}
            </Typography>
            
            <Box>
              <Chip
                label={selectedTicket.status}
                sx={{
                  bgcolor: getStatusColor(selectedTicket.status),
                  color: 'white',
                  fontWeight: 'bold',
                  mr: 1
                }}
              />
              <Chip
                label={selectedTicket.priority}
                color={getPriorityColor(selectedTicket.priority)}
                icon={<PriorityHigh />}
              />
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Person fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography variant="body2">
                <strong>Solicitante:</strong> {selectedTicket.requester}
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTime fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography variant="body2">
                <strong>Data prevista:</strong> {selectedTicket.predictedDate}
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Info fontSize="small" color="action" sx={{ mr: 1 }} />
              <Typography variant="body2">
                <strong>ID:</strong> #{selectedTicket.id}
              </Typography>
            </Box>
          </Box>
          
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1 }}>
            Descrição:
          </Typography>
          <Paper sx={{ p: 2, bgcolor: '#f9fafb', mb: 3 }}>
            <Typography variant="body1">
              {selectedTicket.description}
            </Typography>
          </Paper>
          
          {selectedTicket.assignedTo && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 1 }}>
                Atribuído para:
              </Typography>
              <Typography variant="body1">
                {selectedTicket.assignedTo}
              </Typography>
            </Box>
          )}
          
          <Box sx={{ display: 'flex', gap: 2, mt: 2, mb: 2 }}>
            <Button 
              variant="contained" 
              startIcon={<Edit />}
              onClick={() => {
                onEditTicket(selectedTicket);
                onClose();
              }}
            >
              Editar Ticket
            </Button>
            <Button 
              variant="outlined" 
              color="error"
              startIcon={<Delete />}
              onClick={() => {
                if (window.confirm('Tem certeza que deseja excluir este ticket?')) {
                  onDeleteTicket(selectedTicket.id);
                }
              }}
            >
              Excluir Ticket
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          height: 'calc(100% - 64px)', // Ajuste da altura para evitar espaço em branco
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          pb: 1,
          flexShrink: 0 // Impedir que o título encolha
        }}
      >
        {!selectedTicket ? (
          <Typography variant="h6">
            {day} de {['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][month - 1]} de {year}
          </Typography>
        ) : (
          <Typography variant="h6">Ticket #{selectedTicket.id}</Typography>
        )}
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </DialogTitle>
      
      <DialogContent 
        sx={{ 
          p: 3,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden' // Remover scroll do DialogContent
        }}
      >
        {selectedTicket ? renderTicketDetails() : renderTicketsList()}
      </DialogContent>
    </Dialog>
  );
};

export default DayDetailModal;