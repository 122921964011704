import React, { Component } from 'react';
import AutomationIcon from '@mui/icons-material/Assistant';
import {
  Box,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  Paper,
  Button,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Divider,
  Alert,
  Stack,
  Avatar, CircularProgress, Container, CssBaseline
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import Api from '../../services/api'
import { Styles } from '../../styles/material-styles'
import { connect } from 'react-redux'
import Footer from '../../components/footer'
import HeaderMenu from '../../components/header-menu'
import CustomizedSnackbars from '../../components/material-snackbars'
import Title from '../../components/title-name'
import { browserHistory } from '../../helpers/history'
import { Overlay } from '../../styles/global'
import { PapiroConsole } from '../../utils/papiroConsole'

class AutomationRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomeAutomacao: '',
      descricao: '',
      ativo: true,
      blocosFiltro: [
        {
          id: 1,
          operadorLogico: 'E', // Operador entre este bloco e o próximo
          grupos: [
            {
              id: 1,
              operadorLogico: 'E', // Operador entre condições dentro do grupo
              condicoes: [{ tipo: 'ticket', campo: 'status', operador: 'igual', valor: 'novo' }]
            }
          ]
        }
      ],
      acoes: [{ tipo: 'atribuir', valor: 'suporte' }],
      eventos: [{ tipo: 'Tipo foi alterado', valor: 'Incidente' }]
    };

    // Opções para os campos de dropdown
    this.tiposCondicao = ['ticket', 'cliente', 'agente', 'departamento'];
    this.camposTicket = ['status', 'prioridade', 'assunto', 'categoria', 'tags'];
    this.operadores = ['igual', 'diferente', 'contém', 'não contém', 'começa com', 'termina com'];
    this.tiposAcao = ['atribuir', 'mudar status', 'adicionar tag', 'enviar email', 'enviar notificação'];
    this.Eventos = ['Prioridade foi alterada', 'Tipo foi alterado', 'Status foi alterado'];
    this.operadoresLogicos = ['E', 'OU'];
  }
  componentWillReceiveProps(nextProps) {
  }

  async componentDidMount() {


  }
  // Handlers para campos básicos
  handleNomeChange = (e) => {
    this.setState({ nomeAutomacao: e.target.value });
  };

  handleDescricaoChange = (e) => {
    this.setState({ descricao: e.target.value });
  };

  handleAtivoChange = () => {
    this.setState((prevState) => ({ ativo: !prevState.ativo }));
  };

  // Função para adicionar um novo bloco de filtro
  adicionarBlocoFiltro = () => {
    const novosBloco = [...this.state.blocosFiltro];
    const novoId = Math.max(...novosBloco.map(bloco => bloco.id), 0) + 1;
    
    novosBloco.push({
      id: novoId,
      operadorLogico: 'E',
      grupos: [
        {
          id: 1,
          operadorLogico: 'E',
          condicoes: [{ tipo: 'ticket', campo: 'status', operador: 'igual', valor: '' }]
        }
      ]
    });
    
    this.setState({ blocosFiltro: novosBloco });
  };
  
  // Função para remover um bloco de filtro
  removerBlocoFiltro = (blocoId) => {
    const novosBloco = this.state.blocosFiltro.filter(bloco => bloco.id !== blocoId);
    this.setState({ blocosFiltro: novosBloco });
  };
  
  // Função para adicionar um novo grupo dentro de um bloco
  adicionarGrupo = (blocoId) => {
    const novosBloco = [...this.state.blocosFiltro];
    const blocoIndex = novosBloco.findIndex(bloco => bloco.id === blocoId);
    
    if (blocoIndex !== -1) {
      const novoId = Math.max(...novosBloco[blocoIndex].grupos.map(grupo => grupo.id), 0) + 1;
      
      novosBloco[blocoIndex].grupos.push({
        id: novoId,
        operadorLogico: 'E',
        condicoes: [{ tipo: 'ticket', campo: 'status', operador: 'igual', valor: '' }]
      });
      
      this.setState({ blocosFiltro: novosBloco });
    }
  };
  
  // Função para remover um grupo
  removerGrupo = (blocoId, grupoId) => {
    const novosBloco = [...this.state.blocosFiltro];
    const blocoIndex = novosBloco.findIndex(bloco => bloco.id === blocoId);
    
    if (blocoIndex !== -1) {
      novosBloco[blocoIndex].grupos = novosBloco[blocoIndex].grupos.filter(grupo => grupo.id !== grupoId);
      if (novosBloco[blocoIndex].grupos.length === 0) {
        novosBloco.splice(blocoIndex, 1);
      }
      this.setState({ blocosFiltro: novosBloco });
    }
  };
  
  // Função para atualizar o operador lógico entre blocos
  atualizarOperadorBloco = (blocoId, novoOperador) => {
    const novosBloco = [...this.state.blocosFiltro];
    const blocoIndex = novosBloco.findIndex(bloco => bloco.id === blocoId);
    
    if (blocoIndex !== -1) {
      novosBloco[blocoIndex].operadorLogico = novoOperador;
      this.setState({ blocosFiltro: novosBloco });
    }
  };
  
  // Função para atualizar o operador lógico dentro de um grupo
  atualizarOperadorGrupo = (blocoId, grupoId, novoOperador) => {
    const novosBloco = [...this.state.blocosFiltro];
    const blocoIndex = novosBloco.findIndex(bloco => bloco.id === blocoId);
    
    if (blocoIndex !== -1) {
      const grupoIndex = novosBloco[blocoIndex].grupos.findIndex(grupo => grupo.id === grupoId);
      if (grupoIndex !== -1) {
        novosBloco[blocoIndex].grupos[grupoIndex].operadorLogico = novoOperador;
        this.setState({ blocosFiltro: novosBloco });
      }
    }
  };

  // Função para adicionar condição a um grupo
  adicionarCondicao = (blocoId, grupoId) => {
    const novosBloco = [...this.state.blocosFiltro];
    const blocoIndex = novosBloco.findIndex(bloco => bloco.id === blocoId);
    
    if (blocoIndex !== -1) {
      const grupoIndex = novosBloco[blocoIndex].grupos.findIndex(grupo => grupo.id === grupoId);
      if (grupoIndex !== -1) {
        novosBloco[blocoIndex].grupos[grupoIndex].condicoes.push(
          { tipo: 'ticket', campo: 'status', operador: 'igual', valor: '' }
        );
        this.setState({ blocosFiltro: novosBloco });
      }
    }
  };

  // Função para remover condição de um grupo
  removerCondicao = (blocoId, grupoId, condicaoIndex) => {
    const novosBloco = [...this.state.blocosFiltro];
    const blocoIndex = novosBloco.findIndex(bloco => bloco.id === blocoId);
    
    if (blocoIndex !== -1) {
      const grupoIndex = novosBloco[blocoIndex].grupos.findIndex(grupo => grupo.id === grupoId);
      if (grupoIndex !== -1) {
        if (novosBloco[blocoIndex].grupos[grupoIndex].condicoes.length > 1) {
          novosBloco[blocoIndex].grupos[grupoIndex].condicoes.splice(condicaoIndex, 1);
        } else if (novosBloco[blocoIndex].grupos.length > 1) {
          // Se for a última condição do grupo e houver mais de um grupo, remover o grupo
          novosBloco[blocoIndex].grupos.splice(grupoIndex, 1);
        } else if (this.state.blocosFiltro.length > 1) {
          // Se for o último grupo do bloco e houver mais de um bloco, remover o bloco
          novosBloco.splice(blocoIndex, 1);
        }
        this.setState({ blocosFiltro: novosBloco });
      }
    }
  };

  // Função para atualizar uma condição
  atualizarCondicao = (blocoId, grupoId, condicaoIndex, campo, valor) => {
    const novosBloco = [...this.state.blocosFiltro];
    const blocoIndex = novosBloco.findIndex(bloco => bloco.id === blocoId);
    
    if (blocoIndex !== -1) {
      const grupoIndex = novosBloco[blocoIndex].grupos.findIndex(grupo => grupo.id === grupoId);
      if (grupoIndex !== -1) {
        novosBloco[blocoIndex].grupos[grupoIndex].condicoes[condicaoIndex][campo] = valor;
        this.setState({ blocosFiltro: novosBloco });
      }
    }
  };

  adicionarEvento = () => {
    this.setState(prevState => ({
      eventos: [...prevState.eventos, { tipo: 'Tipo foi alterado', valor: '' }]
    }));
  };

  adicionarAcao = () => {
    this.setState(prevState => ({
      acoes: [...prevState.acoes, { tipo: 'atribuir', valor: '' }]
    }));
  };

  removerEvento = (index) => {
    const novosEventos = [...this.state.eventos];
    novosEventos.splice(index, 1);
    this.setState({ eventos: novosEventos });
  };

  removerAcao = (index) => {
    const novasAcoes = [...this.state.acoes];
    novasAcoes.splice(index, 1);
    this.setState({ acoes: novasAcoes });
  };

  atualizarEvento = (index, campo, valor) => {
    const novasEventos = [...this.state.eventos];
    novasEventos[index][campo] = valor;
    this.setState({ eventos: novasEventos });
  };

  atualizarAcao = (index, campo, valor) => {
    const novasAcoes = [...this.state.acoes];
    novasAcoes[index][campo] = valor;
    this.setState({ acoes: novasAcoes });
  };

  salvarAutomacao = () => {
    const automacao = {
      nome: this.state.nomeAutomacao,
      descricao: this.state.descricao,
      ativo: this.state.ativo,
      blocosFiltro: this.state.blocosFiltro,
      acoes: this.state.acoes
    };
    console.log('Automação salva:', automacao);
    // Aqui você implementaria a lógica para enviar ao backend
    alert('Automação salva com sucesso!');
  };

  render() {
    const { nomeAutomacao, descricao, ativo, blocosFiltro, acoes, eventos } = this.state;
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (

        <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar style={{ backgroundColor: '#3f51b4' }} >
                <AutomationIcon />
              </Avatar>
              <Typography component='h1' variant='h5' style={{ marginTop: '10px' }}>
                {<FormattedMessage id="add.authentication" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
              <Box sx={{ maxWidth: 1000, mx: 'auto', p: 3 }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                        Nova Automação
                        </Typography>
                        
                        {/* Informações básicas */}
                        <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Informações Básicas
                        </Typography>
                        
                        <TextField
                            label="Nome da Automação"
                            fullWidth
                            margin="normal"
                            value={nomeAutomacao}
                            onChange={this.handleNomeChange}
                            placeholder="Ex: Atribuir tickets urgentes"
                        />
                        
                        <TextField
                            label="Descrição (opcional)"
                            fullWidth
                            margin="normal"
                            value={descricao}
                            onChange={this.handleDescricaoChange}
                            multiline
                            rows={3}
                            placeholder="Descreva o propósito desta automação"
                        />
                        
                        <FormControlLabel
                            control={
                            <Switch
                                checked={ativo}
                                onChange={this.handleAtivoChange}
                                color="primary"
                            />
                            }
                            label="Automação Ativa"
                            sx={{ mt: 1 }}
                        />
                        </Paper>
                        
                       {/* Eventos */}
                       <Accordion defaultExpanded sx={{ mb: 3 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="eventos-content"
                            id="eventos-header"
                        >
                            <Typography variant="h6">Eventos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Alert severity="success" sx={{ mb: 2 }}>
                            As ações serão executadas quando o(s) evento(s) abaixo forem iniciado(s)
                            </Alert>
                            
                            {eventos.map((evento, index) => (
                            <Paper
                                key={index}
                                variant="outlined"
                                sx={{ p: 2, mb: 2 }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Typography variant="subtitle2">
                                    Evento {index + 1}
                                </Typography>
                                <IconButton 
                                    size="small" 
                                    color="error"
                                    onClick={() => this.removerEvento(index)}
                                    aria-label="Remover evento"
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                </Box>
                                
                                <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                    <InputLabel>Evento</InputLabel>
                                    <Select
                                        value={evento.tipo}
                                        label="Evento"
                                        onChange={(e) => this.atualizarEvento(index, 'tipo', e.target.value)}
                                    >
                                        {this.Eventos.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            {item.charAt(0).toUpperCase() + item.slice(1)}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                    fullWidth
                                    label="Valor"
                                    value={evento.valor}
                                    onChange={(e) => this.atualizarEvento(index, 'valor', e.target.value)}
                                    placeholder="Valor para o evento"
                                    />
                                </Grid>
                                </Grid>
                            </Paper>
                            ))}
                            
                            <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={this.adicionarEvento}
                            color="primary"
                            >
                            Adicionar evento
                            </Button>
                        </AccordionDetails>
                        </Accordion>
                        


                        {/* Filtros (Blocos de Condições) */}
                        <Accordion defaultExpanded sx={{ mb: 3 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="filtros-content"
                            id="filtros-header"
                        >
                            <Typography variant="h6">Filtros</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Alert severity="info" sx={{ mb: 2 }}>
                            Configure blocos de filtros com condições. Você pode usar operadores E/OU para combinar blocos e condições.
                            </Alert>
                            
                            {blocosFiltro.map((bloco, blocoIndex) => (
                            <Paper 
                                key={bloco.id} 
                                variant="outlined" 
                                sx={{ p: 2, mb: 3, bgcolor: 'background.default' }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    Bloco de Filtro {blocoIndex + 1}
                                </Typography>
                                {blocosFiltro.length > 1 && (
                                    <IconButton 
                                    size="small" 
                                    color="error"
                                    onClick={() => this.removerBlocoFiltro(bloco.id)}
                                    aria-label="Remover bloco de filtro"
                                    >
                                    <DeleteIcon fontSize="small" />
                                    </IconButton>
                                )}
                                </Box>
                                
                                {bloco.grupos.map((grupo, grupoIndex) => (
                                <Paper
                                    key={grupo.id}
                                    variant="outlined"
                                    sx={{ p: 2, mb: 2, bgcolor: 'background.paper' }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                    <Typography variant="subtitle2">
                                        Grupo {grupoIndex + 1}
                                    </Typography>
                                    {(bloco.grupos.length > 1 || blocosFiltro.length > 1) && (
                                        <IconButton 
                                        size="small" 
                                        color="error"
                                        onClick={() => this.removerGrupo(bloco.id, grupo.id)}
                                        aria-label="Remover grupo"
                                        >
                                        <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                    </Box>
                                    
                                    {grupo.condicoes.map((condicao, condicaoIndex) => (
                                    <Box key={condicaoIndex} sx={{ mb: 2 }}>
                                        {condicaoIndex > 0 && (
                                        <Box sx={{ my: 1 }}>
                                            <FormControl size="small" sx={{ minWidth: 120 }}>
                                            <Select
                                                value={grupo.operadorLogico}
                                                onChange={(e) => this.atualizarOperadorGrupo(bloco.id, grupo.id, e.target.value)}
                                                displayEmpty
                                            >
                                                {this.operadoresLogicos.map((op) => (
                                                <MenuItem key={op} value={op}>{op}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Box>
                                        )}
                                        
                                        <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={2}>
                                            <FormControl fullWidth size="small">
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                value={condicao.tipo}
                                                label="Tipo"
                                                onChange={(e) => this.atualizarCondicao(bloco.id, grupo.id, condicaoIndex, 'tipo', e.target.value)}
                                            >
                                                {this.tiposCondicao.map((tipo) => (
                                                <MenuItem key={tipo} value={tipo}>
                                                    {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={2}>
                                            <FormControl fullWidth size="small">
                                            <InputLabel>Campo</InputLabel>
                                            <Select
                                                value={condicao.campo}
                                                label="Campo"
                                                onChange={(e) => this.atualizarCondicao(bloco.id, grupo.id, condicaoIndex, 'campo', e.target.value)}
                                            >
                                                {this.camposTicket.map((campo) => (
                                                <MenuItem key={campo} value={campo}>
                                                    {campo.charAt(0).toUpperCase() + campo.slice(1)}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={3}>
                                            <FormControl fullWidth size="small">
                                            <InputLabel>Operador</InputLabel>
                                            <Select
                                                value={condicao.operador}
                                                label="Operador"
                                                onChange={(e) => this.atualizarCondicao(bloco.id, grupo.id, condicaoIndex, 'operador', e.target.value)}
                                            >
                                                {this.operadores.map((operador) => (
                                                <MenuItem key={operador} value={operador}>
                                                    {operador.charAt(0).toUpperCase() + operador.slice(1)}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                            fullWidth
                                            size="small"
                                            label="Valor"
                                            value={condicao.valor}
                                            onChange={(e) => this.atualizarCondicao(bloco.id, grupo.id, condicaoIndex, 'valor', e.target.value)}
                                            placeholder="Valor"
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={1}>
                                            <IconButton
                                            color="error"
                                            onClick={() => this.removerCondicao(bloco.id, grupo.id, condicaoIndex)}
                                            aria-label="Remover condição"
                                            >
                                            <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                        </Grid>
                                    </Box>
                                    ))}
                                    
                                    <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => this.adicionarCondicao(bloco.id, grupo.id)}
                                    size="small"
                                    color="primary"
                                    sx={{ mt: 1 }}
                                    >
                                    Adicionar condição
                                    </Button>
                                </Paper>
                                ))}
                                
                                <Button
                                startIcon={<AddIcon />}
                                onClick={() => this.adicionarGrupo(bloco.id)}
                                size="small"
                                color="primary"
                                sx={{ mb: 2 }}
                                >
                                Adicionar grupo de condições
                                </Button>
                                
                                {blocoIndex < blocosFiltro.length - 1 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                                    <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel>Operador</InputLabel>
                                    <Select
                                        value={bloco.operadorLogico}
                                        label="Operador"
                                        onChange={(e) => this.atualizarOperadorBloco(bloco.id, e.target.value)}
                                    >
                                        {this.operadoresLogicos.map((op) => (
                                        <MenuItem key={op} value={op}>{op}</MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Box>
                                )}
                            </Paper>
                            ))}
                            
                            <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={this.adicionarBlocoFiltro}
                            color="primary"
                            >
                            Adicionar bloco de filtro
                            </Button>
                        </AccordionDetails>
                        </Accordion>
                        
                        {/* Ações */}
                        <Accordion defaultExpanded sx={{ mb: 3 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="acoes-content"
                            id="acoes-header"
                        >
                            <Typography variant="h6">Ações</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Alert severity="success" sx={{ mb: 2 }}>
                            As seguintes ações serão executadas quando as condições forem atendidas.
                            </Alert>
                            
                            {acoes.map((acao, index) => (
                            <Paper
                                key={index}
                                variant="outlined"
                                sx={{ p: 2, mb: 2 }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Typography variant="subtitle2">
                                    Ação {index + 1}
                                </Typography>
                                <IconButton 
                                    size="small" 
                                    color="error"
                                    onClick={() => this.removerAcao(index)}
                                    aria-label="Remover ação"
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                </Box>
                                
                                <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                    <InputLabel>Tipo de Ação</InputLabel>
                                    <Select
                                        value={acao.tipo}
                                        label="Tipo de Ação"
                                        onChange={(e) => this.atualizarAcao(index, 'tipo', e.target.value)}
                                    >
                                        {this.tiposAcao.map((tipo) => (
                                        <MenuItem key={tipo} value={tipo}>
                                            {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                    fullWidth
                                    label="Valor"
                                    value={acao.valor}
                                    onChange={(e) => this.atualizarAcao(index, 'valor', e.target.value)}
                                    placeholder="Valor para a ação"
                                    />
                                </Grid>
                                </Grid>
                            </Paper>
                            ))}
                            
                            <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={this.adicionarAcao}
                            color="primary"
                            >
                            Adicionar ação
                            </Button>
                        </AccordionDetails>
                        </Accordion>
                        
                        {/* Botões de ação */}
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                        <Button variant="outlined">
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={this.salvarAutomacao}
                            color="primary"
                        >
                            Salvar Automação
                        </Button>
                        </Stack>
                    </Box>
                
              </form>
            </div>
            {
              this.state.loading && (
                <Overlay>
                  <CircularProgress color='secondary' />
                </Overlay>
              )
            }
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />



          </Container >
        </main >
        <Footer />
      </div >
    );
  }
}


AutomationRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AutomationRegistration)));