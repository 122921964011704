import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  Button,
  Grid
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const TicketDetailModal = ({ ticket, open, onClose, getStatusConfig }) => {
  // Função para cores de prioridade
  const getPriorityColor = (priority) => {
    switch(priority?.toLowerCase()) {
      case 'alta':
        return { bg: '#fee2e2', color: '#ef4444' };
      case 'média':
        return { bg: '#fff7cd', color: '#f59e0b' };
      case 'baixa':
        return { bg: '#d1fae5', color: '#10b981' };
      default:
        return { bg: '#f3f4f6', color: '#6b7280' };
    }
  };

  if (!ticket) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2
        }}
      >
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Detalhes do Ticket
        </Typography>
        <IconButton 
          onClick={onClose} 
          size="small"
          sx={{ 
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box 
              sx={{ 
                width: 12, 
                height: 12, 
                borderRadius: '50%', 
                bgcolor: getStatusConfig(ticket.status).color,
                mr: 1
              }} 
            />
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
              {ticket.status}
            </Typography>
          </Box>
          
          <Divider sx={{ mb: 2 }} />
          
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
            #{ticket.id} - {ticket.title}
          </Typography>
          
          <Chip 
            label={ticket.priority} 
            sx={{
              mb: 3,
              bgcolor: getPriorityColor(ticket.priority).bg,
              color: getPriorityColor(ticket.priority).color,
              fontWeight: 'medium'
            }}
          />
          
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="text.secondary">Cliente</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                {ticket.client}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="text.secondary">Categoria</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                {ticket.category}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="text.secondary">Responsável</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                {ticket.assignedTo || 'Não atribuído'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="text.secondary">Data de Abertura</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                {ticket.openDate}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="text.secondary">Data de Previsão</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                {ticket.predictedDate}
              </Typography>
            </Grid>
          </Grid>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Descrição
          </Typography>
          <Paper sx={{ p: 2, bgcolor: '#f9fafb', borderRadius: 2 }}>
            <Typography variant="body1">
              {ticket.description}
            </Typography>
          </Paper>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button 
          onClick={onClose} 
          variant="outlined"
          sx={{ borderRadius: 2 }}
        >
          Fechar
        </Button>
        <Button 
          variant="contained" 
          color="primary"
          sx={{ borderRadius: 2 }}
        >
          Editar Ticket
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TicketDetailModal;