import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import Input from '../Input/Input';
import Select from '../Input/Select/Select';
import { useBaseInspector } from './useBaseInspector';

import ReactQuill from 'react-quill';
import * as ParamsService from "../../../../../../../../services/2talk/chatbotResponseParams.service";
import HelperCustomField from '../../HelperCustomField/HelperCustomField';
import { Switch } from '@mui/material';

interface Props {
    cell: shapes.app.Message;
    chatbotFlowId: string;
}

const cellProps = {
    label: ['attrs', 'label', 'text'],
    description: ['attrs', 'description', 'text'],
    icon: ['attrs', 'icon', 'xlinkHref'],
    portLabel: ['attrs', 'portLabel', 'text'],
    integrationParamsId: ['attrs', 'integrationParamsId', 'id'],
    isTextRich: ['attrs', 'isTextRich', 'isTextRich'],
    hasAttachmentParam: ['attrs', 'hasAttachmentParam', 'hasAttachmentParam'],
    integrationAttachmentParamsId: ['attrs', 'integrationAttachmentParamsId', 'id'],
};

const ResponseParamInspector = (props: Props): ReactElement => {

    const { cell, chatbotFlowId } = props;

    const [paramsOptions, setParamsOptions] = useState([]);
    const [attachmentParamsOptions, setAttachmentParamsOptions] = useState([]);

    const [label, setLabel] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [integrationParamsId, setIntegrationParamsId] = useState(null);
    const [isTextRich, setIsTextRich] = useState<boolean>(false);
    const [hasAttachmentParam, setHasAttachmentParam] = useState<boolean>(false);
    const [integrationAttachmentParamsId, setIntegrationAttachmentParamsId] = useState(null);

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
        setDescription(cell.prop(cellProps.description));
        setIntegrationParamsId(cell.prop(cellProps.integrationParamsId));
        setIsTextRich(cell.prop(cellProps.isTextRich));
        setHasAttachmentParam(cell.prop(cellProps.hasAttachmentParam));
        setIntegrationAttachmentParamsId(cell.prop(cellProps.integrationAttachmentParamsId));
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    const fetchParams = async () => {
        const params = await ParamsService.getAll();
        setParamsOptions(params?.data ?? []);
    }

    const fetchAttachmentParams = async () => {
        const params = await ParamsService.getAllAttachmentParams();
        setAttachmentParamsOptions(params?.data ?? []);
    }

    useEffect(() => {
        const fetchAllParams = async () => {
            await fetchParams();
            await fetchAttachmentParams();
        }

        fetchAllParams();
    }, []);

    const handleChangeHasAttachmentParam = (checked: boolean) => {
        changeCellProp(cellProps.hasAttachmentParam, checked);

        if (checked == false) {
            setIntegrationAttachmentParamsId(null);
            changeCellProp(cellProps.integrationAttachmentParamsId, null);
        }
    }

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       disabled={true}
                />
            </label>

            <div style={{ marginBottom: '8px', marginTop: '8px'}}>
                <Switch
                    value={isTextRich}
                    checked={isTextRich}
                    onChange={(e:any) => changeCellProp(cellProps.isTextRich, e?.target?.checked)}     
                />
                <label>É um texto rico?</label>
            </div>

            {
                isTextRich
                ? (
                    <label>Descrição
                        <div style={{ resize: "vertical", overflow: "auto", height: "250px", marginBottom: "24px", border: "1px solid #ccc" }}>
                            <ReactQuill
                                style={{ height: '100%' }}
                                name="description"
                                fullWidth
                                variant="outlined"
                                id="descriprion"
                                value={description}
                                onChange={(value: any) => changeCellProp(cellProps.description, value)}
                            />
                        </div>
                    </label>
                )
                : (
                    <label>Descrição
                        <Input 
                            type="text"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                        />
                    </label>
                )
            }

            <HelperCustomField
                isRichText={isTextRich}
                description={description}
                param1={cellProps.description}
                onChangeDescription={changeCellProp}
                chatbotFlowId={chatbotFlowId}
            />

            <label>Parâmetro
                <Select
                    value={integrationParamsId}
                    onChange={(e) => changeCellProp(cellProps.integrationParamsId, e.target.value)}
                    options={paramsOptions}
                />
            </label>

            <div style={{ marginBottom: '8px', marginTop: '8px'}}>
                <Switch
                    value={hasAttachmentParam}
                    checked={hasAttachmentParam}
                    onChange={(e:any) => handleChangeHasAttachmentParam(e.target.checked)}     
                />

                <label>Tem parâmetro de anexo?</label>
            </div>
            
            {hasAttachmentParam && (
                <label>Parâmetro de anexo
                    <Select
                        value={integrationAttachmentParamsId}
                        onChange={(e) => changeCellProp(cellProps.integrationAttachmentParamsId, e.target.value)}
                        options={attachmentParamsOptions}
                    />
                </label>
            )}
            
        </>
    );
};

export default ResponseParamInspector;
