import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Chip,
  IconButton,
  Tooltip,
  Button,
  Badge,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Add, Edit, MoreHoriz } from '@mui/icons-material';

const WeekView = ({ 
  currentDate,
  currentMonth,
  currentYear,
  weekdayNames,
  filteredTickets,
  getTicketsForDate,
  openTicketForm,
  showTicketDetails,
  getStatusColor,
  getPriorityColor,
  formatDate
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  
  // Estado para paginação de tickets por dia
  const [paginationState, setPaginationState] = useState({});
  const TICKETS_PER_PAGE = isMobile ? 3 : isTablet ? 5 : 10;
  
  // Encontrar o primeiro dia da semana atual
  const date = new Date(currentYear, currentMonth, currentDate.getDate());
  const day = date.getDay();
  const diff = date.getDate() - day;
  const firstDayOfWeek = new Date(date.setDate(diff));
  
  // Obter a página atual para um dia específico
  const getCurrentPage = (dayKey) => {
    return paginationState[dayKey] || 1;
  };
  
  // Manipulador para carregar mais tickets
  const handleLoadMore = (dayKey) => {
    setPaginationState(prev => ({
      ...prev,
      [dayKey]: (prev[dayKey] || 1) + 1
    }));
  };
  
  // Obter tickets paginados para um dia
  const getPaginatedTickets = (tickets, dayKey) => {
    const currentPage = getCurrentPage(dayKey);
    const endIndex = currentPage * TICKETS_PER_PAGE;
    
    return {
      visibleTickets: tickets.slice(0, endIndex),
      hasMore: tickets.length > endIndex,
      totalCount: tickets.length
    };
  };
  
  // Verificar se é hoje
  const isToday = (day) => {
    const today = new Date();
    return day.getDate() === today.getDate() && 
           day.getMonth() === today.getMonth() && 
           day.getFullYear() === today.getFullYear();
  };
  
  const renderWeekDays = () => {
    const weekDays = [];
    
    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(firstDayOfWeek);
      currentDay.setDate(firstDayOfWeek.getDate() + i);
      
      const dayNumber = currentDay.getDate();
      const month = currentDay.getMonth();
      const year = currentDay.getFullYear();
      const dayKey = `${year}-${month}-${dayNumber}`;
      
      const isCurrentMonth = month === currentMonth;
      
      // Verificar se é hoje
      const isTodayFlag = isToday(currentDay);
      
      // Obter tickets para esse dia
      let dayTickets = [];
      if (isCurrentMonth) {
        // Se for o mês atual, podemos usar nossa função normal
        dayTickets = getTicketsForDate(dayNumber);
      } else {
        // Se for outro mês, precisamos ajustar a lógica
        const dateStr = `${dayNumber < 10 ? '0' + dayNumber : dayNumber}/${(month + 1) < 10 ? '0' + (month + 1) : (month + 1)}/${year}`;
        
        dayTickets = filteredTickets.filter(ticket => {
          const predictedDate = ticket.predictedDate.split(' ')[0];
          return predictedDate === dateStr;
        });
      }
      
      // Aplicar paginação
      const { visibleTickets, hasMore, totalCount } = getPaginatedTickets(dayTickets, dayKey);
      
      // Altura mínima para o cabeçalho do dia
      const dayHeaderHeight = isMobile ? '50px' : '60px';
      
      // Altura para cada cartão de ticket
      const ticketCardHeight = isMobile ? '80px' : isTablet ? '100px' : '120px';
      
      weekDays.push(
        <Grid item xs={12} key={`week-day-${i}`}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              borderRadius: 2,
              border: isTodayFlag ? `2px solid ${theme.palette.primary.main}` : '1px solid #e5e7eb',
              bgcolor: isCurrentMonth ? 'background.paper' : '#f5f5f5'
            }}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mb: 2, 
                alignItems: 'center',
                minHeight: dayHeaderHeight
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography 
                  variant={isMobile ? "body1" : "h6"} 
                  sx={{ 
                    fontWeight: isTodayFlag ? 'bold' : 'normal',
                    color: isTodayFlag ? theme.palette.primary.main : 'text.primary'
                  }}
                >
                  {weekdayNames[i]} {dayNumber}
                  
                </Typography>
                {totalCount > 0 && (
                  <Badge 
                    badgeContent={totalCount} 
                    color="primary" 
                    sx={{ 
                      ml: 1.5,
                      '& .MuiBadge-badge': { 
                        fontSize: '0.65rem',
                        top: -2,
                        right: -2
                      }
                    }}
                  />
                )}
              </Box>
              
              <Tooltip title="Adicionar ticket">
                <IconButton 
                  size="small" 
                  onClick={() => openTicketForm(null, dayNumber)}
                  sx={{ ml: 1 }}
                >
                  <Add fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            
            {visibleTickets.length > 0 ? (
              <>
                {/* Agrupamento por status responsivo */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {['Novo', 'Em andamento', 'Finalizado'].map(status => {
                    const statusTickets = visibleTickets.filter(ticket => ticket.status === status);
                    if (statusTickets.length === 0) return null;
                    
                    return (
                      <Box key={`${dayKey}-${status}`}>
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            mb: 1,
                            borderBottom: 1,
                            borderColor: 'divider',
                            pb: 0.5
                          }}
                        >
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: '50%',
                              backgroundColor: getStatusColor(status),
                              display: 'inline-block',
                              mr: 1
                            }}
                          />
                          <Typography variant="body2" fontWeight="medium">
                            {status}
                          </Typography>
                          <Badge 
                            badgeContent={statusTickets.length} 
                            color="primary" 
                            sx={{ 
                              ml: 1.5,
                              '& .MuiBadge-badge': { 
                                fontSize: '0.65rem',
                                top: -2,
                                right: -2
                              }
                            }}
                          />
                        </Box>
                        
                        <Grid container spacing={1}>
                          {statusTickets.map((ticket) => (
                            <Grid 
                              item 
                              xs={12} 
                              sm={6} 
                              md={4} 
                              key={`${dayKey}-${ticket.id}`}
                            >
                              <Paper
                                sx={{
                                  p: 1.5,
                                  borderLeft: `4px solid ${getStatusColor(ticket.status)}`,
                                  height: ticketCardHeight,
                                  position: 'relative',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  overflow: 'hidden',
                                  '&:hover': {
                                    bgcolor: '#f9fafb',
                                    '& .ticket-actions': {
                                      opacity: 1
                                    }
                                  }
                                }}
                                onClick={() => showTicketDetails(ticket)}
                              >
                                <Box sx={{ display: 'flex', flexDirection: 'column', pr: 5 }}>
                                  <Typography 
                                    variant="body2"
                                    sx={{ 
                                      fontWeight: 'medium',
                                      mb: 0.5,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: 'vertical'
                                    }}
                                  >
                                    {ticket.title}
                                  </Typography>
                                  
                                  {!isMobile && (
                                    <Typography 
                                      variant="caption" 
                                      color="text.secondary"
                                      sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        mb: 0.5,
                                        maxHeight: '32px'
                                      }}
                                    >
                                      {ticket.description}
                                    </Typography>
                                  )}
                                  
                                  <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    alignItems: 'center', 
                                    mt: 'auto'
                                  }}>
                                    <Typography variant="caption" color="text.secondary">
                                      {ticket.requester}
                                    </Typography>
                                    
                                    <Chip 
                                      label={ticket.priority} 
                                      size="small" 
                                      color={getPriorityColor(ticket.priority)}
                                      sx={{ 
                                        height: 20, 
                                        '& .MuiChip-label': { 
                                          px: 1, 
                                          py: 0, 
                                          fontSize: '0.65rem' 
                                        } 
                                      }}
                                    />
                                  </Box>
                                </Box>
                                
                                {/* Botões de ação */}
                                <Box 
                                  className="ticket-actions"
                                  sx={{ 
                                    position: 'absolute', 
                                    top: 8, 
                                    right: 8, 
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 0.5
                                  }}
                                >
                                  <Tooltip title="Editar">
                                    <IconButton 
                                      size="small" 
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openTicketForm(ticket);
                                      }}
                                      sx={{ 
                                        bgcolor: 'background.paper',
                                        boxShadow: 1,
                                        '&:hover': { bgcolor: 'background.paper' }
                                      }}
                                    >
                                      <Edit fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Ver detalhes">
                                    <IconButton 
                                      size="small" 
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        showTicketDetails(ticket);
                                      }}
                                      sx={{ 
                                        bgcolor: 'background.paper',
                                        boxShadow: 1,
                                        '&:hover': { bgcolor: 'background.paper' }
                                      }}
                                    >
                                      <MoreHoriz fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
                
                {hasMore && (
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mt: 2 
                  }}>
                    <Button 
                      onClick={() => handleLoadMore(dayKey)} 
                      variant="text" 
                      size="small"
                    >
                      Carregar mais {Math.min(TICKETS_PER_PAGE, totalCount - visibleTickets.length)} de {totalCount}
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                py: 4,
                minHeight: '100px'
              }}>
                <Typography variant="body2" color="text.secondary">
                  Nenhum ticket
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      );
    }
    
    return weekDays;
  };
  
  return (
    <Grid container>
      {renderWeekDays()}
    </Grid>
  );
};

export default WeekView;